import _isDate from 'lodash/isDate'
import _isString from 'lodash/isString'
import _isUndefined from 'lodash/isUndefined'

import { type ChartDataPoint } from '../types/ui'

const getCSVRow = (
  rowLabel: string,
  columnLabels: string[] | undefined,
  point: ChartDataPoint
): string => {
  const { x, y } = point
  const xCSV = _isString(x) ? x : _isDate(x) ? x.getUTCFullYear().toString() : x

  if (!_isUndefined(columnLabels) && columnLabels.length === 2) {
    return `${xCSV},${y}`
  }

  return `${rowLabel},${xCSV},${y}`
}

export default getCSVRow
export { getCSVRow }
