import _flatten from 'lodash/flatten'
import _isArray from 'lodash/isArray'
import { type TFunction } from 'i18next'
import _isUndefined from 'lodash/isUndefined'

import { type ChartDataPoint, type Emissions } from 'types'

import getCSVRow from './get_csv_row'
import getCSVRowLabel from './get_csv_row_label'

export const DEFAULT_COLUMN_LABELS = ['Label', 'Date', 'Value']
export const DEFAULT_GROUPED_COLUMN_LABELS = ['Group', 'Date', 'Value']

interface GenerateChartCSVArgs {
  t: TFunction
  chartTitle?: string
  columnLabels?: string[]
  emissions?: Emissions[] | Emissions
  data: ChartDataPoint[] | ChartDataPoint[][]
}

const generateChartCSV = (args: GenerateChartCSVArgs): string => {
  const { t, data, emissions, columnLabels, chartTitle } = args
  const datasets = _isArray(data[0])
    ? (data as ChartDataPoint[][])
    : ([data] as ChartDataPoint[][])

  const isGrouped = datasets.length > 1
  const defaultColumnLabels = isGrouped
    ? DEFAULT_GROUPED_COLUMN_LABELS.join(',')
    : DEFAULT_COLUMN_LABELS.join(',')

  const finalColumnLabels = !_isUndefined(columnLabels)
    ? columnLabels.join(',')
    : defaultColumnLabels

  return [
    finalColumnLabels,
    ..._flatten(
      datasets.map((d: ChartDataPoint[], rowIndex: number) => {
        const rowLabel = getCSVRowLabel({
          t,
          datasets,
          rowIndex,
          isGrouped,
          emissions,
          chartTitle
        })

        return d.map(getCSVRow.bind(null, rowLabel, columnLabels))
      })
    )
  ].join('\n')
}

export default generateChartCSV
export { generateChartCSV }
