import React from 'react'
import _isEmpty from 'lodash/isEmpty'
import { SwishSpinner } from 'react-spinners-kit'

import type * as T from './types'
import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'

import './style.scss'

/**
 * Renders a fullscreen loading spinner. Used with page lazy loading.
 */
const PageLoader: React.FC<T.PageLoaderProps> = (props: T.PageLoaderProps) => {
  const { description } = props

  return (
    <div className={CLASS_NAME}>
      <div className={`${CLASS_NAME}-inner`}>
        <div className={`${CLASS_NAME}-inner-wrapper`}>
          <SwishSpinner
            size={64}
            color="#00a0ff"
            loading={true}
            aria-label={description}
          />

          {!_isEmpty(description) && (
            <div className={`${CLASS_NAME}-description`}>
              <p>{description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

PageLoader.propTypes = propTypes
PageLoader.defaultProps = defaultProps

export default PageLoader
