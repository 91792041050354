import React from 'react'

import * as H from '../../hooks'
import { DEFAULT_ICON_WIDTH_PX } from '../../config'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type IconNavDashboardProps } from './types'

import './style.scss'

const IconNavDashboard: React.FC<IconNavDashboardProps> = (
  props: IconNavDashboardProps
) => {
  const { active, className, width = DEFAULT_ICON_WIDTH_PX } = props
  const finalClassName = H.useClassName(CLASS_NAME, className, 'cst-icon', {
    active: active === true
  })

  return (
    <div className={finalClassName}>
      <svg
        width={`${width}`}
        height={`${width}`}
        viewBox="0 0 26 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.33666 13.9427H10.465C11.2101 13.9427 11.8197 13.3331 11.8197 12.588V1.75023C11.8197 1.00513 11.2101 0.395508 10.465 0.395508H2.33666C1.59156 0.395508 0.981934 1.00513 0.981934 1.75023V12.588C0.981934 13.3331 1.59156 13.9427 2.33666 13.9427ZM2.33666 24.7805H10.465C11.2101 24.7805 11.8197 24.1709 11.8197 23.4258V18.0069C11.8197 17.2618 11.2101 16.6522 10.465 16.6522H2.33666C1.59156 16.6522 0.981934 17.2618 0.981934 18.0069V23.4258C0.981934 24.1709 1.59156 24.7805 2.33666 24.7805ZM15.8839 24.7805H24.0122C24.7573 24.7805 25.3669 24.1709 25.3669 23.4258V12.588C25.3669 11.8429 24.7573 11.2333 24.0122 11.2333H15.8839C15.1388 11.2333 14.5292 11.8429 14.5292 12.588V23.4258C14.5292 24.1709 15.1388 24.7805 15.8839 24.7805ZM14.5292 1.75023V7.16912C14.5292 7.91422 15.1388 8.52384 15.8839 8.52384H24.0122C24.7573 8.52384 25.3669 7.91422 25.3669 7.16912V1.75023C25.3669 1.00513 24.7573 0.395508 24.0122 0.395508H15.8839C15.1388 0.395508 14.5292 1.00513 14.5292 1.75023Z"
          fill="#fff"
        />
      </svg>
    </div>
  )
}

IconNavDashboard.propTypes = propTypes
IconNavDashboard.defaultProps = defaultProps

export default IconNavDashboard
export { CLASS_NAME }
export type { IconNavDashboardProps as PROPS }
