import { useEffect } from 'react'

/**
 * Sets the document title to the provided string.
 */
const useDocumentTitle = (title: string): void => {
  useEffect((): void => {
    document.title = `${title} | CarbonSpace`
  }, [title])
}

export default useDocumentTitle
