import mapboxgl from 'mapbox-gl'
import _isUndefined from 'lodash/isUndefined'
import React, { useState, useEffect } from 'react'

import 'mapbox-gl/dist/mapbox-gl.css'

import * as H from '../../hooks'
import { CLASS_NAME } from './const'

import './style.scss'

const { REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env

if (!_isUndefined(REACT_APP_MAPBOX_ACCESS_TOKEN)) {
  mapboxgl.accessToken = REACT_APP_MAPBOX_ACCESS_TOKEN
}

// TODO: Create MapProps interface
const Map: React.FC<any> = (props: any) => {
  const [initializedMap, setInitializedMap] = useState<boolean>(false)
  const { className, stateMapRef, setStateMapRef, mapContainerRef } = props

  const finalClassName = H.useClassName(CLASS_NAME, className as string)

  useEffect((): (() => void) | undefined => {
    if (initializedMap || mapContainerRef.current === null) {
      return
    }

    setInitializedMap(true)

    const map = new mapboxgl.Map({
      container: mapContainerRef.current as HTMLElement,
      style: 'mapbox://styles/mapbox/light-v10'
    })

    map.addControl(new mapboxgl.NavigationControl())

    map.on('style.load', (): void => {
      setStateMapRef({ current: map })
    })

    // Wrapped in try/catch due to internal mapbox error...
    return (): void => {
      stateMapRef?.current?.remove()
    }
  }, [mapContainerRef.current, initializedMap])

  return <div className={finalClassName} ref={mapContainerRef} />
}

export default Map
export { CLASS_NAME }
