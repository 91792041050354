import ReactSwitch from 'react-switch'
import React, { useCallback } from 'react'

import * as H from '../../hooks'

import { CLASS_NAME } from './const'
import { type SwitchProps } from './types'
import { propTypes, defaultProps } from './props'

import './style.scss'

const Switch: React.FC<SwitchProps> = (props: SwitchProps) => {
  const { value, optionA, optionB, onChange, className } = props
  const finalClassName = H.useClassName(CLASS_NAME, className)
  const onSwitchChange = useCallback((): void => {
    const nextValue = value === optionA.value ? optionB.value : optionA.value

    onChange(nextValue)
  }, [value, optionA, optionB, onChange])

  return (
    <div className={finalClassName}>
      <div className={`${CLASS_NAME}-inner`}>
        <p>{optionA.label}</p>

        <ReactSwitch
          height={14}
          width={38}
          onChange={onSwitchChange}
          checked={value === optionB.value}
        />

        <p>{optionB.label}</p>
      </div>
    </div>
  )
}

Switch.propTypes = propTypes
Switch.defaultProps = defaultProps

export default Switch
export { CLASS_NAME }
export type { SwitchProps as PROPS }
