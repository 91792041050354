import React from 'react'

import * as H from '../../hooks'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type IconCloseProps } from './types'

import './style.scss'

const IconClose: React.FC<IconCloseProps> = (props: IconCloseProps) => {
  const { active, onClick, className } = props
  const finalClassName = H.useClassName(CLASS_NAME, className, 'cst-icon', {
    active: active === true
  })

  return (
    <div onClick={onClick} className={finalClassName}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1449_371)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 0.00927734C5.59609 0.00927734 0 5.60537 0 12.5093C0 19.4124 5.59609 25.0093 12.5 25.0093C19.4031 25.0093 25 19.4132 25 12.5093C25 5.60537 19.4031 0.00927734 12.5 0.00927734ZM12.5 23.4468C6.45937 23.4468 1.5625 18.5499 1.5625 12.5093C1.5625 6.46865 6.45937 1.57178 12.5 1.57178C18.5406 1.57178 23.4375 6.46865 23.4375 12.5093C23.4375 18.5499 18.5406 23.4468 12.5 23.4468Z"
            fill="#B5B5B5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.757 16.6273L13.6414 12.5109L17.7336 8.4523C18.0422 8.14683 18.0422 7.65229 17.7336 7.34761C17.4258 7.04292 16.9258 7.04292 16.618 7.34761L12.5313 11.4007L8.38359 7.25229C8.07578 6.94292 7.57578 6.94292 7.26797 7.25229C6.96016 7.56089 6.96016 8.06245 7.26797 8.37105L11.4094 12.5132L7.24453 16.6437C6.93672 16.9492 6.93672 17.4437 7.24453 17.7484C7.55234 18.0539 8.05234 18.0539 8.36094 17.7484L12.5203 13.6234L16.6422 17.7453C16.95 18.0546 17.45 18.0546 17.7578 17.7453C18.0648 17.4375 18.0648 16.9367 17.757 16.6273Z"
            fill="#B5B5B5"
          />
        </g>
        <defs>
          <clipPath id="clip0_1449_371">
            <rect
              width="25"
              height="25"
              fill="white"
              transform="translate(0 0.00927734)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

IconClose.propTypes = propTypes
IconClose.defaultProps = defaultProps

export default IconClose
export { CLASS_NAME }
export type { IconCloseProps as PROPS }
