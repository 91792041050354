import _last from 'lodash/last'

import { type ChartDataPoint } from '../../../types/ui'

const genLinePathString = (
  path: ChartDataPoint[],
  scaleX: any,
  scaleY: any
): string => {
  if (path.length < 2) {
    return ''
  }

  const startPoint = {
    x: scaleX(path[0]?.x),
    y: scaleY(path[0]?.y)
  }

  const lastPoint = {
    x: scaleX(_last(path)?.x),
    y: scaleY(_last(path)?.y)
  }

  const points = path.map(({ x, y }) => ({
    x: scaleX(x),
    y: scaleY(y)
  }))

  return [
    `M ${startPoint.x},${startPoint.y}`,
    points.map(({ x, y }): string => `L ${x},${y}`).join(' '),
    `T ${lastPoint.x},${lastPoint.y}`
  ].join(' ')
}

export default genLinePathString
