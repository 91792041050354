import { useState, useCallback } from 'react'

import { type Emissions } from '../types/data'

interface UseInfoModalRes {
  infoModalEmissions: Emissions | null
  onInfoModalClose: () => void
  onInfoModalOpen: (e: Emissions) => void
}

const useInfoModal = (): UseInfoModalRes => {
  const [infoModalEmissions, setInfoModalEmissions] =
    useState<Emissions | null>(null)

  const onInfoModalClose = useCallback((): void => {
    setInfoModalEmissions(null)
  }, [setInfoModalEmissions])

  const onInfoModalOpen = useCallback(
    (e: Emissions) => {
      setInfoModalEmissions(e)
    },
    [setInfoModalEmissions]
  )

  return {
    infoModalEmissions,
    onInfoModalClose,
    onInfoModalOpen
  }
}

export default useInfoModal
