/* eslint @typescript-eslint/indent: 0 */

import * as d3 from 'd3'
import { useMemo } from 'react'

import { type ChartDataPoint } from '../../../types/ui'

const useScaleX = (data: ChartDataPoint[], width: number): any =>
  useMemo(
    (): any =>
      d3
        .scaleBand()
        .domain(data.map(({ x }) => x as string))
        .range([0, width]),
    [data, width]
  )

export default useScaleX
