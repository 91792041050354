import React from 'react'
import { useTranslation } from 'react-i18next'

import * as H from 'hooks'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type NoDataSkeletonProps } from './types'

import './style.scss'

const NoDataSkeleton: React.FC<NoDataSkeletonProps> = (
  props: NoDataSkeletonProps
) => {
  const { label, className } = props

  const { t } = useTranslation()
  const finalClassName = H.useClassName(CLASS_NAME, className)

  return (
    <div className={finalClassName}>
      <p>{label ?? t('no_data_skeleton.default_label')}</p>
    </div>
  )
}

NoDataSkeleton.propTypes = propTypes
NoDataSkeleton.defaultProps = defaultProps

export default NoDataSkeleton
export { CLASS_NAME }
export type { NoDataSkeletonProps as PROPS }
