import { type Emissions } from '../../types/data'

const getEmissionsFeatureProperty = (
  emissions: Emissions,
  key: string,
  defaultValue?: any
): string => {
  const { feature } = emissions
  const { properties } = feature

  return (properties as any)[key] ?? defaultValue
}

export default getEmissionsFeatureProperty
