import React from 'react'

import * as H from '../../hooks'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type IconInfoProps } from './types'

import './style.scss'

const IconInfo: React.FC<IconInfoProps> = (props: IconInfoProps) => {
  const { active, className } = props
  const finalClassName = H.useClassName(CLASS_NAME, className, 'cst-icon', {
    active: active === true
  })

  return (
    <div className={finalClassName}>
      <svg
        width="27"
        height="26"
        viewBox="0 0 27 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.1206 23.3922C10.978 23.3922 8.8835 22.7812 7.10197 21.6366C5.32044 20.4921 3.93191 18.8652 3.11195 16.9618C2.292 15.0584 2.07747 12.9639 2.49547 10.9433C2.91348 8.92268 3.94525 7.06661 5.46033 5.60982C6.97539 4.15302 8.90571 3.16092 11.0072 2.759C13.1086 2.35707 15.2869 2.56335 17.2663 3.35177C19.2459 4.14018 20.9379 5.47531 22.1282 7.18832C23.3186 8.90133 23.954 10.9153 23.954 12.9755C23.9508 15.7373 22.8085 18.385 20.7775 20.3379C18.7465 22.2907 15.9929 23.3891 13.1206 23.3922ZM13.1206 4.64217C11.4066 4.64217 9.73094 5.13092 8.3057 6.0466C6.88048 6.96228 5.76965 8.26377 5.11369 9.78648C4.45773 11.3092 4.2861 12.9848 4.6205 14.6012C4.95491 16.2178 5.78033 17.7026 6.99239 18.8681C8.20444 20.0335 9.74869 20.8272 11.4299 21.1487C13.111 21.4703 14.8536 21.3052 16.4373 20.6745C18.0209 20.0437 19.3744 18.9757 20.3268 17.6053C21.279 16.2349 21.7873 14.6237 21.7873 12.9755C21.7847 10.7661 20.8708 8.64796 19.246 7.08569C17.6213 5.52343 15.4184 4.64466 13.1206 4.64217ZM13.1206 18.1838C12.8333 18.1838 12.5577 18.074 12.3546 17.8787C12.1515 17.6834 12.0373 17.4184 12.0373 17.1422V11.9338C12.0373 11.6576 12.1515 11.3926 12.3546 11.1973C12.5577 11.002 12.8333 10.8922 13.1206 10.8922C13.4079 10.8922 13.6835 11.002 13.8867 11.1973C14.0898 11.3926 14.204 11.6576 14.204 11.9338V17.1422C14.204 17.4184 14.0898 17.6834 13.8867 17.8787C13.6835 18.074 13.4079 18.1838 13.1206 18.1838ZM13.1206 9.85051C12.9064 9.85051 12.6969 9.78942 12.5187 9.67495C12.3406 9.56049 12.2018 9.39781 12.1198 9.20747C12.0377 9.01713 12.0163 8.80769 12.0581 8.60562C12.0999 8.40356 12.2031 8.21795 12.3546 8.07228C12.5062 7.9266 12.6991 7.82738 12.9093 7.78719C13.1194 7.74699 13.3373 7.76763 13.5352 7.84646C13.7332 7.92531 13.9024 8.05883 14.0214 8.23012C14.1405 8.40142 14.204 8.60282 14.204 8.80884C14.204 9.08511 14.0898 9.35006 13.8867 9.54541C13.6835 9.74077 13.4079 9.85051 13.1206 9.85051Z"
          fill="#A0A0A0"
        />
      </svg>
    </div>
  )
}

IconInfo.propTypes = propTypes
IconInfo.defaultProps = defaultProps

export default IconInfo
export { CLASS_NAME }
export type { IconInfoProps as PROPS }
