import _isUndefined from 'lodash/isUndefined'

import DB from 'data/db'
import type API from 'data/api'
import { DB_EMISSIONS_STORE_NAME } from 'config'

const ensureEmissionsIsCached = async (
  db: DB,
  api: API,
  sub: string,
  featureUUID: string,
  featureLCC: string
): Promise<void> => {
  const emissionsKey = DB.getEmissionsKey(sub, featureUUID, featureLCC)
  const existingEmissions = await db.getItem(
    emissionsKey,
    DB_EMISSIONS_STORE_NAME
  )

  if (
    !_isUndefined(existingEmissions) &&
    !DB.isDBItemExpired(existingEmissions)
  ) {
    return
  }

  const emissions = await api.getFeatureEmissions(sub, featureUUID, featureLCC)

  await db.saveItem(
    {
      key: emissionsKey,
      data: emissions,
      expiry: +DB.getExpiryDate()
    },
    DB_EMISSIONS_STORE_NAME
  )
}

export default ensureEmissionsIsCached
