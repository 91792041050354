export enum EmissionsUnit {
  Tons = 'tCO2',
  KTons = 'ktCO2',
  TonsPerHa = 'tCO2/ha',
  KTonsPerHa = 'ktCO2/ha'
}

export enum SortDirection {
  Ascending = 1,
  Descending = 2
}

export enum IGBPLandCoverClass {
  BarrenSparseVegetation = 'BSV',
  Croplands = 'CRO',
  ClosedShrublands = 'CSH',
  CroplandNaturalVegetationMosaics = 'CVM',
  DeciduousBroadleafForest = 'DBF',
  DeciduousNeedleleafForest = 'DNF',
  EvergreenBroadleafForest = 'EBF',
  EvergreenNeedleleafForest = 'ENF',
  Grasslands = 'GRA',
  MixedForest = 'MF',
  OpenShrublands = 'OSH',
  Savannas = 'SAV',
  SnowAndIce = 'SNO',
  UrbanAndBuiltUp = 'URB',
  WaterBodies = 'WAT',
  PermanentWetlands = 'WET',
  WoodySavannas = 'WSA'
}

export enum IGBPLandCoverClassNames {
  BSV = 'Barren Sparse Vegetation',
  CRO = 'Croplands',
  CSH = 'Closed Shrublands',
  CVM = 'Cropland Natural Vegetation Mosaics',
  DBF = 'Deciduous Broadleaf Forest',
  DNF = 'Deciduous Needleleaf Forest',
  EBF = 'Evergreen Broadleaf Forest',
  ENF = 'Evergreen Needleleaf Forest',
  GRA = 'Grasslands',
  MF = 'Mixed Forest',
  OSH = 'Open Shrublands',
  SAV = 'Savannas',
  SNO = 'Snow and Ice',
  URB = 'Urban and Built-up',
  WAT = 'Water Bodies',
  WET = 'Permanent Wetlands',
  WSA = 'Woody Savannas'
}

export const IGBPLandCoverClasses = Object.values(IGBPLandCoverClass)

export enum LandUse {
  Port = 'Port',
  Industrial = 'Industrial',
  Forest = 'Forest',
  Grassland = 'Grassland',
  Cropland = 'Cropland',
  Urban = 'Urban',
  DeciduousForest = 'Deciduous forest',
  MixedForest = 'Mixed forest',
  EvergreenBroadleafForest = 'Evergreen Broadleaf forest'
}
