import PropTypes from 'prop-types'

export const propTypes = {
  className: PropTypes.string,
  emissions: PropTypes.array.isRequired,
  filters: PropTypes.any.isRequired,
  selectedEmissions: PropTypes.array.isRequired,
  selectedGroups: PropTypes.array.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  onShowFeatureInfo: PropTypes.func.isRequired,
  setSelectedEmissions: PropTypes.func.isRequired
}

export const defaultProps = {}
