import PropTypes from 'prop-types'

export const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isDeslectAllEnabled: PropTypes.bool,
  isSelectAllEnabled: PropTypes.bool,
  onSelectAll: PropTypes.func,
  onDeslectAll: PropTypes.func
}

export const defaultProps = {}
