import React, { useMemo } from 'react'

import * as H from '../../hooks'
import { DEFAULT_ICON_WIDTH_PX } from '../../config'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type IconNavAnalyticsProps } from './types'

import './style.scss'

const WIDTH_HEIGHT_RATIO = 0.633

const IconNavAnalytics: React.FC<IconNavAnalyticsProps> = (
  props: IconNavAnalyticsProps
) => {
  const { active, className, width = DEFAULT_ICON_WIDTH_PX } = props
  const finalClassName = H.useClassName(CLASS_NAME, className, 'cst-icon', {
    active: active === true
  })

  const height = useMemo((): string => `${width * WIDTH_HEIGHT_RATIO}`, [width])

  return (
    <div className={finalClassName}>
      <svg
        width={`${width}`}
        height={height}
        viewBox="0 0 30 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.98193 17.7804C4.0865 17.7804 4.98193 16.8671 4.98193 15.7404C4.98193 14.6138 4.0865 13.7004 2.98193 13.7004C1.87736 13.7004 0.981934 14.6138 0.981934 15.7404C0.981934 16.8671 1.87736 17.7804 2.98193 17.7804Z"
          stroke="#D9D9D9"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M10.9819 10.9806C12.0865 10.9806 12.9819 10.0673 12.9819 8.94063C12.9819 7.81397 12.0865 6.90063 10.9819 6.90063C9.87736 6.90063 8.98193 7.81397 8.98193 8.94063C8.98193 10.0673 9.87736 10.9806 10.9819 10.9806Z"
          stroke="#D9D9D9"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M18.9819 13.7006C20.0865 13.7006 20.9819 12.7873 20.9819 11.6606C20.9819 10.5339 20.0865 9.62061 18.9819 9.62061C17.8774 9.62061 16.9819 10.5339 16.9819 11.6606C16.9819 12.7873 17.8774 13.7006 18.9819 13.7006Z"
          stroke="#D9D9D9"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M26.9819 4.86052C28.0865 4.86052 28.9819 3.94718 28.9819 2.82052C28.9819 1.69386 28.0865 0.780518 26.9819 0.780518C25.8774 0.780518 24.9819 1.69386 24.9819 2.82052C24.9819 3.94718 25.8774 4.86052 26.9819 4.86052Z"
          stroke="#D9D9D9"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M4.66321 14.3125L9.44587 10.2461"
          stroke="#D9D9D9"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M12.8805 9.58521L17.0845 11.0146"
          stroke="#D9D9D9"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M25.382 4.04443L20.37 10.008"
          stroke="#D9D9D9"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </svg>
    </div>
  )
}

IconNavAnalytics.propTypes = propTypes
IconNavAnalytics.defaultProps = defaultProps

export default IconNavAnalytics
export { CLASS_NAME }
export type { IconNavAnalyticsProps as PROPS }
