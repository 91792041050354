import _isObject from 'lodash/isObject'

import { type Emissions, type Feature, type GeoJSON } from '../../types/data'

const genGeoJSON = (
  data: Emissions[] | Feature[],
  withFullGeometry?: boolean
): GeoJSON => ({
  type: 'FeatureCollection',
  features: _isObject((data as Emissions[])?.[0]?.data)
    ? (data as Emissions[]).map((e) =>
        withFullGeometry === true ? e.featureWithGeometry : e.feature
      )
    : (data as Feature[])
})

export default genGeoJSON
