import _chunk from 'lodash/chunk'
import _isNil from 'lodash/isNil'
import _isEmpty from 'lodash/isEmpty'
import _compact from 'lodash/compact'
import React, { useMemo } from 'react'
import _isFinite from 'lodash/isFinite'
import _isUndefined from 'lodash/isUndefined'
import { useTranslation } from 'react-i18next'

import * as H from 'hooks'
import * as U from 'utils'
import IconClose from 'components/IconClose/IconClose'
import { type FeatureProperties } from 'types/data'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type EmissionsDetailsModalProps } from './types'

import { CountryCode } from 'const/ui'
import { IGBPLandCoverClassNames } from 'types/common'

import './style.scss'

interface FeatureInfoListItem {
  label: string
  value: string
}

const EmissionsDetailsModal: React.FC<EmissionsDetailsModalProps> = (
  props: EmissionsDetailsModalProps
) => {
  const { onClose, emissions, className } = props

  const { t } = useTranslation()
  const finalClassName = H.useClassName(CLASS_NAME, className)

  const { total, totalIntensity, feature } = emissions ?? {}
  const { properties = {} } = feature ?? {}
  const {
    country_code: featureCountryCode,
    land_cover: featureLCC,
    site_name: featureSiteName,
    feature_uuid: featureUUID,
    land_use: featureLandUse,
    name: featureName,
    area: featureArea
  } = properties as FeatureProperties

  const uiFeatureLCC =
    IGBPLandCoverClassNames[
      featureLCC as keyof typeof IGBPLandCoverClassNames
    ] ?? featureLCC

  const uiFeatureCountryCode =
    CountryCode[featureCountryCode as keyof typeof CountryCode] ??
    featureCountryCode

  const featureInfoListItems: FeatureInfoListItem[] = useMemo(
    (): FeatureInfoListItem[] =>
      _compact([
        !_isEmpty(uiFeatureCountryCode) && {
          label: t('emissions_details_modal.country_label'),
          value: uiFeatureCountryCode
        },
        !_isEmpty(uiFeatureLCC) && {
          label: t('emissions_details_modal.lcc_label'),
          value: uiFeatureLCC
        },
        !_isEmpty(featureSiteName) && {
          label: t('emissions_details_modal.site_name_label'),
          value: featureSiteName
        },
        !_isEmpty(featureName) && {
          label: t('emissions_details_modal.name_label'),
          value: featureName
        },
        !_isEmpty(featureLandUse) && {
          label: t('emissions_details_modal.land_use_label'),
          value: featureLandUse
        },
        !_isEmpty(featureUUID) && {
          label: t('emissions_details_modal.uuid_label'),
          value: featureUUID
        },
        _isFinite(featureArea) && {
          label: t('emissions_details_modal.area_label'),
          value: `${featureArea.toFixed(2)} ha`
        },
        !_isUndefined(total) && {
          label: t('emissions_details_modal.total_nee_label'),
          value: U.getFormattedEmissionsValue(total)
        },
        !_isUndefined(totalIntensity) && {
          label: t('emissions_details_modal.total_intensity_label'),
          value: U.getFormattedEmissionsValue(totalIntensity, true)
        }
      ]),
    [
      uiFeatureCountryCode,
      featureSiteName,
      featureLandUse,
      uiFeatureLCC,
      featureName,
      featureArea,
      featureUUID,
      t
    ]
  )

  const featureInfoListItemsChunks = useMemo(
    (): FeatureInfoListItem[][] => _chunk(featureInfoListItems, 2),
    [featureInfoListItems]
  )

  if (_isNil(emissions)) {
    return null
  }

  return (
    <div className={finalClassName}>
      <div className={`${CLASS_NAME}-wrapper`}>
        <div className={`${CLASS_NAME}-header`}>
          <IconClose onClick={onClose} />
        </div>

        {featureInfoListItemsChunks.map(
          (featureInfoListItemsChunk: any, i: number) => (
            <ul key={i}>
              {(featureInfoListItemsChunk as FeatureInfoListItem[]).map(
                ({ label, value }) => (
                  <li key={label}>
                    <p>{label}</p>
                    <p>{value}</p>
                  </li>
                )
              )}
            </ul>
          )
        )}
      </div>
    </div>
  )
}

EmissionsDetailsModal.propTypes = propTypes
EmissionsDetailsModal.defaultProps = defaultProps

export default EmissionsDetailsModal
export { CLASS_NAME }
export type { EmissionsDetailsModalProps as PROPS }
