import _has from 'lodash/has'
import _isArray from 'lodash/isArray'
import _isFinite from 'lodash/isFinite'

import convertDateFromApiToStandardDateObject from './convertDateFromApiToStandardDateObject'
import getEmissionsTotalArea from './get_emissions_total_area'

import { type ChartDataPoint } from 'types/ui'
import { DataType, type Emissions, type EmissionsData } from 'types/data'

/**
 * Generates an array of *yearly* ChartDataPoint structures (x, y) for the
 * given emissions data. If multiple emissions objects are provided, an array
 * of chart datasets is returned.
 *
 * Area needs to be explicitly passed if given an EmissionsProperties instance.
 */
export default function getChartData(
  emissionsOrData: EmissionsData | Emissions | Emissions[],
  dataType?: DataType,
  inputArea?: number
): ChartDataPoint[] | ChartDataPoint[][] {
  if (_isArray(emissionsOrData as Emissions[])) {
    const totalArea = getEmissionsTotalArea(emissionsOrData as Emissions[])
    const mergedChartData = (emissionsOrData as Emissions[]).map(
      (e: Emissions) => getChartData(e, dataType) as ChartDataPoint[]
    )

    // prettier-ignore
    return dataType !== DataType.Intensity
      ? mergedChartData
      : mergedChartData.map((chartData: ChartDataPoint[]) => chartData.map(({ x, y }) => ({
        x,
        y: totalArea === 0
          ? 0
          : y / totalArea
      })))
  }

  const data = _has(emissionsOrData, 'data')
    ? (emissionsOrData as Emissions).data
    : (emissionsOrData as EmissionsData)

  const area = _has(emissionsOrData, 'data')
    ? (emissionsOrData as Emissions).area
    : inputArea ?? 0

  const dates = Object.keys(data).sort((a, b) => Number(a) - Number(b))

  return dates
    .map((date: string, i) => {
      const x = convertDateFromApiToStandardDateObject(date)

      let y = data[date]

      if (dataType === DataType.Intensity) {
        y = area === 0 ? 0 : y / area
      }

      return {
        x,
        y
      }
    })
    .filter(({ y }) => _isFinite(y)) as ChartDataPoint[]
}
