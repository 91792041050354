import { useMemo } from 'react'
import _isEmpty from 'lodash/isEmpty'

import * as U from '../../utils'
import { type Emissions } from '../../types/data'
import { type ChartDataPoint } from '../../types/ui'

const useChartData = (
  emissions: Emissions[],
  year: number | null,
  relative?: boolean,
  truncateX?: boolean
): ChartDataPoint[] =>
  useMemo(
    (): ChartDataPoint[] =>
      emissions.map((e: Emissions): ChartDataPoint => {
        const total =
          year == null ? e.total : U.getEmissionsTotalForYear(e, year)

        const totalIntensity =
          year == null
            ? e.totalIntensity
            : U.getEmissionsTotalIntensityForYear(e, year)

        const name = U.getEmissionsFeatureName(e)
        const featureUUID = U.getEmissionsFeatureUUID(e)

        // prettier-ignore
        const x = _isEmpty(name)
          ? truncateX !== false
            ? U.truncateStringForUI(featureUUID, 10)
            : featureUUID
          : truncateX !== false
            ? U.truncateStringForUI(name, 10)
            : name

        const y = relative === true ? totalIntensity ?? 0 : total ?? 0

        return { x, y }
      }),
    [emissions, relative]
  )

export default useChartData
