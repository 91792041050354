import cn from 'clsx'
import _noop from 'lodash/noop'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import * as H from '../../hooks'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type ChartPageControlsProps } from './types'

import './style.scss'

const ChartPageControls: React.FC<ChartPageControlsProps> = (
  props: ChartPageControlsProps
) => {
  const { page, pages, setPage, className } = props

  const { t } = useTranslation()
  const finalClassName = H.useClassName(CLASS_NAME, className)
  const isOnFirstPage = page === 1
  const isOnLastPage = page === pages
  const onClickNextPage = useCallback((): void => {
    if (!isOnLastPage) {
      setPage(page + 1)
    }
  }, [pages, page])

  const onClickPreviousPage = useCallback((): void => {
    if (!isOnFirstPage) {
      setPage(page - 1)
    }
  }, [pages, page])

  if (pages === 1) {
    return null
  }

  return (
    <div className={finalClassName}>
      <p
        onClick={isOnFirstPage ? _noop : onClickPreviousPage}
        className={cn(`${CLASS_NAME}-previous-page`, {
          disabled: isOnFirstPage
        })}
      >
        {t('chart_page_controls.previous')}
      </p>

      <p className={`${CLASS_NAME}-page`}>
        {t('chart_page_controls.page')} {page} {t('chart_page_controls.of')}{' '}
        {pages}
      </p>

      <p
        onClick={isOnLastPage ? _noop : onClickNextPage}
        className={cn(`${CLASS_NAME}-next-page`, {
          disabled: isOnLastPage
        })}
      >
        {t('chart_page_controls.next')}
      </p>
    </div>
  )
}

ChartPageControls.propTypes = propTypes
ChartPageControls.defaultProps = defaultProps

export default ChartPageControls
export { CLASS_NAME }
export type { ChartPageControlsProps as PROPS }
