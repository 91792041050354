import cn from 'clsx'
import _noop from 'lodash/noop'
import _isEmpty from 'lodash/isEmpty'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useContext, useState, useCallback } from 'react'

import * as H from 'hooks'
import * as U from './utils'
import * as OH from './hooks'
import * as C from 'components'
import AuthContext from 'auth_context'
import { type LoginProps } from './types'
import { DOCUMENT_TITLE, PATH, CLASS_NAME } from './const'
import { PATH as DASHBOARD_PAGE_PATH } from 'pages/dashboard/Dashboard'

import './style.scss'

/**
 * Login page; handles logins, signups, and password reset.
 */
const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const { className } = props

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { user, setUser } = useContext(AuthContext)

  useEffect((): void => {
    if (user !== null) {
      navigate(DASHBOARD_PAGE_PATH)
    }
  }, [user])

  H.useDocumentTitle(DOCUMENT_TITLE)

  const [loading, setLoading] = useState<boolean>(false)
  const [formError, setFormError] = useState<string>('')
  const [cognitoUserState, setCognitoUserState] = useState()
  const finalClassName = H.useClassName(CLASS_NAME, className)
  const [authState, setAuthState] = useState(U.AuthStates.SignIn)

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [resetCode, setResetCode] = useState<string>('')

  const emailIsDisabled =
    authState === U.AuthStates.NewPassword ||
    authState === U.AuthStates.ExpiredCodeException ||
    loading

  const passwordIsDisabled =
    authState === U.AuthStates.ResetPassword ||
    authState === U.AuthStates.NewPassword ||
    authState === U.AuthStates.ExpiredCodeException ||
    loading

  const onInputChange = useCallback(
    (setter: (value: string) => void, value: string) => {
      setter(value)
    },
    []
  )

  const onResetPassword = useCallback((): void => {
    setAuthState(U.AuthStates.ResetPassword)
    setPassword('')
    setEmail('')
    setFormError('')
  }, [setAuthState])

  const onSignIn = useCallback((): void => {
    setAuthState(U.AuthStates.SignIn)
    setPassword('')
    setEmail('')
    setFormError('')
  }, [setAuthState])

  const onSubmit = OH.useOnSubmit({
    email,
    password,
    newPassword,
    resetCode,
    authState,
    cognitoUserState,
    setFormError,
    setLoading,
    setAuthState,
    setCognitoUserState,
    setUser
  })

  if (loading) {
    return <C.PageLoader description={t('loading_message')} />
  }

  // prettier-ignore
  return (
    <C.Page className={finalClassName} title={U.TITLE}>
      <form
        className="cst-page-login-form-wrapper"
        onSubmit={
          loading
            ? _noop
            : authState === U.AuthStates.SignIn
              ? onResetPassword
              : onSignIn
        }
      >
        <h1>{(
          (authState === U.AuthStates.ResetPassword) ||
          (authState === U.AuthStates.ResetPasswordStep2)
        )
          ? t('login.title_reset_password')
          : t('login.title_login')}
        </h1>

        <C.Input
          type="text"
          id="cst-login-email"
          placeholder={t('login.email_placeholder')}
          value={email}
          disabled={emailIsDisabled}
          className={cn('primary', { disabled: emailIsDisabled })}
          onChange={
            emailIsDisabled ? _noop : onInputChange.bind(null, setEmail)
          }
        />

        {(
          (authState === U.AuthStates.SignIn) ||
          (authState === U.AuthStates.ResetPasswordStep2)
        ) && (
          <C.Input
            type="password"
            id="cst-login-password"
            placeholder={
              authState === U.AuthStates.ResetPasswordStep2
                ? t('login.new_password_placeholder')
                : t('login.password_placeholder')}
            disabled={passwordIsDisabled}
            value={password}
            className={cn('primary', {
              disabled: passwordIsDisabled
            })}
            onChange={
              passwordIsDisabled ? _noop : onInputChange.bind(null, setPassword)
            }
          />
        )}

        {authState === U.AuthStates.ResetPasswordStep2 && (
          <C.Input
            type="text"
            id="cst-login-reset-code"
            placeholder={t('login.reset_code_placeholder')}
            className={cn('primary', { disabled: loading })}
            value={resetCode}
            disabled={loading}
            onChange={loading ? _noop : onInputChange.bind(null, setResetCode)}
          />
        )}

        {authState === U.AuthStates.NewPassword && (
          <C.Input
            type="password"
            id="cst-login-new-password"
            placeholder={t('login.new_password_placeholder')}
            className={cn('primary', { disabled: loading })}
            value={newPassword}
            disabled={loading}
            onChange={
              loading ? _noop : onInputChange.bind(null, setNewPassword)
            }
          />
        )}

        {!_isEmpty(formError) && <p className="cst-login-error">{formError}</p>}

        <br />
        <br />

        <button
          type="submit"
          className={cn('primary', { disabled: loading })}
          disabled={loading}
          onClick={loading ? _noop : onSubmit}
        >
          {authState === U.AuthStates.ExpiredCodeException
            ? t('login.resend_code_button')
            : authState === U.AuthStates.ResetPassword
              ? t('login.reset_password_button')
              : t('login.submit_button')}
        </button>

        <button
          type="button"
          disabled={loading}
          onClick={authState === U.AuthStates.SignIn ? onResetPassword : onSignIn}
          className={cn({ disabled: loading })}
        >
          {authState === U.AuthStates.SignIn
            ? t('login.forgot_button')
            : t('login.sign_in_button')}
        </button>
      </form>
    </C.Page>
  )
}

export default Login
export { PATH, CLASS_NAME }
export type { LoginProps as PROPS }
