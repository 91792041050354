import * as Sentry from '@sentry/react'

const { NODE_ENV, REACT_APP_SENTRY_DSN } = process.env

export const DEFAULT_ICON_WIDTH_PX = 32
export const HORIZONTAL_BREAKPOINT_PX = 900

export const RECENT_EMISSIONS_STORAGE_KEY = 'recent-emissions'
export const RECENT_EMISSIONS_COUNT = 10

export const RECENT_SUBS_STORAGE_KEY = 'recent-subs'

export const CHART_COLORS = [
  '#179942',
  '#4a4ea6',
  '#9a9a9a',
  '#280307',
  '#110328',
  '#0c5408',
  '#e20018',
  '#bbab00',
  '#e019a3'
]

export const DB_NAME = 'cs-ui-db'
export const DB_VERSION = 1
export const DB_FEATURES_STORE_NAME = 'features'
export const DB_EMISSIONS_STORE_NAME = 'emissions'
export const DB_GEOMETRIES_STORE_NAME = 'geometries'
export const DB_CONFIG = [
  {
    name: DB_FEATURES_STORE_NAME,
    keyPath: 'key',
    autoIncrement: false
  },
  {
    name: DB_EMISSIONS_STORE_NAME,
    keyPath: 'key',
    autoIncrement: false
  },
  {
    name: DB_GEOMETRIES_STORE_NAME,
    keyPath: 'key',
    autoIncrement: false
  }
]

// This is used as the key for groups with items not having a value for the
// grouping property. It is used in tests to target groups with no source key
// value, so this constant is used instead.
export const GROUP_KEY_NONE = 'None'

export const SENTRY_CONFIG =
  NODE_ENV === 'test'
    ? {}
    : {
        dsn: REACT_APP_SENTRY_DSN,
        integrations: [
          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false
          })
        ],

        replaysSessionSampleRate: NODE_ENV === 'development' ? 1 : 0.1,
        replaysOnErrorSampleRate: 1.0
      }
