import PropTypes from 'prop-types'

export const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  controls: PropTypes.any,
  onExport: PropTypes.func
}

export const defaultProps = {}
