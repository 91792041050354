import { Trans } from 'react-i18next'
import _isUndefined from 'lodash/isUndefined'
import React, { useState, useCallback } from 'react'
import { useMeasure, useClickAway } from '@uidotdev/usehooks'

import * as H from 'hooks'
import DownloadButton from 'components/DownloadButton/DownloadButton'

import { CLASS_NAME } from './const'
import { type ChartHeaderProps } from './types'
import { propTypes, defaultProps } from './props'

import './style.scss'

const MIN_WIDTH_FOR_VERTICAL_LAYOUT_PX = 250

const ChartHeader: React.FC<ChartHeaderProps> = (props: ChartHeaderProps) => {
  const { className, title, controls, onExportCSV, onExportJPG, onExportPNG } =
    props

  const [ref, { width }] = useMeasure()

  const finalClassName = H.useClassName(CLASS_NAME, className, {
    vertical: width < MIN_WIDTH_FOR_VERTICAL_LAYOUT_PX
  })

  const [isExportMenuOpen, setIsExportMenuOpen] = useState<boolean>(false)
  const onToggleExportMenu = useCallback(() => {
    setIsExportMenuOpen(!isExportMenuOpen)
  }, [isExportMenuOpen])

  const exportMenuRef = useClickAway(() => {
    setIsExportMenuOpen(false)
  })

  const onClickExportCSV = useCallback(() => {
    setIsExportMenuOpen(false)

    if (!_isUndefined(onExportCSV)) {
      onExportCSV()
    }
  }, [onExportCSV])

  const onClickExportPNG = useCallback(() => {
    setIsExportMenuOpen(false)

    if (!_isUndefined(onExportPNG)) {
      onExportPNG()
    }
  }, [onExportPNG])

  const onClickExportJPG = useCallback(() => {
    setIsExportMenuOpen(false)

    if (!_isUndefined(onExportJPG)) {
      onExportJPG()
    }
  }, [onExportJPG])

  return (
    <div className={finalClassName} ref={ref}>
      {!_isUndefined(title) && title.length > 0 && (
        <div className={`${CLASS_NAME}-title`}>{title}</div>
      )}

      {!_isUndefined(controls) && (
        <div className={`${CLASS_NAME}-controls`}>{controls}</div>
      )}

      {(!_isUndefined(onExportCSV) ||
        !_isUndefined(onExportJPG) ||
        !_isUndefined(onExportPNG)) && (
        <div className={`${CLASS_NAME}-export-wrapper`}>
          <DownloadButton onClick={onToggleExportMenu} />

          {isExportMenuOpen && (
            <ul ref={exportMenuRef}>
              {!_isUndefined(onExportCSV) && (
                <li onClick={onClickExportCSV}>
                  <p>
                    <Trans i18nKey="chart_header.download_as_csv_label">
                      Download as CSV
                    </Trans>
                  </p>
                </li>
              )}

              {!_isUndefined(onExportPNG) && (
                <li onClick={onClickExportPNG}>
                  <p>
                    <Trans i18nKey="chart_header.download_as_png_label">
                      Download as PNG
                    </Trans>
                  </p>
                </li>
              )}

              {!_isUndefined(onExportJPG) && (
                <li onClick={onClickExportJPG}>
                  <p>
                    <Trans i18nKey="chart_header.download_as_jpg_label">
                      Download as JPG
                    </Trans>
                  </p>
                </li>
              )}
            </ul>
          )}
        </div>
      )}
    </div>
  )
}

ChartHeader.propTypes = propTypes
ChartHeader.defaultProps = defaultProps

export default ChartHeader
export { CLASS_NAME }
export type { ChartHeaderProps as PROPS }
