import _isEmpty from 'lodash/isEmpty'
import _includes from 'lodash/includes'

const isUserAdmin = (user?: any): boolean => {
  const groups: string[] =
    (user?.signInUserSession?.accessToken?.payload ?? ({} as any))[
      'cognito:groups'
    ] ?? []

  return !_isEmpty(groups) && _includes(groups, 'admin')
}

export default isUserAdmin
