const downloadImage = (dataURL: string, fileName: string): void => {
  const anchor = document.createElement('a')
  anchor.href = dataURL
  anchor.download = fileName

  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}

export default downloadImage
