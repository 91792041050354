import React from 'react'
import _isUndefined from 'lodash/isUndefined'

import ChartBar from '../ChartBar/ChartBar'
import { type ChartBarSeriesProps } from './types'

import './style.scss'

const ChartBarSeries: React.FC<ChartBarSeriesProps> = (
  props: ChartBarSeriesProps
) => {
  const {
    dataIsIntensity,
    padding = 0,
    onBarClick,
    centerBars,
    features,
    formatX,
    formatY,
    labelX,
    labelY,
    scaleX,
    scaleY,
    width,
    data
  } = props

  const fullBarWidth = data.length === 0 ? 0 : width / data.length

  // prettier-ignore
  const barWidth = fullBarWidth === 0
    ? 0
    : padding === 0
      ? fullBarWidth - 2
      : padding < 1
        ? fullBarWidth - 2 - fullBarWidth * padding
        : fullBarWidth - 2 - padding

  return data.map((point, i: number) => (
    <ChartBar
      key={`${i}`}
      width={barWidth}
      data={point}
      scaleX={scaleX}
      scaleY={scaleY}
      padding={padding}
      chartWidth={width}
      formatX={formatX}
      formatY={formatY}
      labelX={labelX}
      labelY={labelY}
      onClick={onBarClick}
      center={centerBars}
      dataIsIntensity={dataIsIntensity}
      feature={_isUndefined(features) ? undefined : features[i]}
    />
  ))
}

export default ChartBarSeries
