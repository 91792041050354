import _isFinite from 'lodash/isFinite'
import _isUndefined from 'lodash/isUndefined'

const setOrAdd = (
  object: Record<string, number>,
  key: string,
  value: number
): void => {
  if (_isUndefined(object[key])) {
    object[key] = value
  } else if (_isFinite(object[key])) {
    object[key] += value
  } else {
    throw new Error(`Object key ${key} value is not a number`)
  }
}

export default setOrAdd
