import _isUndefined from 'lodash/isUndefined'

/**
 * Download a CSV string as a file.
 *
 * Taken from https://github.com/carbon-design-system/carbon-charts/blob/master/packages/core/src/services/essentials/files.ts
 */
const downloadCSV = (content: string, filename: string): void => {
  const anchor = document.createElement('a')
  const mimeType = 'text/csv;encoding:utf-8'
  const blob = new Blob([content], { type: mimeType })

  // @ts-expect-error property msSaveOrOpenBlob exists on navigator
  if (!_isUndefined(navigator.msSaveBlob)) {
    // @ts-expect-error property msSaveOrOpenBlob exists on navigator
    navigator.msSaveBlob(blob, filename)
  } else if (!_isUndefined(URL) && 'download' in anchor) {
    const href = URL.createObjectURL(blob)

    anchor.href = href
    anchor.setAttribute('download', filename)

    document.body.appendChild(anchor)

    anchor.click()

    document.body.removeChild(anchor)
    URL.revokeObjectURL(href)
  } else {
    location.href = `data:application/octet-stream,${encodeURIComponent(
      content
    )}`
  }
}

export default downloadCSV
