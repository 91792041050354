import { Auth } from 'aws-amplify'

interface AuthData {
  jwtToken: string
  sub: string
}

const getAwsSessionParams = async (): Promise<AuthData> => {
  const session = await Auth.currentSession()
  const jwtToken = session.getIdToken().getJwtToken()
  const sub = session.getAccessToken()?.payload?.sub

  return { jwtToken, sub }
}

export default getAwsSessionParams
