import _isEmpty from 'lodash/isEmpty'
import React, { type ReactNode } from 'react'

import * as H from '../../hooks'

import { propTypes, defaultProps } from './props'
import { CLASS_NAME } from './const'
import { type PageProps } from './types'

/**
 * Wrapper for page components that populates the document title.
 */
const Page: React.FC<PageProps> = (props: PageProps) => {
  const { className, title, id, children } = props
  const finalClassName = H.useClassName(CLASS_NAME, className)
  const divProps: Record<string, string | number | ReactNode> = {}

  if (!_isEmpty(id)) {
    divProps.id = id
  }

  H.useDocumentTitle(title)

  return (
    <div className={finalClassName} {...divProps}>
      {children}
    </div>
  )
}

Page.propTypes = propTypes
Page.defaultProps = defaultProps

export default Page
export { CLASS_NAME }
export type { PageProps as PROPS }
