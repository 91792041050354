import { PolygonsPerformanceExtremes } from './types'
import { type Emissions } from '../../types/data'

export default function getEmissionsForSelection(
  selection: PolygonsPerformanceExtremes,
  emissions: Emissions[] | null
): Emissions[] {
  if (emissions === null) {
    return []
  }

  const results = [...emissions]

  switch (selection) {
    case PolygonsPerformanceExtremes.IntensityTenBestPerforming:
      return results.sort((a, b) => {
        return a.totalIntensity - b.totalIntensity
      })

    case PolygonsPerformanceExtremes.IntensityTenWorstPerforming:
      return results.sort((a, b) => {
        return b.totalIntensity - a.totalIntensity
      })

    case PolygonsPerformanceExtremes.NEETenBestPerforming:
      return results.sort((a, b) => {
        return a.total - b.total
      })

    case PolygonsPerformanceExtremes.NEETenWorstPerforming:
      return results.sort((a, b) => {
        return b.total - a.total
      })

    default:
      throw new Error(`Invalid PolygonsPerformanceExtremes: ${selection}`)
  }
}
