import * as d3 from 'd3'
import { useMemo } from 'react'

const useScaleY = (min: number, max: number, height: number): any =>
  useMemo(
    (): any =>
      d3
        .scaleLinear()
        .domain([min > 0 ? 0 : min * 1.1, max < 0 ? 0 : max * 1.1])
        .range([height, 0]),
    [min, max, height]
  )

export default useScaleY
