import _min from 'lodash/min'
import _isArray from 'lodash/isArray'
import _flatten from 'lodash/flatten'

import { type ChartDataPoint } from 'types/ui'

// prettier-ignore
const getEmissionsDataMinY = (data: ChartDataPoint[] | ChartDataPoint[][]): number | undefined => {
  return _isArray(data[0])
    ? _min(
      _flatten((data as ChartDataPoint[][]).map((d) => d.map(({ y }) => y)))
    )
    : _min((data as ChartDataPoint[]).map(({ y }) => y))
}

export default getEmissionsDataMinY
