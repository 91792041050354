import _flatten from 'lodash/flatten'
import _isArray from 'lodash/isArray'

import { type Feature } from '../../types/data'

/**
 * Flattens an array of features with one or multiple land cover
 * classifications per feature into an array of features with a single LCC
 * string.
 */
const flattenFeaturesByLCC = (features: Feature[]): Feature[] => {
  return _flatten(
    features.map((feature: Feature): Feature[] => {
      const { properties } = feature
      const { land_cover: featureLCC } = properties

      const featureLCCs = _isArray(featureLCC) ? featureLCC : [featureLCC]

      return featureLCCs.map(
        (featureLCC: string): Feature =>
          ({
            ...feature,
            properties: {
              ...properties,
              // eslint-disable-next-line camelcase
              land_cover: featureLCC
            }
          }) as any
      )
    })
  )
}

export default flattenFeaturesByLCC
