import _uniq from 'lodash/uniq'
import _flatten from 'lodash/flatten'
import _isArray from 'lodash/isArray'
import _compact from 'lodash/compact'

import { type Emissions } from 'types/data'
import { type IGBPLandCoverClass } from 'types/common'

const getEmissionsLandCoverClasses = (
  emissions: Emissions[]
): IGBPLandCoverClass[] =>
  _uniq(
    _compact(
      _flatten(
        emissions.map(({ feature }) =>
          _isArray(feature?.properties?.land_cover)
            ? feature?.properties?.land_cover
            : [feature?.properties?.land_cover]
        )
      )
    )
  ) as IGBPLandCoverClass[]

export default getEmissionsLandCoverClasses
