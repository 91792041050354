import _get from 'lodash/get'
import _isFinite from 'lodash/isFinite'
import _isUndefined from 'lodash/isUndefined'

import genGroup from './gen_group'
import getGroupKey from './get_group_key'
import addEmissionsToGroup from './add_emissions_to_group'
import { SORT_MODE_PROPERTY_PATH, GroupMode, type SortMode } from 'types/ui'

import { type Emissions, type EmissionsGroup } from 'types/data'

const groupEmissions = (
  emissions: Emissions[],
  mode: GroupMode,
  sortMode?: SortMode
): EmissionsGroup[] => {
  if (mode === GroupMode.None) {
    return [genGroup(emissions, 'Ungrouped')]
  }

  const groups: EmissionsGroup[] = []

  emissions.forEach((e: Emissions) => {
    const groupKey = getGroupKey(mode, e)
    const group = groups.find(({ key }) => key === groupKey)

    if (_isUndefined(group)) {
      groups.push(genGroup([e], groupKey))
    } else {
      addEmissionsToGroup(e, group)
    }
  })

  if (!_isUndefined(sortMode)) {
    groups.sort((a: any, b: any) => {
      const sortKey =
        SORT_MODE_PROPERTY_PATH[
          sortMode as keyof typeof SORT_MODE_PROPERTY_PATH
        ]
      const aValue = _get(a, sortKey)
      const bValue = _get(b, sortKey)

      return _isFinite(aValue) ? bValue - aValue : bValue.localeCompare(aValue)
    })
  }

  return groups
}

export default groupEmissions
