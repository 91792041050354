import PropTypes from 'prop-types'

import { DEFAULT_ICON_WIDTH_PX } from '../../config'

export const propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  width: PropTypes.number
}

export const defaultProps = {
  width: DEFAULT_ICON_WIDTH_PX
}
