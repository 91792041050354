import _isNil from 'lodash/isNil'
import { type Map } from 'mapbox-gl'
import { useCallback, useEffect } from 'react'
import _isUndefined from 'lodash/isUndefined'

const MIN_MARKER_ZOOM_LEVEL = 12

const useSetMarkersVisibleOnMapZoom = (
  map: Map | undefined,
  setAreMarkersVisible: (areMarkersVisible: boolean) => void
): void => {
  const onMapZoom = useCallback((): void => {
    if (_isUndefined(map) || _isNil(map) || _isUndefined((map as any).style)) {
      return
    }

    const zoom = map.getZoom()

    setAreMarkersVisible(zoom > MIN_MARKER_ZOOM_LEVEL)
  }, [setAreMarkersVisible, map])

  useEffect((): (() => void) | undefined => {
    if (_isUndefined(map) || _isNil(map) || _isUndefined((map as any).style)) {
      return
    }

    map.on('zoom', onMapZoom)

    return (): void => {
      if (
        _isUndefined(map) ||
        _isNil(map) ||
        _isUndefined((map as any).style)
      ) {
        return
      }

      map.off('zoom', onMapZoom)
    }
  }, [onMapZoom, map])
}

export default useSetMarkersVisibleOnMapZoom
