import React from 'react'
import { Trans } from 'react-i18next'
import _isUndefined from 'lodash/isUndefined'

import * as H from 'hooks'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type EmissionsListHeaderProps } from './types'

import './style.scss'

const EmissionsListHeader: React.FC<EmissionsListHeaderProps> = (
  props: EmissionsListHeaderProps
) => {
  const {
    title,
    subTitle,
    isDeselectAllEnabled,
    isSelectAllEnabled,
    onSelectAll,
    onDeselectAll,
    className
  } = props

  const finalClassName = H.useClassName(CLASS_NAME, className)

  if (
    _isUndefined(title) &&
    _isUndefined(subTitle) &&
    isDeselectAllEnabled !== true &&
    isSelectAllEnabled !== true
  ) {
    return null
  }

  return (
    <div className={finalClassName}>
      {!_isUndefined(title) && (
        <div className={`${CLASS_NAME}-title`}>{title}</div>
      )}

      {!_isUndefined(subTitle) && (
        <div className={`${CLASS_NAME}-sub-title`}>{subTitle}</div>
      )}

      {(isDeselectAllEnabled === true || isSelectAllEnabled === true) && (
        <ul className={`${CLASS_NAME}-controls`}>
          {isDeselectAllEnabled === true && (
            <li
              onClick={onDeselectAll}
              className={`${CLASS_NAME}-controls-deselect-all`}
            >
              <p>
                <Trans i18nKey="emissions_list_header.deselect_all">
                  Deselect All
                </Trans>
              </p>
            </li>
          )}

          {isSelectAllEnabled === true && (
            <li
              onClick={onSelectAll}
              className={`${CLASS_NAME}-controls-select-all`}
            >
              <p>
                <Trans i18nKey="emissions_list_header.select_all">
                  Select all
                </Trans>
              </p>
            </li>
          )}
        </ul>
      )}
    </div>
  )
}

EmissionsListHeader.propTypes = propTypes
EmissionsListHeader.defaultProps = defaultProps

export default EmissionsListHeader
export { CLASS_NAME }
export type { EmissionsListHeaderProps as PROPS }
