export const TITLE = 'Login'
export const PATH = ['/login', '/forgot', '/sign_up']

export const AuthStates = {
  SignIn: 'SignIn',
  LoggedIn: 'LoggedIn',
  SignedOut: 'SignedOut',
  NewPassword: 'NewPassword',
  ResetPassword: 'ResetPassword',
  ResetPasswordStep2: 'ResetPasswordStep2',
  ExpiredCodeException: 'ExpiredCodeException'
}
