import { Amplify } from 'aws-amplify'

const {
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_USER_POOL_ID,
  REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID
} = process.env

const config = {
  Auth: {
    region: REACT_APP_AWS_REGION,
    userPoolId: REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true
  }
}

const initializeAmplify = (): void => {
  Amplify.configure(config)
}

export { initializeAmplify }
