import _compact from 'lodash/compact'
import _isEmpty from 'lodash/isEmpty'

import convertDateFromApiToStandardDateObject from './convertDateFromApiToStandardDateObject'
import getEmissionsDataTotal from './get_emissions_data_total'
import { type EmissionsData, type Emissions } from 'types/data'

export default function getEmissionsWithFilteredData(
  emissions: Emissions[],
  start: Date,
  end: Date
): Emissions[] {
  const yearsInInterval = end.getUTCFullYear() - start.getUTCFullYear() + 1

  return _compact(
    emissions.map((emissionsData: Emissions): Emissions | null => {
      const { area, data, ...otherData } = emissionsData
      const filteredData = {}
      const keys = Object.keys(data)
      const validKeys = keys.filter((key: string): boolean => {
        if (key.length !== 6) {
          return false
        }

        const date = convertDateFromApiToStandardDateObject(key)

        const dateTimestamp = date.valueOf()
        const startTimestamp = start.valueOf()
        const endTimestamp = end.valueOf()

        return dateTimestamp >= startTimestamp && dateTimestamp <= endTimestamp
      })

      // prettier-ignore
      validKeys.forEach((key: string) => {
        (filteredData as EmissionsData)[key] = data[key]
      })

      if (_isEmpty(filteredData)) {
        return null
      }

      const total = getEmissionsDataTotal(filteredData)

      // prettier-ignore
      const totalIntensity = area === 0 || yearsInInterval === 0
        ? 0
        : (total / area) / yearsInInterval

      return {
        ...otherData,
        area,
        total,
        totalIntensity,
        data: filteredData
      }
    })
  )
}
