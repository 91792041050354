import { useState, useMemo } from 'react'
import _chunk from 'lodash/chunk'
import { useTranslation } from 'react-i18next'

import * as U from 'utils'
import * as H from 'hooks'
import Switch from 'components/Switch/Switch'
import BarChart from 'components/BarChart/BarChart'
import LineChart from 'components/LineChart/LineChart'
import { type Emissions, type ChartDataPoint } from 'types'
import { type SwitchOption } from 'components/Switch/types'
import ChartPageControls from 'components/ChartPageControls/ChartPageControls'

import { CLASS_NAME } from './const'
import { DataType } from 'types/data'

import './style.scss'

const HEIGHT = 300
const ID = 'net-emissions'
const ITEMS_PER_PAGE = 10

// This is to render values over 1,000 t in Kt with a suffix.
const tooltipFormatY = (
  y: number,
  dataType: DataType = DataType.NEE
): string => {
  const isInKT = Math.abs(y) > 1000
  const uiY = isInKT ? y / 1000 : y
  const uiValue = +uiY.toFixed(2).toLocaleString()
  const uiUnitPrefix = isInKT ? 'kt' : 't'
  const uiUnit =
    dataType === DataType.NEE ? `${uiUnitPrefix}CO2` : `${uiUnitPrefix}CO2/ha`

  return `${uiValue} ${uiUnit}`
}

type Props = {
  emissions: Emissions[]
  title: string
  className?: string
  split?: boolean
  small?: boolean
  hideMonth?: boolean
}

export default function NetEmissionsChart({
  title,
  split,
  emissions,
  className,
  hideMonth
}: Props) {
  const { t } = useTranslation()
  const [page, setPage] = useState<number>(1)
  const finalClassName = H.useClassName(CLASS_NAME, className)
  const mergedEmissions = H.useFunctionMemo(U.getEmissionsDataMerged, emissions)
  const mergedEmissionsArea = H.useFunctionMemo(
    U.getEmissionsTotalArea,
    emissions
  )

  const dataTypeSwitchOptionA = useMemo(
    (): SwitchOption => ({
      label: t('net_emissions_chart.data_type_switch_option_nee'),
      value: DataType.NEE
    }),
    [t]
  )

  const dataTypeSwitchOptionB = useMemo(
    (): SwitchOption => ({
      label: t('net_emissions_chart.data_type_switch_option_intensity'),
      value: DataType.Intensity
    }),
    [t]
  )

  const [dataType, setDataType] = useState<DataType>(DataType.NEE)

  const labelX = t('net_emissions_chart.label_x')
  const labelY =
    dataType === DataType.NEE
      ? t('net_emissions_chart.label_y_nee')
      : t('net_emissions_chart.label_y_intensity')

  const singleEmissionsChartData = H.useFunctionMemo<ChartDataPoint[]>(
    U.getChartData,
    mergedEmissions,
    dataType,
    mergedEmissionsArea
  )

  const multipleEmissionsChartData = H.useFunctionMemo<ChartDataPoint[]>(
    U.getChartData,
    emissions,
    dataType
  )

  const chartData = useMemo(
    (): ChartDataPoint[] =>
      split === true ? multipleEmissionsChartData : singleEmissionsChartData,
    [split, singleEmissionsChartData, multipleEmissionsChartData]
  )

  const pages = Math.ceil(chartData.length / ITEMS_PER_PAGE)
  const pageOfData = useMemo((): ChartDataPoint[] => {
    if (split !== true) {
      return singleEmissionsChartData
    }

    if (pages === 1) {
      return chartData
    }

    return _chunk(chartData, ITEMS_PER_PAGE)[page - 1]
  }, [chartData, page, pages])

  const tooltipEmissions = useMemo(
    (): Emissions[] | undefined =>
      emissions.length === 1 || split === true ? emissions : undefined,
    [emissions, split]
  )

  const pageOfTooltipEmissions = useMemo((): Emissions[] | undefined => {
    if (pages === 1) {
      return tooltipEmissions
    }

    return _chunk(tooltipEmissions, ITEMS_PER_PAGE)[page - 1]
  }, [emissions, tooltipEmissions, pages, page])

  return (
    <div className={finalClassName}>
      {split !== true && (
        <BarChart
          barPadding={0.1}
          title={title}
          data={pageOfData}
          temporal={true}
          height={HEIGHT}
          dataIsIntensity={dataType === DataType.Intensity}
          formatY={tooltipFormatY}
          labelX={labelX}
          labelY={labelY}
          extraHeaderControls={
            <Switch
              value={dataType}
              onChange={setDataType}
              optionA={dataTypeSwitchOptionA}
              optionB={dataTypeSwitchOptionB}
            />
          }
          hideMonth={hideMonth}
        />
      )}
      {split === true && (
        <LineChart
          id={ID}
          split={split}
          title={title}
          data={pageOfData}
          temporal={true}
          height={HEIGHT}
          dataIsIntensity={dataType === DataType.Intensity}
          tooltipFormatY={tooltipFormatY}
          tooltipEmissions={pageOfTooltipEmissions}
          labelX={labelX}
          labelY={labelY}
          extraHeaderControls={
            <>
              <ChartPageControls page={page} pages={pages} setPage={setPage} />
              <Switch
                value={dataType}
                onChange={setDataType}
                optionA={dataTypeSwitchOptionA}
                optionB={dataTypeSwitchOptionB}
              />
            </>
          }
        />
      )}
    </div>
  )
}
