import _isEmpty from 'lodash/isEmpty'
import _isFunction from 'lodash/isFunction'

import formatLogString from './formatLogString'

const ROOT_SCOPE = 'cs-ui'
const START_MTS = Date.now()

const logString = (handlerName: string, scope: string, str: string): void => {
  const handler = !_isFunction((console as any)[handlerName])
    ? console.log
    : (console as any)[handlerName]

  const finalScope = _isEmpty(scope) ? ROOT_SCOPE : `${ROOT_SCOPE}:${scope}`

  handler(formatLogString(handlerName, finalScope, str, Date.now() - START_MTS))
}

export default logString
