import _sum from 'lodash/sum'
import _compact from 'lodash/compact'
import _isUndefined from 'lodash/isUndefined'
import { useTranslation } from 'react-i18next'

import * as H from 'hooks'
import * as U from 'utils'
import DashboardCard from '../DashboardCard/DashboardCard'

import { CLASS_NAME } from './const'
import { type Emissions } from 'types/data'

import '../style.scss'

type Props = {
  emissions: Emissions[]
  className?: string
}

export default function LandCover({
  emissions,
  className
}: Props): JSX.Element {
  const features = emissions.map(({ feature }) => feature)

  const { t } = useTranslation()
  const finalClassName = H.useClassName(CLASS_NAME, className)

  // prettier-ignore
  const totalArea = features === null
    ? 0
    : _sum(
      _compact(
        features.map(({ properties }) => {
          const { area } = properties

          return _isUndefined(area) ? 0 : area
        })
      )
    )

  return (
    <DashboardCard
      title={t('metric_card_land_cover.title')}
      value={`${U.formatUINumber(totalArea)} ha`}
      className={finalClassName}
    />
  )
}

export { CLASS_NAME }
