/* eslint @typescript-eslint/indent: 0 */

import { useMemo, useRef, useCallback, useContext, type ReactNode } from 'react'
import _isBoolean from 'lodash/isBoolean'
import { useTranslation } from 'react-i18next'
import { type i18n } from 'i18next'

import ChartHeader from 'components/ChartHeader/ChartHeader'
import ChartAxisLeft from 'components/ChartAxisLeft/ChartAxisLeft'
import ChartBarSeries from 'components/ChartBarSeries/ChartBarSeries'
import ChartAxisBottom from 'components/ChartAxisBottom/ChartAxisBottom'
import ChartAxisGridLines from 'components/ChartAxisGridLines/ChartAxisGridLines'
import FluidContentWrapperContext from '../FluidContentWrapper/context'

import * as CSV from 'csv'
import * as U from 'utils'
import * as H from 'hooks'
import * as OH from './hooks'
import { type Feature, type Emissions } from '../../types/data'
import { type ChartDataPoint } from '../../types/ui'

import { CLASS_NAME } from './const'

import './style.scss'

type Props = {
  className?: string
  centerBars?: boolean
  barPadding?: number
  extraHeaderControls?: ReactNode
  temporal?: boolean
  data: ChartDataPoint[]
  features?: Feature[]
  height: number
  labelX?: string
  labelY?: string
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
  onBarClick?: (data: ChartDataPoint) => void
  formatX?: (x: Date | string | number) => string
  formatY?: (y: number) => string
  title?: string
  dataIsIntensity?: boolean
  baselineValue?: number
  hideMonth?: boolean
  csvData?: ChartDataPoint[]
}

export default function BarChart({
  height: aHeight,
  className: aClassName,
  marginTop: aMarginTop = 40,
  marginRight: aMarginRight = 60,
  marginBottom: aMarginBottom = 40,
  marginLeft: aMarginLeft = 40,
  extraHeaderControls,
  dataIsIntensity,
  barPadding = 0,
  labelX = 'x',
  labelY = 'y',
  centerBars,
  onBarClick,
  features,
  temporal,
  formatX,
  formatY,
  csvData,
  baselineValue,
  title,
  data,
  hideMonth
}: Props) {
  const { t, i18n } = useTranslation()
  const exportFileName = t('export.file_name')
  const exportNameColumnLabel = t('export.column_name')
  const exportNEEColumnLabel = t('export.column_nee')
  const exportColumnLabels = useMemo(
    (): string[] => [exportNameColumnLabel, exportNEEColumnLabel],
    [exportNameColumnLabel, exportNEEColumnLabel]
  )

  const dataForMinMaxCalculation =
    baselineValue != null ? [...data, { x: 0, y: baselineValue }] : data
  const min = H.useFunctionMemo<number>(
    U.getEmissionsDataMinY,
    dataForMinMaxCalculation
  )
  const max = H.useFunctionMemo<number>(
    U.getEmissionsDataMaxY,
    dataForMinMaxCalculation
  )

  const { width: contentWrapperWidth } = useContext(FluidContentWrapperContext)
  const svgRef = useRef<any>(null)
  const containerRef = useRef<any>(null)
  const className = H.useClassName(CLASS_NAME, aClassName)
  const scaleY = OH.useScaleY(min ?? 0, max ?? 0, aHeight)
  const marginTop = U.getFiniteWithDefault(aMarginTop, 0)
  const marginRight = U.getFiniteWithDefault(aMarginRight, 0)
  const marginBottom = U.getFiniteWithDefault(aMarginBottom, 0)
  const marginLeft = U.getFiniteWithDefault(aMarginLeft, 0)

  const width = contentWrapperWidth - marginLeft - marginRight - 32
  const height = aHeight + marginTop + marginBottom
  const scaleX =
    temporal === true
      ? OH.useTimeScaleX(data, width)
      : OH.useScaleX(data, width)

  const transform = OH.useTransform(marginLeft, marginTop)
  const transformAxisBottom = OH.useTransformAxisBottom(aHeight)
  const onExportCSV = useCallback((): void => {
    const csvEmissions = (features ?? []).map((feature: Feature): Emissions => {
      const emissions: Emissions = {
        feature,
        featureWithGeometry: feature,
        id: '0',
        data: {},
        total: 0,
        totalIntensity: 0,
        area: feature?.properties?.area ?? 0
      }

      return emissions
    })

    const csv = CSV.generateChartCSV({
      data: csvData ?? data,
      emissions: csvEmissions,
      columnLabels: exportColumnLabels,
      chartTitle: title,
      t
    })

    U.downloadCSV(csv, exportFileName)
  }, [data, exportColumnLabels, exportFileName, features])

  const onExportPNG = useCallback(() => {
    const { current } = containerRef

    // eslint-disable-next-line github/no-then,@typescript-eslint/no-unsafe-argument
    U.exportElementToPNG(current, height + 100, 'result.png').catch(
      (err: Error) => {
        console.error(err)
      }
    )
  }, [height, containerRef.current])

  const onExportJPG = useCallback(() => {
    const { current } = containerRef

    // eslint-disable-next-line github/no-then,@typescript-eslint/no-unsafe-argument
    U.exportElementToJPG(current, height + 100, 'result.jpg').catch(
      (err: Error) => {
        console.error(err)
      }
    )
  }, [height, containerRef.current])

  const tooltipFormatX = (i18n: i18n, date: Date): string => {
    const isoLocaleCode = i18n.languages[0]
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      ...(hideMonth === true ? null : { month: 'long' }),
      timeZone: 'UTC'
    }

    return new Intl.DateTimeFormat(isoLocaleCode, options).format(date)
  }

  return (
    <div className={className} ref={containerRef}>
      <ChartHeader
        title={title}
        onExportCSV={onExportCSV}
        onExportPNG={onExportPNG}
        onExportJPG={onExportJPG}
        controls={extraHeaderControls}
      />
      <div className="cst-bar-chart-wrapper">
        <svg width={width + marginLeft + marginRight} height={height + 100}>
          <g transform={transform} ref={svgRef}>
            <line
              x1={0}
              y1={scaleY(0)}
              x2={width}
              y2={scaleY(0)}
              stroke="#ccc"
              strokeDasharray="8, 8"
            />
            <ChartAxisBottom
              label={labelX}
              chartHeight={height}
              chartWidth={width}
              scale={scaleX}
              transform={transformAxisBottom}
            />
            <ChartAxisLeft scale={scaleY} label={labelY} />
            <ChartAxisGridLines scaleX={scaleX} scaleY={scaleY} />
            <ChartBarSeries
              data={data}
              width={width}
              scaleX={scaleX}
              scaleY={scaleY}
              labelX={labelX}
              labelY={labelY}
              formatY={formatY}
              features={features}
              padding={barPadding}
              onBarClick={onBarClick}
              dataIsIntensity={dataIsIntensity}
              formatX={formatX ?? tooltipFormatX.bind(null, i18n)}
              centerBars={
                _isBoolean(centerBars) ? centerBars : temporal !== true
              }
            />
            {baselineValue != null && (
              <line
                x1="0"
                x2={width}
                y1={scaleY(baselineValue)}
                y2={scaleY(baselineValue)}
                stroke="#f33"
                strokeDasharray="10, 10"
                strokeWidth="4"
              />
            )}
          </g>
        </svg>
      </div>
    </div>
  )
}
