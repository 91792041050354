import { useMemo } from 'react'

/**
 * Utility to reduce boilerplace when using `useMemo` with a single function.
 * Calls the function with the provided arguments and includes them in the
 * `useMemo` dependency array.
 *
 * @example
 * const filteredEmissions = H.useFunctionMemo(
 *   U.getFilteredEmissions,
 *   emissions ?? [],
 *   filters
 * )
 */
const useFunctionMemo = <T>(func: any, ...args: any[]): T =>
  useMemo((): T => func(...args), [...args])

export default useFunctionMemo
