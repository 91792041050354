import cn from 'clsx'
import React from 'react'
import _isEmpty from 'lodash/isEmpty'
import { Trans, useTranslation } from 'react-i18next'

import * as H from '../../hooks'
import * as U from '../../utils'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type MapEmissionsMarkerProps } from './types'

import './style.scss'

const MapEmissionsMarker: React.FC<MapEmissionsMarkerProps> = (
  props: MapEmissionsMarkerProps
) => {
  const { onClick, emissions, selectionIndex, className } = props

  const { t } = useTranslation()
  const finalClassName = H.useClassName(CLASS_NAME, className)
  const { feature, total, totalIntensity } = emissions
  const { properties } = feature
  const {
    name: featureName,
    site_name: featureSiteName,
    area: featureArea
  } = properties

  const nameFieldLabel = _isEmpty(featureName)
    ? t('map_emissions_marker.site_name_field_label')
    : t('map_emissions_marker.name_field_label')

  const nameFieldValue = _isEmpty(featureName) ? featureSiteName : featureName
  const featureTotalNEE = U.getFormattedEmissionsValue(total)
  const featureTotalIntensity = U.getFormattedEmissionsValue(
    totalIntensity ?? 0,
    true
  )

  const featureAreaLabel =
    featureArea > 1000
      ? `${(featureArea / 1000).toFixed(2)} kHa`
      : `${featureArea.toFixed(2)} Ha`

  return (
    <div onClick={onClick} className={finalClassName}>
      <p className={`${CLASS_NAME}-selection-index`}>{selectionIndex + 1}</p>

      <div className={`${CLASS_NAME}-wrapper`}>
        <ul>
          {!_isEmpty(nameFieldValue) && (
            <li>
              <p>{nameFieldLabel}</p>
              <p>{nameFieldValue}</p>
            </li>
          )}

          <li>
            <p>
              <Trans i18nKey="map_emissions_marker.area_field_label">
                Area
              </Trans>
            </p>
            <p>{featureAreaLabel}</p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              <Trans i18nKey="map_emissions_marker.nee_field_label">
                Total NEE
              </Trans>
            </p>
            <p
              className={cn({
                positive: total > 0,
                negative: total < 0
              })}
            >
              {featureTotalNEE}
            </p>
          </li>

          {featureTotalIntensity !== null && (
            <li>
              <p>
                <Trans i18nKey="map_emissions_marker.intensity_field_label">
                  Intensity
                </Trans>
              </p>
              <p
                className={cn({
                  positive: totalIntensity !== null && totalIntensity > 0,
                  negative: totalIntensity !== null && totalIntensity < 0
                })}
              >
                {featureTotalIntensity}
              </p>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

MapEmissionsMarker.propTypes = propTypes
MapEmissionsMarker.defaultProps = defaultProps

export default MapEmissionsMarker
export { CLASS_NAME }
export type { MapEmissionsMarkerProps as PROPS }
