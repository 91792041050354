import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import * as H from 'hooks'
import BarChart from 'components/BarChart/BarChart'
import { type ChartDataPoint } from 'types'
import NEEPerformanceExtremesChartDropdown from './NEEPerformanceExtremesChartDropdown'
import useChartData from './useChartData'

import { CLASS_NAME } from './const'
import { type Emissions } from '../../types/data'
import { type PolygonsPerformanceExtremes } from './types'

import './style.scss'

const DEFAULT_HEIGHT_PX = 400

type Props = {
  className?: string
  dataIsIntensity?: boolean
  emissions: Emissions[]
  title?: string
  height?: number
  year: number | null
  selection: PolygonsPerformanceExtremes
  selectedEmissions: Emissions[]
  multiLine?: boolean
  setSelection: (selection: PolygonsPerformanceExtremes) => void
  setSelectedEmissions?: (emissions: Emissions[]) => void
  setYear: (year: number) => void
}

export default function NEEPerformanceExtremesChart({
  height = DEFAULT_HEIGHT_PX,
  dataIsIntensity,
  emissions,
  className,
  year,
  setYear,
  selection,
  setSelection,
  selectedEmissions,
  setSelectedEmissions
}: Props) {
  const { t } = useTranslation()
  const chartData = useChartData(selectedEmissions, year, dataIsIntensity)
  const chartDataWithFullXValues = useChartData(
    selectedEmissions,
    year,
    dataIsIntensity,
    false
  )

  const finalClassName = H.useClassName(CLASS_NAME, className)

  const chartDataWithXPrefix = useMemo(
    (): ChartDataPoint[] =>
      chartData.map(({ x, y }, i: number) => {
        const e = selectedEmissions[i]
        const { id } = e

        return {
          x: `ID ${id}: ${x}`,
          y
        }
      }),
    [chartData]
  )

  const title =
    dataIsIntensity === true
      ? t('multiple_emissions_chart.title_nee_intensity', {
          year
        })
      : t('multiple_emissions_chart.title_nee_total', { year })

  const labelY =
    dataIsIntensity === true
      ? t('multiple_emissions_chart.label_y_intensity')
      : t('multiple_emissions_chart.label_y_nee')

  return (
    <div className={finalClassName}>
      <BarChart
        title={title}
        data={chartDataWithXPrefix}
        csvData={chartDataWithFullXValues}
        temporal={false}
        height={height}
        features={emissions.map(({ feature }) => feature)}
        labelX={t('multiple_emissions_chart.label_x')}
        labelY={labelY}
        centerBars={true}
        barPadding={0.25}
        dataIsIntensity={dataIsIntensity}
        extraHeaderControls={
          <NEEPerformanceExtremesChartDropdown
            selection={selection}
            year={year}
            setSelection={setSelection}
            setYear={setYear}
            emissions={emissions ?? []}
            selectedEmissions={selectedEmissions}
            setSelectedEmissions={setSelectedEmissions}
          />
        }
      />
    </div>
  )
}

export { CLASS_NAME }
