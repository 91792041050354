import _isUndefined from 'lodash/isUndefined'
import { type Map, type AnyLayer } from 'mapbox-gl'

const removeMapLayer = (map: Map, data: AnyLayer): void => {
  const existingLayer = map?.getLayer(data.id)

  if (!_isUndefined(existingLayer)) {
    map.removeLayer(data.id)
  }
}

export default removeMapLayer
