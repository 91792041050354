import _flatten from 'lodash/flatten'
import _isEmpty from 'lodash/isEmpty'
import _isArray from 'lodash/isArray'
import _isUndefined from 'lodash/isUndefined'
import axios, { type AxiosResponse } from 'axios'

import type DB from './db'
import Cache from './cache'
import * as U from '../utils'
import { getLogger } from '../logger'
import { type DBTag } from '../types/db'
import { type Feature } from 'types'

const PAGE_SIZE = 500
const { REACT_APP_API_ENDPOINT } = process.env
const l = getLogger('api')

class API {
  db: DB | null
  cache: Cache | null

  constructor(db: DB | null = null) {
    this.db = db
    this.cache = db === null ? null : new Cache(db)
  }

  async queryAPI<T>(
    sub: string,
    path: string,
    queryString: string = ''
  ): Promise<T> {
    try {
      const { jwtToken, sub: awsSub } = await U.getAwsSessionParams()
      const finalSub = _isEmpty(sub) ? awsSub : sub
      const headers = {
        Authorization: jwtToken,
        sub: finalSub
      }

      const queryParam = _isEmpty(queryString) ? '' : `&${queryString}`
      const url = `${REACT_APP_API_ENDPOINT}/assets${path}?sub=${finalSub}${queryParam}`

      const result: AxiosResponse<T> = await axios(url, { headers })
      const { data } = result

      return data
    } catch (err: any) {
      l.error(`failed to query API (${path}): ${err.message}`)

      throw err
    }
  }

  async getFeaturesWithTags(sub: string, tags: string[]): Promise<any> {
    if (this.cache !== null) {
      const cachedData = await this.cache.getFeaturesWithTags(tags)

      if (!_isEmpty(cachedData)) {
        return cachedData
      }
    }

    const startMTS = Date.now()
    const data = (await this.queryAPI(
      sub,
      '/tags',
      tags.map((t: string) => `tags=${t}`).join('&')
    )) as any

    const resTags: DBTag[] = data.resTags

    l.success(
      `fetched ${resTags.length} features with tags ${tags.join(', ')} (took ${Date.now() - startMTS
      }ms)`
    )

    if (this.cache !== null) {
      await this.cacheTags(resTags)
    }

    return resTags
  }

  async getTags(sub: string): Promise<DBTag[]> {
    if (this.cache !== null) {
      const isTagsCached = await this.isTagsCached()

      if (isTagsCached) {
        return await this.cache.getTags()
      }
    }

    const startMTS = Date.now()
    const data = (await this.queryAPI(sub, '/tags')) as any
    const { tags } = data

    l.success(`fetched ${tags.length} tags (took ${Date.now() - startMTS}ms)`)

    if (this.cache !== null) {
      await this.cacheTags(tags as DBTag[])
    }

    return tags
  }

  async getFeatures(sub: string): Promise<any> {
    l.info('fetching full GeoJSON...')

    if (this.cache !== null) {
      const isCached = await this.isFeaturesCached()

      if (isCached) {
        return await this.cache.getFeatures()
      }
    }

    let lastFeatureId = ''
    let isLastPage = false
    let polygons: any = []

    const startMTS = Date.now()

    while (!isLastPage) {
      const lastFeatureIdQueryParam = lastFeatureId !== '' ? `&last_feature_uuid=${lastFeatureId}` : ''

      const data = (await this.queryAPI(sub, '/', `page_size=${PAGE_SIZE}${lastFeatureIdQueryParam}`)) as any

      lastFeatureId = data.features.at(-1).properties.feature_uuid
      isLastPage = data.features.length < PAGE_SIZE

      polygons = [...polygons, ...data.features]
    }

    const featuresMultipleLCC: Feature[] = polygons

    l.success(
      `fetched ${featuresMultipleLCC.length} features(took ${Date.now() - startMTS
      }ms)`
    )

    const features = _flatten(
      featuresMultipleLCC.map(({ properties, ...feature }) =>
        (_isArray(properties.land_cover)
          ? properties.land_cover
          : [properties.land_cover]
        ).map((landCover: string) => ({
          ...feature,
          properties: {
            ...properties,
            // eslint-disable-next-line camelcase
            land_cover: landCover
          }
        }))
      )
    )

    if (this.cache !== null) {
      await this.cacheFeatures(features)
    }

    return features
  }

  async getFeatureGeometry(sub: string, featureUUID: string): Promise<any> {
    if (this.cache !== null) {
      const isCached = await this.isFeatureGeometryCached(featureUUID)

      if (isCached) {
        return await this.cache.getFeatureGeometry(featureUUID)
      }
    }

    const startMTS = Date.now()
    const data = await this.queryAPI(
      sub,
      '/features',
      `feature_param=geometry&uuid=${featureUUID}`
    )

    l.success(
      `fetched geometry for feature ${featureUUID}(took ${Date.now() - startMTS
      }ms)`
    )

    if (this.cache !== null) {
      await this.cacheFeatureGeometry(featureUUID, data)
    }

    return data
  }

  async getFeatureEmissions(
    sub: string,
    featureUUID: string,
    featureLCC: string
  ): Promise<any> {
    if (this.cache !== null) {
      const isCached = await this.isFeatureEmissionsCached(
        featureUUID,
        featureLCC
      )

      if (isCached) {
        return await this.cache.getFeatureEmissions(featureUUID, featureLCC)
      }
    }

    const startMTS = Date.now()
    const data = await this.queryAPI(
      sub,
      '/features',
      `feature_param=co2&lcc=${featureLCC}&uuid=${featureUUID}`
    )

    l.success(
      `fetched CO2 data for feature ${featureUUID} (took ${Date.now() - startMTS}ms)`
    )

    if (this.cache !== null) {
      await this.cacheFeatureEmissions(featureUUID, featureLCC, data)
    }

    return data
  }

  async cacheTags(tags: DBTag[]): Promise<void> {
    if (this.cache !== null) {
      await this.cache.cacheTags(tags)
    }
  }

  async cacheFeatures(data: Array<Record<string, any>>): Promise<void> {
    if (this.cache !== null) {
      await this.cache.cacheFeatures(data)
    }
  }

  async cacheFeatureEmissions(
    featureUUID: string,
    featureLCC: string,
    data: any
  ): Promise<void> {
    if (this.cache !== null) {
      await this.cache.cacheFeatureEmissions(featureUUID, featureLCC, data)
    }
  }

  async cacheFeatureGeometry(featureUUID: string, data: any): Promise<void> {
    if (this.cache !== null) {
      await this.cache.cacheFeatureGeometry(featureUUID, data)
    }
  }

  async isTagsCached(): Promise<boolean> {
    if (this.cache === null) {
      return false
    }

    const tags = await this.cache.getTags()

    return !_isEmpty(tags)
  }

  async isFeaturesCached(): Promise<boolean> {
    if (this.cache === null) {
      return false
    }

    const features = await this.cache.getFeatures()

    return !_isEmpty(features)
  }

  async isFeatureEmissionsCached(
    featureUUID: string,
    featureLCC: string
  ): Promise<boolean> {
    if (this.cache === null) {
      return false
    }

    const res = await this.cache.getFeatureEmissions(featureUUID, featureLCC)

    return !_isUndefined(res)
  }

  async isFeatureGeometryCached(featureUUID: string): Promise<boolean> {
    if (this.cache === null) {
      return false
    }

    const res = await this.cache.getFeatureGeometry(featureUUID)

    return !_isUndefined(res)
  }
}

export default API
