import cn from 'clsx'
import * as d3 from 'd3'
import _isUndefined from 'lodash/isUndefined'
import React, { useRef, useEffect } from 'react'

import { CLASS_NAME } from './const'
import { type ChartAxisLeftProps } from './types'

import './style.scss'

const ChartAxisLeft: React.FC<ChartAxisLeftProps> = (
  props: ChartAxisLeftProps
) => {
  const { className, scale, label } = props
  const ref = useRef<any>(null)

  useEffect((): void => {
    const { current } = ref

    if (current === null) {
      return
    }

    d3.select(current).call(d3.axisLeft(scale))
  }, [ref.current, scale])

  return (
    <>
      {!_isUndefined(label) && label.length > 0 && (
        <text x="-48" y="-24" className={`${CLASS_NAME}-label`}>
          {label}
        </text>
      )}
      <g className={cn(CLASS_NAME, className)} ref={ref} />
    </>
  )
}

export default ChartAxisLeft
