import _sum from 'lodash/sum'
import _isFinite from 'lodash/isFinite'
import _isUndefined from 'lodash/isUndefined'

import { type EmissionsData } from 'types/data'

const getEmissionsDataTotal = (data: EmissionsData, year?: number): number =>
  _sum(
    Object.keys(data)
      .filter(
        (k) =>
          _isFinite(+k) &&
          (_isUndefined(year) || k.slice(0, 4) === year.toString())
      )
      .map((k) => data[k])
  )

export default getEmissionsDataTotal
