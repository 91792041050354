import { Auth } from 'aws-amplify'
import LoadingBar from 'react-top-loading-bar'
import { RouterProvider } from 'react-router-dom'
import React, { Suspense, useState, useMemo, useEffect } from 'react'

import * as U from 'utils'
import UIContext from 'ui_context'
import AuthContext from 'auth_context'
import PageLoader from 'components/PageLoader/PageLoader'
import useData from 'hooks/useData'
import { type Feature, type Emissions } from 'types/data'

import { type AppProps } from './types'

const App: React.FC<AppProps> = (props: AppProps) => {
  const { db, router, initialUser } = props
  const initialSub = (initialUser?.attributes.sub as string) ?? ''
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false)
  const [userSub, setUserSub] = useState<string>(initialSub)
  const [user, setUser] = useState<any>(initialUser)
  const authContextValue = useMemo(
    (): any => ({
      user,
      setUser
    }),
    [user, setUser]
  )

  useEffect((): void => {
    Auth.currentAuthenticatedUser()
      // eslint-disable-next-line promise/always-return,github/no-then
      .then((user: any): void => {
        setIsUserAdmin(U.isUserAdmin(user))
      })
      // eslint-disable-next-line github/no-then
      .catch((err: Error) => {
        console.error(err)
      })
  }, [])

  useEffect((): void => {
    if (user !== null) {
      const { attributes } = user
      const { sub } = attributes

      setSub(sub as string)
      setUserSub(sub as string)
      setIsUserAdmin(U.isUserAdmin(user))

      return
    }

    Auth.currentUserInfo()
      // eslint-disable-next-line github/no-then
      .then(async (currentUser: any) => {
        if (currentUser === null) {
          return
        }

        const { attributes } = currentUser
        const { sub } = attributes

        setUser(currentUser)
        setUserSub(sub as string)
        setSub(sub as string)

        return await Auth.currentAuthenticatedUser()
      })
      // eslint-disable-next-line promise/always-return,github/no-then
      .then((currentAuthenticatedUser: any) => {
        setIsUserAdmin(U.isUserAdmin(currentAuthenticatedUser))
      })
      // eslint-disable-next-line github/no-then
      .catch((err: Error) => {
        console.error(err)
      })
  }, [user])

  const [sub, setSub] = useState<string>(initialSub)
  const [currentSub, setCurrentSub] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [features, setFeatures] = useState<Feature[] | null>(null)
  const [emissions, setEmissions] = useState<Emissions[] | null>(null)
  const [loadingMessage, setLoadingMessage] = useState<string>('')
  const [loadingProgress, setLoadingProgress] = useState<number>(0)
  const uiContextValue = useMemo(
    (): any => ({
      db,
      sub,
      setSub,
      userSub,
      features,
      emissions,
      isLoading,
      isUserAdmin
    }),
    [db, sub, userSub, setSub, features, emissions, isLoading, isUserAdmin]
  )

  useData({
    db,
    sub,
    currentSub,
    setEmissions,
    setFeatures,
    setIsLoading,
    setCurrentSub,
    setLoadingMessage,
    setLoadingProgress
  })

  useEffect((): void => {
    if (user !== null) {
      return
    }

    setEmissions(null)
    setFeatures(null)
    setCurrentSub(null)
    setSub('')
  }, [user])

  if (isLoading) {
    return (
      <>
        <PageLoader description={loadingMessage} />
        <LoadingBar color="#040444" progress={loadingProgress} />
      </>
    )
  }

  return (
    <Suspense>
      <UIContext.Provider value={uiContextValue}>
        <AuthContext.Provider value={authContextValue}>
          <RouterProvider router={router} />
        </AuthContext.Provider>
      </UIContext.Provider>
    </Suspense>
  )
}

export default App
