import _isArray from 'lodash/isArray'

import convertDateFromApiToStandardDateObject from './convertDateFromApiToStandardDateObject'
import getEmissionsDataMerged from './get_emissions_data_merged'

import { type Emissions } from 'types/data'

const getEmissionsMinDate = (
  emissions: Emissions | Emissions[]
): Date | null => {
  const data = _isArray(emissions)
    ? getEmissionsDataMerged(emissions)
    : emissions.data

  const dates = Object.keys(data).map(convertDateFromApiToStandardDateObject)

  dates.sort((a, b) => Number(a) - Number(b))

  return dates[0] ?? null
}

export default getEmissionsMinDate
