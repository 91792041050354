import _sum from 'lodash/sum'
import _isUndefined from 'lodash/isUndefined'

import { type Emissions, type EmissionsGroup } from 'types/data'

const getSelectedEmissionGroups = (
  groups: EmissionsGroup[],
  selectedEmissions: Emissions[]
): EmissionsGroup[] =>
  groups.filter(
    ({ items }: { items: Emissions[] }) =>
      _sum(
        items.map((emissions: Emissions) =>
          _isUndefined(selectedEmissions.find(({ id }) => id === emissions.id))
            ? 0
            : 1
        )
      ) === items.length
  )

export default getSelectedEmissionGroups
