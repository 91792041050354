import PropTypes from 'prop-types'

export const propTypes = {
  className: PropTypes.string,
  groups: PropTypes.array.isRequired,
  selectedEmissions: PropTypes.array.isRequired,
  setSelectedEmissions: PropTypes.func.isRequired,
  itemWidthPX: PropTypes.number.isRequired,
  showSelectionIndex: PropTypes.bool,
  hideNavMapIcon: PropTypes.bool,
  subTitle: PropTypes.string,
  scrollWindowWidth: PropTypes.number,
  scrollWindowHeight: PropTypes.number
}

export const defaultProps = {}
