import React from 'react'
import _isUndefined from 'lodash/isUndefined'

import * as H from '../../hooks'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type IconNavMapProps } from './types'

import './style.scss'

const IconNavMap: React.FC<IconNavMapProps> = (props: IconNavMapProps) => {
  const { active, className, width } = props
  const finalClassName = H.useClassName(CLASS_NAME, className, 'cst-icon', {
    active: _isUndefined(active) ? false : active
  })

  return (
    <div className={finalClassName}>
      <svg
        width={`${width}`}
        height={`${width}`}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_721_127)">
          <path
            d="M2.10693 7.53052V25.5305L9.98193 21.0305L18.9819 25.5305L26.8569 21.0305V3.03052L18.9819 7.53052L9.98193 3.03052L2.10693 7.53052Z"
            stroke="#D4D4D4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.98193 3.03052V21.0305"
            stroke="#D4D4D4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.9819 7.53052V25.5305"
            stroke="#D4D4D4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_721_127">
            <rect
              width="27"
              height="27"
              fill="white"
              transform="translate(0.981934 0.780518)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

IconNavMap.propTypes = propTypes
IconNavMap.defaultProps = defaultProps

export default IconNavMap
export { CLASS_NAME }
export type { IconNavMapProps as PROPS }
