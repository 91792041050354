import React from 'react'

import * as H from '../../hooks'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type IconViewOnMapProps } from './types'

import './style.scss'

const IconViewOnMap: React.FC<IconViewOnMapProps> = (
  props: IconViewOnMapProps
) => {
  const { active, className } = props
  const finalClassName = H.useClassName(CLASS_NAME, className, 'cst-icon', {
    active: active === true
  })

  return (
    <div className={finalClassName}>
      <svg
        width="23"
        height="22"
        viewBox="0 0 23 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.137 13.7523L17.3128 10.1248C17.8975 9.16886 18.2057 8.08101 18.2057 6.97314C18.2057 5.86527 17.8975 4.77741 17.3128 3.82152C16.7345 2.86543 15.9041 2.07172 14.9046 1.51995C13.9051 0.968176 12.7717 0.677734 11.6181 0.677734C10.4645 0.677734 9.33113 0.968176 8.33165 1.51995C7.33217 2.07172 6.50168 2.86543 5.92343 3.82152C5.33871 4.77741 5.03049 5.86527 5.03049 6.97314C5.03049 8.08101 5.33871 9.16886 5.92343 10.1248L8.09923 13.7352C4.46395 14.164 0.882385 15.2703 0.882385 17.4142C0.882385 20.0642 6.45171 21.2734 11.6271 21.2734C16.8024 21.2734 22.3717 20.0642 22.3717 17.4142C22.3717 15.2703 18.7902 14.164 15.137 13.7523ZM7.48141 4.69625C7.7848 4.15732 8.19794 3.68214 8.69641 3.29879C9.19488 2.91545 9.76854 2.63173 10.3835 2.46443C10.9984 2.29712 11.6421 2.24962 12.2766 2.32473C12.911 2.39984 13.5233 2.59604 14.0772 2.90173C14.6311 3.20742 15.1153 3.61638 15.5013 4.10444C15.8874 4.59249 16.1673 5.14973 16.3245 5.7432C16.4817 6.33666 16.5131 6.9543 16.4168 7.55959C16.3205 8.16487 16.0984 8.74552 15.7638 9.26718L11.6271 16.1278L7.49036 9.26718C7.06837 8.57364 6.84529 7.78558 6.84372 6.98287C6.84215 6.18015 7.06214 5.3913 7.48141 4.69625ZM11.6271 19.5582C5.71749 19.5582 2.67317 18.0574 2.67317 17.4142C2.67317 16.9597 4.59825 15.7419 9.10207 15.3732L10.3108 17.3713C10.4447 17.5921 10.6367 17.7753 10.8677 17.9026C11.0987 18.0299 11.3605 18.097 11.6271 18.097C11.8936 18.097 12.1554 18.0299 12.3864 17.9026C12.6174 17.7753 12.8094 17.5921 12.9433 17.3713L14.1521 15.3732C18.6559 15.7419 20.581 16.9854 20.581 17.4142C20.581 18.0574 17.5366 19.5582 11.6271 19.5582Z"
          fill="#A0A0A0"
        />
        <path
          d="M11.6271 8.40958C12.6161 8.40958 13.4178 7.64168 13.4178 6.69442C13.4178 5.74716 12.6161 4.97925 11.6271 4.97925C10.638 4.97925 9.83627 5.74716 9.83627 6.69442C9.83627 7.64168 10.638 8.40958 11.6271 8.40958Z"
          fill="#A0A0A0"
        />
      </svg>
    </div>
  )
}

IconViewOnMap.propTypes = propTypes
IconViewOnMap.defaultProps = defaultProps

export default IconViewOnMap
export { CLASS_NAME }
export type { IconViewOnMapProps as PROPS }
