import { EmissionsUnit } from 'types'

const getEmissionsValueUnit = (
  value: number,
  isRelative?: boolean
): EmissionsUnit =>
  Math.abs(value) > 1000
    ? isRelative === true
      ? EmissionsUnit.KTonsPerHa
      : EmissionsUnit.KTons
    : isRelative === true
      ? EmissionsUnit.TonsPerHa
      : EmissionsUnit.Tons

export default getEmissionsValueUnit
