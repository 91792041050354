import PropTypes from 'prop-types'

export const propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

export const defaultProps = {}
