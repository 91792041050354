import PI from 'p-iteration'
import _isUndefined from 'lodash/isUndefined'
import { type ConsolaInstance } from 'consola'

import type DB from './db'
import { getLogger } from '../logger'
import { type CacheFlags, type DBTag } from '../types/db'

const DEFAULT_FLAGS = {
  hasReceivedFullGeoJSON: false
}

const CACHE_DB_FLAGS_ITEM_ID = 1
const CACHE_DB_TAGS_STORE_NAME = 'tags'
const CACHE_DB_FLAGS_STORE_NAME = 'flags'
const CACHE_DB_FEATURES_STORE_NAME = 'features'
const CACHE_DB_FEATURE_EMISSIONS_STORE_NAME = 'feature_emissions'
const CACHE_DB_FEATURE_GEOMETRIES_STORE_NAME = 'feature_geometries'
const CACHE_DB_FEATURES_WITH_TAG_STORE_NAME = 'features_with_tag'

export const CACHE_DB_VERSION = 1
export const CACHE_DB_CONFIG = [
  {
    name: CACHE_DB_FEATURES_STORE_NAME,
    keyPath: 'properties.feature_uuid'
  },
  {
    name: CACHE_DB_FEATURE_EMISSIONS_STORE_NAME,
    keyPath: 'key'
  },
  {
    name: CACHE_DB_FEATURE_GEOMETRIES_STORE_NAME,
    keyPath: 'feature_uuid'
  },
  {
    name: CACHE_DB_TAGS_STORE_NAME,
    autoIncrement: true,
    keyPath: 'id'
  },
  {
    name: CACHE_DB_FEATURES_WITH_TAG_STORE_NAME,
    keyPath: 'tag'
  },
  {
    name: CACHE_DB_FLAGS_STORE_NAME,
    autoIncrement: true,
    keyPath: 'id'
  }
]

class Cache {
  db: DB
  l: ConsolaInstance

  constructor(db: DB) {
    this.db = db
    this.l = getLogger(`cache:${db.name}`)
  }

  getFeatureEmissionsKey(featureUUID: string, featureLCC: string): string {
    return `${featureUUID}|co2|${featureLCC}`
  }

  async getTags(): Promise<DBTag[]> {
    return await this.db.getAllItems(CACHE_DB_TAGS_STORE_NAME)
  }

  async getFlags(): Promise<any> {
    return (
      (await this.db.getItem(CACHE_DB_FLAGS_ITEM_ID, CACHE_DB_FLAGS_STORE_NAME))
        .data ?? { ...DEFAULT_FLAGS }
    )
  }

  async getFeature(featureUUID: string): Promise<any> {
    const res = await this.db.getItem(featureUUID, CACHE_DB_FEATURES_STORE_NAME)

    return _isUndefined(res) ? res : res.data
  }

  async getFeatures(): Promise<any> {
    return await this.db.getAllItems(CACHE_DB_FEATURES_STORE_NAME)
  }

  async getFeaturesWithTags(tags: string[]): Promise<any> {
    return await PI.map(tags, async (tag: string): Promise<any> => {
      const res = await this.db.getItem(
        tag,
        CACHE_DB_FEATURES_WITH_TAG_STORE_NAME
      )

      return _isUndefined(res) ? res : res.data
    })
  }

  async getFeatureEmissions(
    featureUUID: string,
    featureLCC: string
  ): Promise<any> {
    const key = this.getFeatureEmissionsKey(featureUUID, featureLCC)

    return await this.db.getItem(key, CACHE_DB_FEATURE_EMISSIONS_STORE_NAME)
  }

  async getFeatureGeometry(featureUUID: string): Promise<any> {
    return await this.db.getItem(
      featureUUID,
      CACHE_DB_FEATURE_GEOMETRIES_STORE_NAME
    )
  }

  async cacheTags(tags: DBTag[]): Promise<void> {
    await PI.map(tags, async (tag: DBTag): Promise<void> => {
      const { tag: tagStr } = tag

      await this.db.saveItem(
        {
          tag: tagStr
        },
        CACHE_DB_TAGS_STORE_NAME
      )
    })

    this.l.info(`cached ${tags.length} tags`)
  }

  async cacheFeatures(data: Array<Record<string, any>>): Promise<void> {
    await PI.map(data, async (feature: Record<string, any>): Promise<void> => {
      await this.db.saveItem(feature, CACHE_DB_FEATURES_STORE_NAME)
    })

    this.l.info(`cached ${data.length} features`)
  }

  async cacheFeaturesWithTags(tag: string, features: any): Promise<void> {
    await this.db.saveItem(
      {
        tag,
        features
      },
      CACHE_DB_FEATURES_WITH_TAG_STORE_NAME
    )

    this.l.info(
      `cached ${features.length} features with tags ${tag} (${features.join(
        ', '
      )})`
    )
  }

  async cacheFeature(
    featureUUID: string,
    data: Record<string, any>
  ): Promise<void> {
    await this.db.saveItem(
      {
        // eslint-disable-next-line camelcase
        feature_uuid: featureUUID,
        ...data
      },
      CACHE_DB_FEATURES_STORE_NAME
    )

    this.l.info(`cached feature ${featureUUID}`)
  }

  async cacheFeatureEmissions(
    featureUUID: string,
    featureLCC: string,
    data: any
  ): Promise<void> {
    const key = this.getFeatureEmissionsKey(featureUUID, featureLCC)
    const dbFeatureEmissionsItem: Record<string, any> = {
      key,
      ...data
    }

    await this.db.saveItem(
      dbFeatureEmissionsItem,
      CACHE_DB_FEATURE_EMISSIONS_STORE_NAME
    )

    this.l.info(`cached feature ${featureUUID} emissions data`)
  }

  async cacheFeatureGeometry(featureUUID: string, data: any): Promise<void> {
    const dbFeatureGeometryItem: Record<string, any> = {
      // eslint-disable-next-line camelcase
      feature_uuid: featureUUID,
      ...data
    }

    await this.db.saveItem(
      dbFeatureGeometryItem,
      CACHE_DB_FEATURE_GEOMETRIES_STORE_NAME
    )

    this.l.info(`cached feature ${featureUUID} geometry`)
  }

  async setFlags(flags: CacheFlags): Promise<void> {
    const existingFlags = await this.getFlags()
    const dbFlagsItem: Record<string, any> = {
      id: CACHE_DB_FLAGS_ITEM_ID,
      ...existingFlags,
      ...flags
    }

    await this.db.saveItem(dbFlagsItem, CACHE_DB_FLAGS_STORE_NAME)

    this.l.info(
      `updated flags (${Object.keys(flags)
        .map((k) => `${k}=${(flags as any)[k]}`)
        .join(', ')})`
    )
  }

  async setHasReceivedFullGeoJSON(
    hasReceivedFullGeoJSON: boolean
  ): Promise<void> {
    await this.setFlags({ hasReceivedFullGeoJSON })
  }

  async getHasReceivedFullGeoJSON(): Promise<boolean> {
    const flags = await this.getFlags()
    const { hasReceivedFullGeoJSON } = flags

    return hasReceivedFullGeoJSON
  }
}

export default Cache
