import _noop from 'lodash/noop'
import React, { useCallback } from 'react'
import _isUndefined from 'lodash/isUndefined'

import * as H from '../../hooks'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type InputProps } from './types'

import './style.scss'

const DEFAULT_TYPE = 'text'

const Input: React.FC<InputProps> = (props: InputProps) => {
  const {
    type = DEFAULT_TYPE,
    placeholder,
    className,
    onChange,
    disabled,
    value,
    id
  } = props

  const finalClassName = H.useClassName(CLASS_NAME, className, {
    disabled: disabled === true
  })

  const onInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (disabled === true || _isUndefined(onChange)) {
        return
      }

      const { currentTarget } = e
      const { value } = currentTarget

      onChange(value.trim())
    },
    [onChange, disabled]
  )

  return (
    <input
      id={id}
      type={type}
      value={value}
      disabled={disabled}
      onChange={disabled === true ? _noop : onInputChange}
      placeholder={placeholder}
      className={finalClassName}
    />
  )
}

Input.propTypes = propTypes
Input.defaultProps = defaultProps

export default Input
export { CLASS_NAME }
export type { InputProps as PROPS }
