import getEmissionsTotal from './get_emissions_total'
import getEmissionsTotalArea from './get_emissions_total_area'

import { type EmissionsGroup, type Emissions } from 'types/data'

const genGroup = (emissions: Emissions[], key: string): EmissionsGroup => {
  const totalArea = getEmissionsTotalArea(emissions)
  const total = getEmissionsTotal(emissions)

  return {
    key,
    items: emissions,
    total,
    totalIntensity: totalArea === 0 ? 0 : total / totalArea,
    area: totalArea
  }
}

export default genGroup
