import _isUndefined from 'lodash/isUndefined'

import DB from 'data/db'
import type API from 'data/api'
import { DB_GEOMETRIES_STORE_NAME } from 'config'

const ensureGeometryIsCached = async (
  db: DB,
  api: API,
  sub: string,
  featureUUID: string,
  featureLCC: string
): Promise<void> => {
  const geometryKey = DB.getGeometryKey(sub, featureUUID, featureLCC)
  const existingGeometry = await db.getItem(
    geometryKey,
    DB_GEOMETRIES_STORE_NAME
  )

  if (
    !_isUndefined(existingGeometry) &&
    !DB.isDBItemExpired(existingGeometry)
  ) {
    return
  }

  const geometry = await api.getFeatureGeometry(sub, featureUUID)
  const dbGeometryItem: Record<string, any> = {
    key: geometryKey,
    expiry: +DB.getExpiryDate(),
    ...geometry
  }

  await db.saveItem(dbGeometryItem, DB_GEOMETRIES_STORE_NAME)
}

export default ensureGeometryIsCached
