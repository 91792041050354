import { useEffect } from 'react'
import { type Map } from 'mapbox-gl'
import _isUndefined from 'lodash/isUndefined'

import { type GeoJSON } from '../../../types/data'

import * as C from '../const'
import * as U from '../utils'

const useFeaturesLayer = (
  map: Map | undefined,
  geoJson: GeoJSON | null
): void => {
  useEffect((): void => {
    if (
      geoJson === null ||
      _isUndefined(map) ||
      _isUndefined((map as any).style)
    ) {
      return
    }

    if (_isUndefined(map.getLayer(C.POINTS_LAYER_ID))) {
      return
    }

    U.addMapSource(map, C.FEATURES_SOURCE_ID, geoJson)
    U.addMapLayer(map, C.ASSETS_LAYER_OPTIONS, C.POINTS_LAYER_ID)
    U.addMapLayer(map, C.ASSETS_BORDERS_LAYER_OPTIONS, C.POINTS_LAYER_ID)
  }, [map, (map as any)?.style, geoJson])
}

export default useFeaturesLayer
