import { EmissionsUnit } from 'types/common'

export enum DisplayMode {
  Overlay = 'overlay',
  Excess = 'excess'
}

export const CLASS_NAME = 'cst-baseline-chart'
export const UNIT_OPTIONS = [
  { label: 'NEE tCO2', value: EmissionsUnit.Tons },
  { label: 'NEE ktCO2', value: EmissionsUnit.KTons }
]
