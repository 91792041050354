import { type Emissions } from '../../types/data'

import getEmissionsFeatureProperty from './get_emissions_feature_property'

const getEmissionsFeatureUUID = (
  emissions: Emissions,
  defaultValue?: string
): string =>
  getEmissionsFeatureProperty(emissions, 'feature_uuid', defaultValue)

export default getEmissionsFeatureUUID
