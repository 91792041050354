import _max from 'lodash/max'
import _isArray from 'lodash/isArray'
import _flatten from 'lodash/flatten'

import { type ChartDataPoint } from 'types/ui'

// prettier-ignore
const getEmissionsDataMaxY = (data: ChartDataPoint[] | ChartDataPoint[][]): number | undefined => {
  return _isArray(data[0])
    ? _max(
      _flatten((data as ChartDataPoint[][]).map((d) => d.map(({ y }) => y)))
    )
    : _max((data as ChartDataPoint[]).map(({ y }) => y))
}

export default getEmissionsDataMaxY
