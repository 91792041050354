import cn from 'clsx'
import _noop from 'lodash/noop'
import _isNil from 'lodash/isNil'
import _isUndefined from 'lodash/isUndefined'
import { useTranslation } from 'react-i18next'
import { useClickAway } from '@uidotdev/usehooks'
import React, { useEffect, useState, useCallback, type ReactNode } from 'react'

import * as H from '../../hooks'
import IconChevronDown from '../IconChevronDown/IconChevronDown'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type DropdownOption, type DropdownProps } from './types'

import './style.scss'

const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
  const {
    label,
    placeholder,
    onChange,
    helpText,
    disabled,
    className,
    options,
    inline,
    value
  } = props

  const { t } = useTranslation()
  const finalClassName = H.useClassName(CLASS_NAME, className, {
    disabled: disabled === true
  })

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const ref = useClickAway((): void => {
    setIsOpen(false)
  })

  useEffect((): void => {
    setIsOpen(false)
  }, [value, options, placeholder, disabled])

  const onToggleIsOpen = useCallback((): void => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const onSelectItem = useCallback(
    (item: DropdownOption): void => {
      if (_isUndefined(onChange) || item.value === value?.value) {
        return
      }

      onChange(item)
    },
    [onChange, value]
  )

  const buttonLabel = _isNil(value)
    ? placeholder ?? t('dropdown_placeholder')
    : value.label

  return (
    <div
      ref={ref}
      className={cn(finalClassName, {
        inline,
        withLabel: !_isUndefined(label)
      })}
    >
      {!_isUndefined(label) && (
        <div className={`${CLASS_NAME}-label`}>
          <p>{label}</p>
        </div>
      )}

      <button
        className={cn({ open: isOpen })}
        onClick={disabled === true ? _noop : onToggleIsOpen}
      >
        <p>{buttonLabel}</p>

        <IconChevronDown />
      </button>

      {!_isUndefined(helpText) && (
        <div className={`${CLASS_NAME}-help`}>
          <p>{helpText}</p>
        </div>
      )}

      {isOpen && (
        <ul>
          {options.map(
            (option: DropdownOption): ReactNode => (
              <li
                key={option.value}
                onClick={onSelectItem.bind(null, option)}
                className={cn({
                  selected: value?.value === option.value
                })}
              >
                <p>{option.label}</p>
              </li>
            )
          )}
        </ul>
      )}
    </div>
  )
}

Dropdown.propTypes = propTypes
Dropdown.defaultProps = defaultProps

export default Dropdown
export { CLASS_NAME }
export type { DropdownProps as PROPS }
