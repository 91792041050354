import _isUndefined from 'lodash/isUndefined'
import { type GeoJSONSource, type Map } from 'mapbox-gl'

import { type GeoJSON } from '../../../types/data'
import { type CLUSTER_MAP_SOURCE_CONFIG } from '../const'

const addMapSource = (
  map: Map,
  id: string,
  data: GeoJSON,
  config?: typeof CLUSTER_MAP_SOURCE_CONFIG
): void => {
  const existingSource: GeoJSONSource = map.getSource(id) as GeoJSONSource

  if (!_isUndefined(existingSource)) {
    existingSource.setData(data)
  } else {
    map.addSource(id, {
      generateId: true,
      type: 'geojson',
      data,
      ...config
    })
  }
}

export default addMapSource
