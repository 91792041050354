import _isDate from 'lodash/isDate'
import _isEmpty from 'lodash/isEmpty'
import _isArray from 'lodash/isArray'

import isEmissionsSearchMatch from './is_emissions_search_match'
import getEmissionsWithFilteredData from './getEmissionsWithFilteredData'

import { type EmissionsFilters, type Emissions } from 'types/data'

const getFilteredEmissions = (
  emissions: Emissions[],
  filters: EmissionsFilters
): Emissions[] => {
  const { name, siteName, query, lcc, start, end } = filters

  let filteredEmissions = _isEmpty(query)
    ? emissions
    : emissions.filter(isEmissionsSearchMatch.bind(null, query))

  if (!_isEmpty(lcc)) {
    filteredEmissions = filteredEmissions.filter(({ feature }) =>
      _isArray(feature?.properties?.land_cover)
        ? feature?.properties?.land_cover.includes(lcc)
        : feature?.properties?.land_cover === lcc
    )
  }

  if (name !== null && !_isEmpty(name)) {
    filteredEmissions = filteredEmissions.filter(({ feature }) =>
      feature?.properties?.name?.includes(name)
    )
  }

  if (siteName !== null && !_isEmpty(siteName)) {
    filteredEmissions = filteredEmissions.filter(({ feature }) =>
      feature?.properties?.site_name?.includes(siteName)
    )
  }

  if (!_isDate(start) || !_isDate(end)) {
    return filteredEmissions
  }

  return getEmissionsWithFilteredData(filteredEmissions, start, end)
}

export default getFilteredEmissions
