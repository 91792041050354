import * as d3 from 'd3'

const MAX_TEXT_LENGTH_PX = 70

function wrapAxisTick(): void {
  // @ts-expect-error this is a <g> node
  const self = d3.select(this)

  let textLength = self.node().getComputedTextLength()
  let text = self.text()

  while (textLength > MAX_TEXT_LENGTH_PX && text.length > 0) {
    text = text.slice(0, -1)
    self.text(`${text}...`)
    textLength = self.node().getComputedTextLength()
  }
}

export default wrapAxisTick
