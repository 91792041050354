import { type SortMode, type GroupMode } from './ui'
import { type LandUse, type IGBPLandCoverClass } from './common'

export interface EmissionsFilters {
  query: string
  end: Date | null
  start: Date | null
  sortMode: SortMode
  name: string | null
  groupMode: GroupMode
  siteName: string | null
  lcc: IGBPLandCoverClass | null
}

export interface GeoJSON {
  type: 'FeatureCollection'
  features: Feature[]
  crs?: {
    type: 'name'
    properties: {
      name: string
      area?: number // km2
    }
  }
}

export interface FeatureProperties {
  id: number | string
  land_use: LandUse
  assetId: number
  fid: number
  description?: string
  country_code: string
  land_cover: IGBPLandCoverClass
  site_name: string
  feature_uuid: string
  name: string
  area: number
}

interface FeatureGeometry {
  type: 'MultiPolygon'
  coordinates: [[Array<[number, number]>]]
}

export interface Feature {
  type: 'Feature'
  id?: string | number
  geometry: FeatureGeometry
  properties: FeatureProperties
}

export type EmissionsData = Record<string, number>

export interface Emissions {
  id: string
  data: Record<string, number>
  feature: Feature
  featureWithGeometry: Feature
  area: number
  total: number
  totalIntensity: number
}

export interface EmissionsGroup {
  key: string
  items: Emissions[]
  total: number
  totalIntensity: number
  area: number
}

export enum DataType {
  NEE = 'NEE',
  Intensity = 'Intensity'
}
