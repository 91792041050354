import { SortMode, GroupMode } from 'types/ui'
import { type EmissionsFilters, type Emissions } from 'types/data'

const getNewEmissionsFilters = (
  emissions: Emissions[] | null,
  emissionsMinDate: Date | null,
  emissionsMaxDate: Date | null
): EmissionsFilters => {
  const start = emissions === null ? null : emissionsMinDate
  const end = emissions === null ? null : emissionsMaxDate ?? new Date()

  return {
    end,
    start,
    query: '',
    lcc: null,
    name: null,
    siteName: null,
    sortMode: SortMode.None,
    groupMode: GroupMode.None
  }
}

export default getNewEmissionsFilters
