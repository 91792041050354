import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const { NODE_ENV } = process.env

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: NODE_ENV === 'development',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  })
  // eslint-disable-next-line github/no-then
  .catch((err: Error) => {
    console.error(err)
  })

export default i18n
