import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import * as H from 'hooks'
import UIContext from 'ui_context'
import NavBar from 'components/NavBar/NavBar'
import NoDataSkeleton from 'components/NoDataSkeleton/NoDataSkeleton'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type NoDataSkeletonPageProps } from './types'

import './style.scss'

const NoDataSkeletonPage: React.FC<NoDataSkeletonPageProps> = (
  props: NoDataSkeletonPageProps
) => {
  const { label, className } = props

  const { t } = useTranslation()
  const finalClassName = H.useClassName(CLASS_NAME, className)
  const { isUserAdmin } = useContext(UIContext)

  return (
    <div className={finalClassName}>
      <NavBar />

      <div className={`${CLASS_NAME}-wrapper`}>
        <NoDataSkeleton
          label={
            label ??
            (isUserAdmin
              ? t('no_data_skeleton_page.default_label_admin')
              : t('no_data_skeleton_page.default_label'))
          }
        />
      </div>
    </div>
  )
}

NoDataSkeletonPage.propTypes = propTypes
NoDataSkeletonPage.defaultProps = defaultProps

export default NoDataSkeletonPage
export { CLASS_NAME }
export type { NoDataSkeletonPageProps as PROPS }
