import _isArray from 'lodash/isArray'
import { type TFunction } from 'i18next'
import _isUndefined from 'lodash/isUndefined'

import { type Emissions, type ChartDataPoint } from 'types'

interface GetCSVRowLabelArgs {
  t: TFunction
  rowIndex: number
  chartTitle?: string
  isGrouped: boolean
  datasets: ChartDataPoint[][]
  emissions?: Emissions[] | Emissions
}

const getCSVRowLabel = (args: GetCSVRowLabelArgs): string => {
  const { t, rowIndex, chartTitle, emissions, datasets, isGrouped } = args
  const emissionsForDataset =
    !_isUndefined(emissions) && _isArray(emissions)
      ? emissions?.[rowIndex]
      : emissions

  if (_isUndefined(emissionsForDataset)) {
    return datasets.length === 1
      ? chartTitle ?? t('export.dataset_label_default')
      : isGrouped
        ? `${t('export.dataset_label_group_prefix')} ${rowIndex + 1}`
        : `${t('export.dataset_label_item_prefix')} ${rowIndex + 1}`
  }

  const { feature } = emissionsForDataset
  const { properties } = feature
  const { feature_uuid: featureUUID = '', name = '' } = properties

  if (name.length > 0) {
    return name
  }

  return `${t('export.dataset_label_feature_uuid_prefix')} ${featureUUID}`
}

export default getCSVRowLabel
export { getCSVRowLabel }
