import PropTypes from 'prop-types'

export const propTypes = {
  className: PropTypes.string,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired
}

export const defaultProps = {}
