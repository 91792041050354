import _sum from 'lodash/sum'
import _isArray from 'lodash/isArray'

import { type Emissions } from 'types/data'

const getEmissionsTotalArea = (data: Emissions | Emissions[]): number => {
  if (_isArray(data)) {
    return _sum(data.map(getEmissionsTotalArea))
  }

  const { feature } = data
  const { properties } = feature ?? {}
  const { area } = properties ?? {}

  return area ?? 0
}

export default getEmissionsTotalArea
