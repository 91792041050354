import _isUndefined from 'lodash/isUndefined'

import DB from 'data/db'
import { type Feature } from 'types/data'
import { DB_FEATURES_STORE_NAME } from 'config'

const ensureFeatureIsCached = async (
  db: DB,
  feature: Feature,
  sub: string,
  featureUUID: string,
  featureLCC: string
): Promise<void> => {
  const featureKey = DB.getFeatureKey(sub, featureUUID, featureLCC)
  const existingFeature = await db.getItem(featureKey, DB_FEATURES_STORE_NAME)

  if (!_isUndefined(existingFeature) && !DB.isDBItemExpired(existingFeature)) {
    return
  }

  await db.saveItem(
    {
      key: featureKey,
      ...feature,
      expiry: +DB.getExpiryDate()
    },
    DB_FEATURES_STORE_NAME
  )
}

export default ensureFeatureIsCached
