import cn from 'clsx'
import _noop from 'lodash/noop'
import _isUndefined from 'lodash/isUndefined'
import React, { useMemo, useCallback } from 'react'
import { type ListChildComponentProps, FixedSizeList } from 'react-window'

import * as H from 'hooks'
import { type Emissions } from 'types/data'
import FeatureListItem from 'components/FeatureListItem/FeatureListItem'
import EmissionsListHeader from 'components/EmissionsListHeader/EmissionsFilterHeader'

import { CLASS_NAME } from './const'
import { type FeatureListProps } from './types'
import { propTypes, defaultProps } from './props'

import './style.scss'

const FeatureList: React.FC<FeatureListProps> = (props: FeatureListProps) => {
  const {
    title,
    subTitle,
    emissions,
    className,
    multiLine,
    renderIDs,
    hideNavMapIcon,
    disableSelection,
    onShowFeatureInfo,
    selectedEmissions,
    showSelectionIndex,
    setSelectedEmissions,
    scrollWindowWidth,
    scrollWindowHeight
  } = props

  const finalClassName = H.useClassName(CLASS_NAME, className)
  const emissionsSelectionStatuses = useMemo(
    (): boolean[] =>
      emissions.map(
        (e: Emissions): boolean =>
          !_isUndefined(selectedEmissions?.find(({ id }) => id === e.id))
      ),
    [emissions, selectedEmissions]
  )

  const {
    onSelectEmissions,
    onDeselectEmissions,
    onSelectAll,
    onDeselectAll,
    isSelectAllDisabled,
    isDeselectAllDisabled
  } = H.useEmissionsSelect(emissions, selectedEmissions, setSelectedEmissions)

  const onShowFeatureInfoClick = useCallback(
    (e: Emissions) => {
      if (!_isUndefined(onShowFeatureInfo)) {
        onShowFeatureInfo(e)
      }
    },
    [onShowFeatureInfo]
  )

  const FeatureListRow: React.FC<ListChildComponentProps> = (
    props: ListChildComponentProps
  ): JSX.Element => {
    const { index, style, data } = props
    const e: Emissions = data[index]
    const finalStyle = {
      ...style,
      top: +(style?.top ?? 0) + 16,
      left: +(style?.left ?? 0) + 32,
      width: Math.max(0, (scrollWindowWidth ?? 0) - 64)
    }

    return (
      <li
        style={finalStyle}
        onClick={
          disableSelection === true
            ? _noop
            : emissionsSelectionStatuses[index]
              ? onDeselectEmissions?.bind(null, e) ?? _noop
              : onSelectEmissions?.bind(null, e) ?? _noop
        }
      >
        <FeatureListItem
          emissions={e}
          renderID={renderIDs}
          multiLine={multiLine}
          hideNavMapIcon={hideNavMapIcon}
          disableSelection={disableSelection}
          showSelectionIndex={showSelectionIndex}
          selected={emissionsSelectionStatuses[index]}
          onShowInfo={onShowFeatureInfoClick?.bind(null, e)}
          selectionIndex={(selectedEmissions ?? []).findIndex(
            ({ id }) => id === e.id
          )}
        />
      </li>
    )
  }

  // prettier-ignore
  return (
    <div className={cn(finalClassName, { multiLine })}>
      {disableSelection !== true && (
        <EmissionsListHeader
          title={title}
          subTitle={subTitle}
          onSelectAll={onSelectAll}
          onDeselectAll={onDeselectAll}
          isSelectAllEnabled={!isSelectAllDisabled}
          isDeselectAllEnabled={!isDeselectAllDisabled}
        />
      )}

      <ul className={`${CLASS_NAME}-list`}>
        <FixedSizeList
          itemSize={80}
          itemData={emissions}
          itemCount={emissions.length}
          width={scrollWindowWidth ?? 0}
          height={Math.max(0, (scrollWindowHeight ?? 0) - 32)}
        >
          {FeatureListRow}
        </FixedSizeList>
      </ul>
    </div>
  )
}

FeatureList.propTypes = propTypes
FeatureList.defaultProps = defaultProps

export default FeatureList
export { CLASS_NAME }
export type { FeatureListProps as PROPS }
