import _isEmpty from 'lodash/isEmpty'
import { createConsola, type ConsolaInstance, type LogObject } from 'consola'

import logString from './logString'

const LOG_LEVEL_DEFAULT = 3
const LOG_LEVEL_SILENT = -999

const getLogger = (scope?: string): ConsolaInstance => {
  const urlParams = new URLSearchParams(window.location.search)
  const isDebug = urlParams.get('debug') === 'true'

  return createConsola({
    reporters: [
      {
        log: (logObj: LogObject): void => {
          const { args, type: handlerType } = logObj
          const str = args.join(' ')

          if (!_isEmpty(str)) {
            logString(handlerType, scope ?? '', str)
          }
        }
      }
    ],
    level: isDebug ? LOG_LEVEL_DEFAULT : LOG_LEVEL_SILENT
  })
}

export default getLogger
