import _noop from 'lodash/noop'
import { createContext } from 'react'

interface AuthContextValue {
  user: any
  setUser: (user: any) => void
}

const AuthContext = createContext<AuthContextValue>({
  user: null,
  setUser: _noop
})

AuthContext.displayName = 'AuthContext'

export default AuthContext
