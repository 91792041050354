import _keys from 'lodash/keys'
import _uniq from 'lodash/uniq'
import _flattenDeep from 'lodash/flattenDeep'

import { type Emissions } from 'types/data'

export const getEmissionsYears = (emissions: Emissions[]): number[] =>
  _uniq(
    _flattenDeep(
      emissions.map(({ data }) => _keys(data).map((k) => +k.substring(0, 4)))
    )
  )
