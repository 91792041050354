import PropTypes from 'prop-types'

export const propTypes = {
  title: PropTypes.string,
  centered: PropTypes.bool,
  className: PropTypes.string,
  widthOffset: PropTypes.number,
  onHeightChange: PropTypes.func,
  onWidthChange: PropTypes.func
}

export const defaultProps = {}
