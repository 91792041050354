import _isFinite from 'lodash/isFinite'
import _isUndefined from 'lodash/isUndefined'

import DB from '../../data/db'
import flattenFeaturesByLCC from './flatten_features_by_lcc'
import getEmissionsDataTotal from './get_emissions_data_total'
import { type EmissionsData, type Emissions, type Feature } from 'types'
import {
  DB_EMISSIONS_STORE_NAME,
  DB_FEATURES_STORE_NAME,
  DB_GEOMETRIES_STORE_NAME
} from '../../config'

interface EmissionsDataResult {
  emissions: Emissions[]
  features: Feature[]
}

/**
 * Retrieves all available emissions and feature data for the specified sub
 * from IndexedDB.
 */
const getEmissionsData = async (
  sub: string,
  db: DB
): Promise<EmissionsDataResult> => {
  const features: Feature[] = []
  const emissions: Emissions[] = []

  const allFeaturesFromDB: any = await db.getAllItems(DB_FEATURES_STORE_NAME)

  const allFeaturesForSub = allFeaturesFromDB.filter(
    ({ key }: { key: string }): boolean => key.includes(sub)
  )

  const allFeatures = allFeaturesForSub.map(
    ({ key, ...otherFeatureFields }: { key: string }) => ({
      ...otherFeatureFields
    })
  )

  const allFeaturesSingleLCC = flattenFeaturesByLCC(allFeatures as Feature[])

  for (const feature of allFeaturesSingleLCC) {
    features.push(feature)

    const { properties } = feature
    const {
      feature_uuid: featureUUID,
      land_cover: featureLCC,
      area
    } = properties

    const geometryKey = DB.getGeometryKey(sub, featureUUID, featureLCC)
    const emissionsKey = DB.getEmissionsKey(sub, featureUUID, featureLCC)
    const featureWithGeometryFromDB = await db.getItem(
      geometryKey,
      DB_GEOMETRIES_STORE_NAME
    )

    if (_isUndefined(featureWithGeometryFromDB)) {
      continue
    }

    const { key, ...featureWithGeometry } = featureWithGeometryFromDB

    featureWithGeometry.properties = feature.properties

    const featureEmissionsData = await db.getItem(
      emissionsKey,
      DB_EMISSIONS_STORE_NAME
    )

    if (_isUndefined(featureEmissionsData)) {
      continue
    }

    const { data } = featureEmissionsData
    const total = getEmissionsDataTotal(data as EmissionsData)
    const totalIntensity = _isFinite(area) && area > 0 ? total / area : 0

    emissions.push({
      data,
      area,
      feature,
      featureWithGeometry,
      total,
      totalIntensity,
      id: `${emissions.length}`
    })
  }

  return {
    emissions,
    features
  }
}

export default getEmissionsData
