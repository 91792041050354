import _isFinite from 'lodash/isFinite'
import _isUndefined from 'lodash/isUndefined'

// prettier-ignore
const getFiniteWithDefault = (
  value: number | undefined,
  defaultValue: number
): number =>
  _isUndefined(value)
    ? defaultValue
    : _isFinite(value)
      ? value
      : defaultValue

export default getFiniteWithDefault
