import _isEmpty from 'lodash/isEmpty'

import { type ChartDataPoint, PathDirection } from '../../../types/ui'

const genPaths = (
  data: ChartDataPoint[],
  direction: PathDirection
): ChartDataPoint[][] => {
  let paths: ChartDataPoint[][] = []
  let currentPath: ChartDataPoint[] = []

  for (let i = 0; i < data.length; i += 1) {
    if (
      (data[i].y < 0 && direction === PathDirection.Up) ||
      (data[i].y > 0 && direction === PathDirection.Down)
    ) {
      if (currentPath.length > 1) {
        const finalPath = [
          ...currentPath,
          {
            x: data[i].x,
            y: data[i].y
          }
        ]

        paths = [...paths, finalPath]
        currentPath = []
      }

      continue
    }

    if (
      _isEmpty(currentPath) ||
      (currentPath[0].y < 0 && data[i].y < 0) ||
      (currentPath[0].y > 0 && data[i].y > 0)
    ) {
      currentPath = [...currentPath, data[i]]
    } else if (!_isEmpty(currentPath)) {
      paths = [...paths, currentPath]
      currentPath = []
    }
  }

  const results = _isEmpty(currentPath) ? paths : [...paths, currentPath]

  return results.filter((path) => path.length > 1)
}

export default genPaths
