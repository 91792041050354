import PropTypes from 'prop-types'

export const propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  emissions: PropTypes.any.isRequired,
  selectionIndex: PropTypes.number.isRequired
}

export const defaultProps = {}
