import cn from 'clsx'
import _noop from 'lodash/noop'
import _isEmpty from 'lodash/isEmpty'
import { Trans } from 'react-i18next'
import _isFinite from 'lodash/isFinite'
import _isUndefined from 'lodash/isUndefined'
import { useNavigate } from 'react-router-dom'
import { useMeasure } from '@uidotdev/usehooks'
import React, { type SyntheticEvent, useCallback, useMemo } from 'react'

import * as H from '../../hooks'
import * as U from '../../utils'
import IconInfo from '../IconInfo/IconInfo'
import IconNavMap from '../IconNavMap/IconNavMap'
import { PATH as MAP_PAGE_PATH } from 'pages/map/const'

import { CLASS_NAME } from './const'
import { type FeatureListItemProps } from './types'

import './style.scss'

const DEFAULT_UI_ID_CONTAINER_WIDTH = 20
const UI_ID_CHARACTER_PIXEL_SCALE_CONST = 7

/**
 * Renders a single emissions items' internal values (emissions, id/name, etc).
 *
 * If `multiLine` is true, the list item is rendered with the feature ID/name
 * on an additional row. This is to provide extra screen space for the page
 * content.
 */
const FeatureListItem: React.FC<FeatureListItemProps> = (
  props: FeatureListItemProps
) => {
  const {
    showSelectionIndex,
    disableSelection,
    selectionIndex,
    hideNavMapIcon,
    onShowInfo,
    multiLine,
    emissions,
    className,
    selected,
    renderID,
    onClick,
    active,
    style
  } = props

  const navigate = useNavigate()
  const { id, feature, total, totalIntensity } = emissions
  const { properties } = feature
  const { feature_uuid: featureUUID, name, area } = properties
  const [uiIDContainerRef, { width: uiIDContainerWidth }] = useMeasure()
  const finalClassName = H.useClassName(CLASS_NAME, className, {
    active: active === true,
    selected: selected === true,
    multiLine: _isUndefined(multiLine) ? false : multiLine
  })

  const featureID = _isEmpty(name) ? featureUUID : name
  const featureNEE = U.getFormattedEmissionsValue(total, false)
  const featureIntensity = _isFinite(totalIntensity)
    ? U.getFormattedEmissionsValue(totalIntensity, true)
    : 0

  const finalUIIDContainerWidth =
    uiIDContainerWidth === 0
      ? DEFAULT_UI_ID_CONTAINER_WIDTH * UI_ID_CHARACTER_PIXEL_SCALE_CONST
      : uiIDContainerWidth

  const onIconInfoClick = useCallback(
    (e: SyntheticEvent) => {
      if (!_isUndefined(onShowInfo)) {
        onShowInfo()
      }

      e.stopPropagation()
      e.preventDefault()

      return false
    },
    [onShowInfo]
  )

  // prettier-ignore
  const onIconNavMapClick = useCallback((e: MouseEvent) => {
    navigate(`${MAP_PAGE_PATH}?eid=${id}`)

    e.preventDefault()
    e.stopPropagation()
    return false
  }, [id])

  // NOTE: This is a hack as text-overflow: ellipsis doesn't work within a
  // flexbox container.
  const uiID = useMemo((): string => {
    if (multiLine === true) {
      return featureID
    }

    const featureIDLength = Math.ceil(
      finalUIIDContainerWidth / UI_ID_CHARACTER_PIXEL_SCALE_CONST - 5
    )

    return featureIDLength >= featureID.length
      ? featureID
      : `${featureID.substring(0, featureIDLength)}...`
  }, [multiLine, finalUIIDContainerWidth, featureID])

  const uiIDComponent = <p className={`${CLASS_NAME}-id`}>{uiID}</p>

  // prettier-ignore
  return (
    <div
      style={style}
      onClick={disableSelection === true ? _noop : onClick}
      className={cn(finalClassName, {
        clickDisabled: disableSelection
      })}
    >
      <div className={`${CLASS_NAME}-wrapper`}>
        {(
          (renderID !== false) &&
          (multiLine === false || _isUndefined(multiLine))
        ) && (
          <div
            ref={uiIDContainerRef}
            className={`${CLASS_NAME}-column ${CLASS_NAME}-column-id`}
          >
            <p>
              <Trans i18nKey="feature_list_item.id_column_label">ID</Trans>
            </p>
            <p>{id}</p>
          </div>
        )}

        {(multiLine === false || _isUndefined(multiLine)) && (
          <div ref={uiIDContainerRef} className={`${CLASS_NAME}-column name`}>
            <p>
              <Trans i18nKey="feature_list_item.name_column_label">Name</Trans>
            </p>
            {uiIDComponent}
          </div>
        )}

        <div className={`${CLASS_NAME}-column ${CLASS_NAME}-column-area`}>
          <p>
            <Trans i18nKey="feature_list_item.area_column_label">Area</Trans>
          </p>
          <p>{U.formatUINumber(area)} Ha</p>
        </div>

        <div
          className={cn(`${CLASS_NAME}-column ${CLASS_NAME}-column-intensity`, {
            positive: totalIntensity !== null && totalIntensity > 0,
            negative: totalIntensity !== null && totalIntensity < 0
          })}
        >
          <p>
            <Trans i18nKey="feature_list_item.intensity_column_label">
              Intensity
            </Trans>
          </p>
          <p>{featureIntensity}</p>
        </div>

        <div
          className={cn(`${CLASS_NAME}-column ${CLASS_NAME}-column-nee`, {
            positive: total > 0,
            negative: total < 0
          })}
        >
          <p>
            <Trans i18nKey="feature_list_item.nee_column_label">NEE</Trans>
          </p>
          <p>{featureNEE}</p>
        </div>

        {!_isUndefined(onShowInfo) && (
          <div
            onClick={onIconInfoClick}
            className={cn(
              `${CLASS_NAME}-column`,
              `${CLASS_NAME}-column-info`,
              'icon'
            )}
          >
            <IconInfo />
          </div>
        )}

        {hideNavMapIcon !== true && (
          <div
            onClick={onIconNavMapClick as any}
            className={cn(
              `${CLASS_NAME}-column`, `${CLASS_NAME}-column-nav-map`, 'icon'
            )}
          >
            <IconNavMap />
          </div>
        )}

        {(
          (showSelectionIndex === true) &&
          !_isUndefined(selectionIndex) &&
          (selectionIndex >= 0)
        ) && (
            <div className={`${CLASS_NAME}-selection-index`}>
              <p>{selectionIndex + 1}</p>
            </div>
        )}
      </div>

      {multiLine === true && (
        <div ref={uiIDContainerRef} className={`${CLASS_NAME}-name-row`}>
          <p>
            <Trans i18nKey="feature_list_item.id_column_label">ID</Trans>
          </p>
          {uiIDComponent}
        </div>
      )}
    </div>
  )
}

export default FeatureListItem
export { CLASS_NAME }
export type { FeatureListItemProps as PROPS }
