import React from 'react'

import * as H from '../../hooks'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type ContentWrapperProps } from './types'

import './style.scss'

const ContentWrapper: React.FC<ContentWrapperProps> = (
  props: ContentWrapperProps
) => {
  const { className, children, ...otherProps } = props
  const finalClassName = H.useClassName(CLASS_NAME, className)

  return (
    <div className={finalClassName} {...otherProps}>
      {children}
    </div>
  )
}

ContentWrapper.propTypes = propTypes
ContentWrapper.defaultProps = defaultProps

export default ContentWrapper
export { CLASS_NAME }
export type { ContentWrapperProps as PROPS }
