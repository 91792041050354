import cn from 'clsx'
import _isNil from 'lodash/isNil'
import _isUndefined from 'lodash/isUndefined'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { type LngLatBoundsLike, type Map as MapBox } from 'mapbox-gl'
import React, {
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useState,
  useContext
} from 'react'

import UIContext from 'ui_context'
import AuthContext from 'auth_context'
import {
  type GeoJSON,
  type Emissions,
  type EmissionsGroup,
  type EmissionsFilters
} from 'types/data'

import * as U from 'utils'
import * as H from 'hooks'
import * as OU from './utils'
import * as OH from './hooks'
import * as C from 'components'
import * as OC from './components'
import { type MapPageProps } from './types'
import { type IGBPLandCoverClass } from 'types'
import { DOCUMENT_TITLE, CLASS_NAME, PATH } from './const'

import './style.scss'

const MapPage: React.FC<MapPageProps> = (props: MapPageProps) => {
  const { className } = props
  const location = useLocation()
  const { search } = location
  const urlEID = search.split('=')[1]
  const { user } = useContext(AuthContext)

  H.useEnforceAuthByNavigate(user)
  H.useDocumentTitle(DOCUMENT_TITLE)

  const markerRefs = useRef([])
  const { t } = useTranslation()
  const mapRef = useRef<MapBox>()
  const { userSub, sub, emissions } = useContext(UIContext)
  const urlEmissions = emissions?.find(({ id }) => id === urlEID)

  const finalClassName = H.useClassName(CLASS_NAME, className)
  const [areMarkersVisible, setAreMarkersVisible] = useState<boolean>(false)
  const [selectedEmissions, setSelectedEmissions] = useState<Emissions[]>([])

  const emissionsMinDate = H.useFunctionMemo<Date>(
    U.getEmissionsMinDate,
    emissions ?? []
  )

  const emissionsMaxDate = H.useFunctionMemo<Date | null>(
    U.getEmissionsMaxDate,
    emissions ?? []
  )

  const [filters, setFilters] = useState<EmissionsFilters>(
    U.getNewEmissionsFilters(emissions, emissionsMinDate, emissionsMaxDate)
  )

  const { groupMode, sortMode } = filters
  const setFilterValue = useCallback(
    (key: string, value: any) => {
      setFilters((prevFilters: EmissionsFilters) => ({
        ...prevFilters,
        [key]: value
      }))
    },
    [setFilters]
  )

  const filteredEmissions = H.useFunctionMemo<Emissions[]>(
    U.getFilteredEmissions,
    emissions ?? [],
    filters
  )

  const sortedEmissions = H.useFunctionMemo<Emissions[]>(
    U.getSortedEmissions,
    filteredEmissions,
    sortMode
  )

  const filteredSelectedEmissions = H.useFunctionMemo<Emissions[]>(
    U.getFilteredEmissions,
    selectedEmissions,
    filters
  )

  const groupedEmissions = H.useFunctionMemo<EmissionsGroup[]>(
    U.getGroupedEmissions,
    sortedEmissions,
    groupMode,
    sortMode
  )

  const selectedGroups: EmissionsGroup[] = H.useFunctionMemo(
    U.getSelectedEmissionGroups,
    groupedEmissions,
    filteredSelectedEmissions
  )

  const { infoModalEmissions, onInfoModalClose, onInfoModalOpen } =
    H.useInfoModal()

  const mapContainerRef = useRef<HTMLDivElement>(null)
  const [stateMapRef, setStateMapRef] =
    useState<React.MutableRefObject<MapBox | undefined>>(mapRef)

  useEffect((): void => {
    markerRefs.current = markerRefs.current.slice(0, selectedEmissions.length)
  }, [selectedEmissions])

  const geoJsonWithPolygonGeometry = H.useFunctionMemo<GeoJSON>(
    U.genGeoJSON,
    filteredEmissions,
    true
  )

  const geoJsonWithPointGeometry = H.useFunctionMemo<GeoJSON>(
    U.genGeoJSON,
    filteredEmissions
  )

  const onFeatureClick = useCallback(
    (featureUUID: string, featureLCC: IGBPLandCoverClass) => {
      OU.onFeatureClick(
        filteredEmissions,
        selectedEmissions,
        setSelectedEmissions,
        featureUUID,
        featureLCC
      )
    },
    [filteredEmissions, selectedEmissions, setSelectedEmissions]
  )

  const selectedFeatureUUIDs = useMemo(
    (): string[] =>
      selectedEmissions.map(({ feature }) => feature.properties.feature_uuid),
    [selectedEmissions]
  )

  OH.useClustersLayer(
    stateMapRef.current,
    geoJsonWithPointGeometry,
    selectedEmissions
  )

  OH.useOnClusterClick(stateMapRef.current)
  OH.useFeaturesLayer(stateMapRef.current, geoJsonWithPolygonGeometry)
  OH.useOnFeatureClick(
    onFeatureClick,
    stateMapRef.current,
    selectedFeatureUUIDs
  )

  OH.useFeaturesCenter(
    filteredEmissions,
    selectedEmissions,
    stateMapRef.current
  )

  OH.useSetMarkersVisibleOnMapZoom(stateMapRef.current, setAreMarkersVisible)

  const onMarkerClick = useCallback(
    (e: Emissions) => {
      if (
        _isNil(stateMapRef.current) ||
        _isUndefined(stateMapRef.current) ||
        _isUndefined((stateMapRef.current as any).style)
      ) {
        return
      }

      const { feature } = e
      const { geometry } = feature
      const { coordinates } = geometry

      const bounds = [coordinates, coordinates] as unknown as LngLatBoundsLike

      if (stateMapRef.current !== null) {
        stateMapRef.current.fitBounds(bounds, {
          padding: 100,
          maxZoom: 13,
          duration: 2000
        })
      }
    },
    [stateMapRef.current]
  )

  OH.useSelectedEmissionsMapMarkers(
    stateMapRef.current,
    selectedEmissions,
    onMarkerClick
  )

  useEffect((): void => {
    if (urlEmissions !== undefined) {
      setTimeout((): void => {
        setSelectedEmissions([urlEmissions])
      }, 500)
    }
  }, [urlEmissions])

  if (emissions === null) {
    return <C.NoDataSkeletonPage className={finalClassName} />
  }

  return (
    <div
      className={cn(finalClassName, {
        hideMarkers: !areMarkersVisible,
        withSubWarning: userSub !== sub
      })}
    >
      {userSub !== sub && <C.SubWarningBar />}

      <C.NavBar />
      <OC.MapSidebar
        setSelectedEmissions={setSelectedEmissions}
        selectedEmissions={selectedEmissions}
        onShowFeatureInfo={onInfoModalOpen}
        setFilterValue={setFilterValue}
        selectedGroups={selectedGroups}
        emissions={sortedEmissions}
        filters={filters}
      />

      <div className={`${CLASS_NAME}-content-wrapper-outer`}>
        <C.EmissionsFilterHeader
          emissionsMinDate={emissionsMinDate}
          emissionsMaxDate={emissionsMaxDate}
          setFilterValue={setFilterValue}
          emissions={emissions}
          filters={filters}
          title={t('map.title')}
        />

        <C.Map
          stateMapRef={stateMapRef}
          setStateMapRef={setStateMapRef}
          mapContainerRef={mapContainerRef}
        />
      </div>

      <C.EmissionsDetailsModal
        emissions={infoModalEmissions}
        onClose={onInfoModalClose}
      />
    </div>
  )
}

export default MapPage
export { PATH, CLASS_NAME }
export type { MapPageProps as PROPS }
