import { Trans } from 'react-i18next'
import React, { useCallback, useContext } from 'react'

import * as H from 'hooks'
import UIContext from 'ui_context'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type SubWarningBarProps } from './types'

import './style.scss'

const SubWarningBar: React.FC<SubWarningBarProps> = (
  props: SubWarningBarProps
) => {
  const { className } = props
  const { sub, userSub, setSub } = useContext(UIContext)
  const finalClassName = H.useClassName(CLASS_NAME, className)

  const onResetClick = useCallback((): void => {
    setSub(userSub)
  }, [userSub, setSub])

  return (
    <div className={finalClassName}>
      <p>
        <Trans i18nKey="sub_warning_bar.label_prefix">
          Viewing data for sub
        </Trans>
      </p>
      <p>{sub}</p>
      <button onClick={onResetClick}>
        <Trans i18nKey="sub_warning_bar.reset">Reset</Trans>
      </button>
    </div>
  )
}

SubWarningBar.propTypes = propTypes
SubWarningBar.defaultProps = defaultProps

export default SubWarningBar
export { CLASS_NAME }
export type { SubWarningBarProps as PROPS }
