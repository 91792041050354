const getDateForYear = (year: number, month?: number): Date => {
  const date = new Date()

  date.setUTCFullYear(year)
  date.setUTCDate(1)
  date.setUTCMonth(month ?? 0)
  date.setUTCHours(0, 0, 0, 0)

  return date
}

export default getDateForYear
