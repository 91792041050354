import { useEffect } from 'react'
import _isUndefined from 'lodash/isUndefined'
import { type AnyLayer, type Map } from 'mapbox-gl'

import { type Emissions, type GeoJSON } from '../../../types/data'

import * as U from '../utils'
import * as C from '../const'

/**
 * Adds cluster & points layers to the map.
 *
 * @param map - mapbox map instance
 * @param geoJson - data to be displayed on the map
 */
const useClusterLayer = (
  map?: Map,
  geoJson?: GeoJSON,
  selectedEmissions?: Emissions[]
): void => {
  useEffect((): void => {
    if (
      _isUndefined(map) ||
      _isUndefined(geoJson) ||
      _isUndefined((map as any).style) ||
      _isUndefined(selectedEmissions)
    ) {
      return
    }

    try {
      U.addMapSource(
        map,
        C.FEATURE_POINTS_SOURCE,
        geoJson,
        C.CLUSTER_MAP_SOURCE_CONFIG
      )

      if (selectedEmissions.length === 0) {
        U.addMapLayer(map, C.CLUSTERS_LAYER_CONFIG)
        U.addMapLayer(map, C.CLUSTER_COUNT_LAYER_CONFIG)
        U.addMapLayer(
          map,
          C.POINTS_LAYER_CONFIG as AnyLayer,
          C.CLUSTERS_LAYER_ID
        )
        U.addMapLayer(map, C.POINT_COUNT_LAYER_CONFIG, C.CLUSTERS_LAYER_ID)
      } else {
        U.removeMapLayer(map, C.CLUSTERS_LAYER_CONFIG)
        U.removeMapLayer(map, C.CLUSTER_COUNT_LAYER_CONFIG)
        U.removeMapLayer(map, C.POINTS_LAYER_CONFIG as AnyLayer)
        U.removeMapLayer(map, C.POINT_COUNT_LAYER_CONFIG)
      }
    } catch (e) {
      console.error(e)
    }
  }, [selectedEmissions, map, geoJson])
}

export default useClusterLayer
