import PropTypes from 'prop-types'

export const propTypes = {
  className: PropTypes.string,
  vertical: PropTypes.bool.isRequired
}

export const defaultProps = {
  vertical: false
}
