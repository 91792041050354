import React from 'react'

import * as H from '../../hooks'

import { propTypes, defaultProps } from './props'
import { INNER_CLASS_NAME, CLASS_NAME } from './const'
import { type CenteredContentWrapperProps } from './types'

import './style.scss'

const CenteredContentWrapper: React.FC<CenteredContentWrapperProps> = (
  props: CenteredContentWrapperProps
) => {
  const { className, innerClassName, children, ...otherProps } = props

  const finalClassName = H.useClassName(CLASS_NAME, className)
  const finalInnerClassName = H.useClassName(INNER_CLASS_NAME, innerClassName)

  return (
    <div className={finalClassName} {...otherProps}>
      <div className={finalInnerClassName}>{children}</div>
    </div>
  )
}

CenteredContentWrapper.propTypes = propTypes
CenteredContentWrapper.defaultProps = defaultProps

export default CenteredContentWrapper
export { INNER_CLASS_NAME, CLASS_NAME }
export type { CenteredContentWrapperProps as PROPS }
