import { useEffect } from 'react'
import center from '@turf/center'
import { type Map } from 'mapbox-gl'
import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'

import { type Emissions } from '../../../types/data'

import * as U from 'utils'
import * as C from '../const'

/**
 * Centers the map on the provided feature dataset
 *
 * @param map - mapbox map instance
 * @param geoJson - data currently displayed on the map
 */
const useFeaturesCenter = (
  emissions: Emissions[] | null,
  selectedEmissions: Emissions[],
  map?: Map
): void => {
  useEffect((): void => {
    if (
      _isUndefined(map) ||
      _isUndefined((map as any).style) ||
      emissions === null ||
      _isEmpty(emissions) ||
      selectedEmissions.length > 0
    ) {
      return
    }

    const geoJSON = U.genGeoJSON(emissions.map(({ feature }) => feature))
    const centerGeoJSON = center(geoJSON)
    const { geometry } = centerGeoJSON
    const { coordinates } = geometry

    // Wrapped in a timeout since otherwise the map unmount hook breaks...
    setTimeout((): void => {
      map.jumpTo({
        center: coordinates,
        padding: C.PADDING,
        zoom: 2
      })
    }, 0)
  }, [map, (map as any)?.style, emissions, selectedEmissions])
}

export default useFeaturesCenter
