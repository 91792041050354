import _isUndefined from 'lodash/isUndefined'
import { useTranslation, Trans } from 'react-i18next'
import { useMemo, useEffect, useCallback } from 'react'

import * as H from 'hooks'
import * as C from 'components'

import { PolygonsPerformanceExtremes } from './types'
import { type Emissions } from '../../types/data'
import { SortDirection } from '../../types/common'
import getEmissionsForSelection from './getEmissionsForSelection'
import getEmissionsWithFilteredData from '../../utils/data/getEmissionsWithFilteredData'

import { type DropdownOption } from 'components/Dropdown/types'

const CLASS_NAME = 'cst-dashboard-side-bar'
const NUMBER_OF_POLYGONS = 10

type Props = {
  className?: string
  selection: PolygonsPerformanceExtremes
  emissions: Emissions[]
  selectedEmissions: Emissions[]
  multiLine?: boolean
  setSelection: (selection: PolygonsPerformanceExtremes) => void
  setSelectedEmissions?: (emissions: Emissions[]) => void
  year: number | null
  setYear: (year: number) => void
}

export default function NEEPerformanceExtremesDropdown({
  year,
  selection,
  setSelection,
  emissions,
  multiLine,
  className,
  selectedEmissions,
  setSelectedEmissions
}: Props) {
  const { t } = useTranslation()
  const finalClassName = H.useClassName(CLASS_NAME, className, {
    multiLine: multiLine === true
  })

  const selectionOptions = useMemo(
    () => [
      {
        label: t('dashboard_sidebar.intensity_ten_best_performing_label'),
        value: PolygonsPerformanceExtremes.IntensityTenBestPerforming
      },
      {
        label: t('dashboard_sidebar.intensity_ten_worst_performing_label'),
        value: PolygonsPerformanceExtremes.IntensityTenWorstPerforming
      },
      {
        label: t('dashboard_sidebar.nee_ten_best_performing_label'),
        value: PolygonsPerformanceExtremes.NEETenBestPerforming
      },
      {
        label: t('dashboard_sidebar.nee_ten_worst_performing_label'),
        value: PolygonsPerformanceExtremes.NEETenWorstPerforming
      }
    ],
    [t]
  )

  const selectionOption = useMemo(
    (): DropdownOption | null =>
      selectionOptions.find(({ value }) => value === selection) ?? null,
    [selection, selectionOptions]
  )

  const onselectionChange = useCallback(
    (option: DropdownOption): void => {
      const { value } = option

      setSelection(value as PolygonsPerformanceExtremes)
    },
    [setSelection]
  )

  const emissionsForSelection: Emissions[] = H.useFunctionMemo(
    getEmissionsForSelection,
    selection,
    emissions
  )

  const filteredEmissionsForSelection = useMemo((): Emissions[] => {
    if (year === null) {
      return emissionsForSelection
    }

    const start = new Date(`${year}-01-01T00:00:00.000Z`)
    const end = new Date(`${year}-12-31T23:59:59.999Z`)
    const results = getEmissionsWithFilteredData(
      emissionsForSelection,
      start,
      end
    )
    const useIntensity =
      selection === PolygonsPerformanceExtremes.IntensityTenBestPerforming ||
      selection === PolygonsPerformanceExtremes.IntensityTenWorstPerforming

    // prettier-ignore
    const sortDirection =
      selection === PolygonsPerformanceExtremes.IntensityTenBestPerforming ||
        selection === PolygonsPerformanceExtremes.NEETenBestPerforming
        ? SortDirection.Ascending
        : SortDirection.Descending

    results.sort((a, b) => {
      const aValue = useIntensity ? a.totalIntensity : a.total
      const bValue = useIntensity ? b.totalIntensity : b.total

      return sortDirection === SortDirection.Ascending
        ? (aValue ?? 0) - (bValue ?? 0)
        : (bValue ?? 0) - (aValue ?? 0)
    })

    return results.slice(0, NUMBER_OF_POLYGONS)
  }, [year, selection, emissionsForSelection])

  useEffect((): void => {
    if (_isUndefined(setSelectedEmissions)) {
      return
    }

    if (
      (selectedEmissions.length > 0 &&
        filteredEmissionsForSelection.length === 0) ||
      filteredEmissionsForSelection.length > 0
    ) {
      setSelectedEmissions(filteredEmissionsForSelection)
    }
  }, [setSelectedEmissions, filteredEmissionsForSelection])

  return (
    <div className={finalClassName}>
      <C.Dropdown
        disabled={false}
        value={selectionOption}
        options={selectionOptions}
        onChange={onselectionChange}
        placeholder={t('dashboard_sidebar.selection_dropdown_placeholder')}
      />

      {filteredEmissionsForSelection.length === 0 && (
        <div className={`${CLASS_NAME}-no-data`}>
          <p>
            <Trans i18nKey="dashboard_sidebar.no_assets_message">
              No assets with data for the specified year
            </Trans>
          </p>
        </div>
      )}
    </div>
  )
}
