import getEmissionsTotal from './get_emissions_total'
import getEmissionsTotalArea from './get_emissions_total_area'

import { type Emissions, type EmissionsGroup } from 'types/data'

const addEmissionsToGroup = (
  emissions: Emissions,
  group: EmissionsGroup
): EmissionsGroup => {
  const total = getEmissionsTotal(emissions)
  const totalArea = getEmissionsTotalArea(emissions)

  group.items.push(emissions)
  group.total += total
  group.area += totalArea
  group.totalIntensity = group.area === 0 ? 0 : group.total / group.area

  return group
}

export default addEmissionsToGroup
