import _sum from 'lodash/sum'
import _isEmpty from 'lodash/isEmpty'

import { type Feature } from '../../../types/data'
import { type Dimensions } from '../../../types/ui'

import { CHAR_WIDTH_PX, LINE_HEIGHT_PX } from '../const'

const getLegendDimensions = (
  chartWidth: number,
  features: Feature[]
): Dimensions => {
  const singleRowWidth = _sum(
    features.map((feature: Feature): number => {
      const { properties } = feature
      const { feature_uuid: featureUUID, name } = properties
      const label = _isEmpty(name) ? featureUUID : name

      return CHAR_WIDTH_PX * label.length + 72
    })
  )

  const width = chartWidth === 0 ? 0 : Math.min(chartWidth, singleRowWidth)

  const height =
    chartWidth === 0
      ? 0
      : Math.ceil(singleRowWidth / chartWidth) * LINE_HEIGHT_PX

  return { width, height }
}

export default getLegendDimensions
