import React from 'react'
import { useTranslation } from 'react-i18next'
import { SwishSpinner } from 'react-spinners-kit'

import * as H from '../../hooks'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type ComponentLoaderProps } from './types'

import './style.scss'

const ComponentLoader: React.FC<ComponentLoaderProps> = (
  props: ComponentLoaderProps
) => {
  const { label, className } = props

  const { t } = useTranslation()
  const finalClassName = H.useClassName(CLASS_NAME, className)

  return (
    <div className={finalClassName}>
      <div className={`${CLASS_NAME}-inner`}>
        <SwishSpinner
          size={64}
          color="#2828a7"
          loading={true}
          aria-label={label ?? t('loading_message')}
        />
      </div>
    </div>
  )
}

ComponentLoader.propTypes = propTypes
ComponentLoader.defaultProps = defaultProps

export default ComponentLoader
export { CLASS_NAME }
export type { ComponentLoaderProps as PROPS }
