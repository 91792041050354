import * as H from 'hooks'
import LandCover from './LandCover/LandCover'
import PolygonCount from './PolygonCount/PolygonCount'
import MetricCardAnnualFlux from './AnnualFlux/AnnualFlux'
import MetricCardRelativeFlux from './RelativeFlux/RelativeFlux'

import { CLASS_NAME } from './const'
import { type Emissions } from 'types/data'

import './style.scss'

export type Props = {
  emissions: Emissions[]
  year: number
  className?: string
}

export default function DashboardCards({
  year,
  emissions,
  className
}: Props): JSX.Element {
  const finalClassName = H.useClassName(CLASS_NAME, className)

  return (
    <div className={finalClassName}>
      <PolygonCount year={year} emissions={emissions} />
      <LandCover emissions={emissions} />
      <MetricCardAnnualFlux year={year} emissions={emissions} />
      <MetricCardRelativeFlux year={year} emissions={emissions} />
    </div>
  )
}

export { CLASS_NAME }
