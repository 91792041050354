import React from 'react'

import * as H from '../../hooks'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type IconArrowDownRightProps } from './types'

import './style.scss'

const IconArrowDownRight: React.FC<IconArrowDownRightProps> = (
  props: IconArrowDownRightProps
) => {
  const { active, className } = props
  const finalClassName = H.useClassName(CLASS_NAME, className, 'cst-icon', {
    active: active === true
  })

  return (
    <div className={finalClassName}>
      <svg
        width="9"
        height="9"
        viewBox="0 0 9 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.527466 1.2644H7.66071M7.66071 1.2644V7.38457M7.66071 1.2644L0.588949 7.6628"
          stroke="#FF0000"
        />
      </svg>
    </div>
  )
}

IconArrowDownRight.propTypes = propTypes
IconArrowDownRight.defaultProps = defaultProps

export default IconArrowDownRight
export { CLASS_NAME }
export type { IconArrowDownRightProps as PROPS }
