import cn from 'clsx'
import _noop from 'lodash/noop'
import _isUndefined from 'lodash/isUndefined'
import { isValidUUIDV4 } from 'is-valid-uuid-v4'
import { useLocalStorage } from '@uidotdev/usehooks'
import { useTranslation, Trans } from 'react-i18next'
import React, { useState, useEffect, useContext, useCallback } from 'react'

import * as H from 'hooks'
import * as OU from './utils'
import * as C from 'components'
import UIContext from 'ui_context'
import AuthContext from 'auth_context'
import { type AdminProps } from './types'
import { PATH, CLASS_NAME } from './const'
import { RECENT_SUBS_STORAGE_KEY } from 'config'

import './style.scss'

interface RecentSub {
  sub: string
  label: string
}

const Admin: React.FC<AdminProps> = (props: AdminProps) => {
  const { className } = props

  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const { db, sub, userSub, setSub, isUserAdmin } = useContext(UIContext)

  const finalClassName = H.useClassName(CLASS_NAME, className)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [inputSub, setInputSub] = useState<string>(sub)
  const [recentSubs, setRecentSubs] = useLocalStorage(
    RECENT_SUBS_STORAGE_KEY,
    []
  )

  H.useEnforceAuthByNavigate(user, isUserAdmin)

  const onSubChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { currentTarget } = e
      const { value } = currentTarget

      setInputSub(value)
    },
    [sub, setSub, db]
  )

  const onSetSub = useCallback((): void => {
    if (!(isValidUUIDV4(inputSub) as boolean)) {
      setErrorMessage(t('admin.error_message_invalid_sub'))
    } else {
      const recentSub = {
        sub: inputSub,
        label: ''
      }

      setRecentSubs([recentSub, ...recentSubs])
      setErrorMessage('')

      OU.onSubChange(db, inputSub, sub, setSub)
    }
  }, [db, inputSub, sub, setSub, recentSubs, setRecentSubs])

  useEffect((): void => {
    setErrorMessage('')
  }, [inputSub])

  const onResetSub = useCallback((): void => {
    setSub(userSub)
  }, [setSub, userSub])

  const onRecentSubLabelChange = useCallback(
    (recentSubIndex: number, e: React.ChangeEvent<HTMLInputElement>) => {
      const { currentTarget } = e
      const { value } = currentTarget

      const recentSub = recentSubs[recentSubIndex]

      if (_isUndefined(recentSub)) {
        console.error(
          `Tried to set label for recent sub that does not exist (${recentSubIndex})`
        )
        return
      }

      recentSub.label = value

      setRecentSubs(recentSubs)
    },
    [recentSubs, setRecentSubs]
  )

  const onDeleteRecentSub = useCallback(
    (recentSubIndex: number) => {
      const newRecentSubs = recentSubs.filter(
        (_: RecentSub, i: number) => i !== recentSubIndex
      )

      setRecentSubs(newRecentSubs)
    },
    [recentSubs, setRecentSubs]
  )

  const onSetRecentSub = useCallback(
    (recentSubIndex: number) => {
      const recentSub: RecentSub = recentSubs[recentSubIndex]

      if (_isUndefined(recentSub)) {
        console.error(
          `Tried to set recent sub that does not exist (${recentSubIndex})`
        )
        return
      }

      const { sub: inputSub } = recentSub

      OU.onSubChange(db, inputSub, sub, setSub)
    },
    [recentSubs]
  )

  const isSetSubButtonDisabled = inputSub.length === 0 || inputSub === sub
  const isResetSubButtonDisabled = sub === userSub

  return (
    <div className={finalClassName}>
      <C.NavBar />

      <C.FluidContentWrapper title="Admin">
        <div className={`${CLASS_NAME}-wrapper`}>
          <div className={`${CLASS_NAME}-sub-controls`}>
            <label htmlFor="cst-page-admin-sub-input">
              <Trans i18nKey="admin.sub_input_label">Modify active sub</Trans>
            </label>

            <div className={`${CLASS_NAME}-input-wrapper`}>
              <input
                id="cst-page-admin-sub-input"
                placeholder="Sub"
                value={inputSub}
                onChange={onSubChange}
              />

              <button
                onClick={isSetSubButtonDisabled ? _noop : onSetSub}
                disabled={isSetSubButtonDisabled}
              >
                <Trans i18nKey="admin.set_sub">Set Sub</Trans>
              </button>

              <button
                onClick={isResetSubButtonDisabled ? _noop : onResetSub}
                disabled={isResetSubButtonDisabled}
              >
                <Trans i18nKey="admin.reset_sub">Reset Sub</Trans>
              </button>
            </div>

            {errorMessage.length > 0 && (
              <p className={`${CLASS_NAME}-error`}>{errorMessage}</p>
            )}
          </div>

          {recentSubs.length > 0 && (
            <div className={`${CLASS_NAME}-recent-subs`}>
              <h4>
                <Trans i18nKey="admin.recent_subs_title">Recent Subs</Trans>
              </h4>

              <ul>
                {(recentSubs as RecentSub[]).map(
                  ({ sub: recentSub, label }, i: number) => (
                    <li
                      key={recentSub}
                      className={cn({
                        active: recentSub === sub
                      })}
                    >
                      <button
                        disabled={recentSub === sub}
                        onClick={
                          recentSub === sub
                            ? _noop
                            : onSetRecentSub.bind(null, i)
                        }
                      >
                        <Trans i18nKey="admin.apply">Apply</Trans>
                      </button>

                      <p
                        onClick={
                          recentSub === sub
                            ? _noop
                            : onSetRecentSub.bind(null, i)
                        }
                      >
                        {recentSub}
                      </p>

                      <input
                        type="text"
                        placeholder="Label"
                        value={label}
                        onChange={onRecentSubLabelChange.bind(null, i)}
                      />

                      <button onClick={onDeleteRecentSub.bind(null, i)}>
                        <Trans i18nKey="admin.delete">Delete</Trans>
                      </button>

                      {recentSub === sub && (
                        <p>
                          <Trans i18nKey="admin.active">Active</Trans>
                        </p>
                      )}
                    </li>
                  )
                )}
              </ul>
            </div>
          )}
        </div>
      </C.FluidContentWrapper>
    </div>
  )
}

export default Admin
export { PATH, CLASS_NAME }
export type { AdminProps as PROPS }
