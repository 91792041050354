import { type Emissions } from '../../types/data'

import getEmissionsFeatureProperty from './get_emissions_feature_property'

const getEmissionsFeatureName = (
  emissions: Emissions,
  defaultValue?: string
): string => getEmissionsFeatureProperty(emissions, 'name', defaultValue)

export default getEmissionsFeatureName
