import cn from 'clsx'

import * as H from '../../../hooks'
import { CLASS_NAME } from './const'

import './style.scss'

export enum TextColor {
  Green = 'green',
  Red = 'red'
}

type Props = {
  title: string
  value: string
  className?: string
  textColor?: TextColor
}

export default function DashboardCard({
  title,
  value,
  className,
  textColor
}: Props): JSX.Element {
  const finalClassName = H.useClassName(CLASS_NAME, className)

  return (
    <div className={finalClassName}>
      <div className={`${CLASS_NAME}-title`}>{title}</div>
      <div className={cn(`${CLASS_NAME}-value`, textColor)}>{value}</div>
    </div>
  )
}

export { CLASS_NAME }
