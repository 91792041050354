import { useEffect } from 'react'
import _isEmpty from 'lodash/isEmpty'
import _isFinite from 'lodash/isFinite'
import _isUndefined from 'lodash/isUndefined'
import { type Map, type MapMouseEvent } from 'mapbox-gl'

import { type IGBPLandCoverClass } from 'types'

import * as C from '../const'

/**
 * Sets the clicked feature as active in mapbox so it renders differently. On
 * map move/zoom, it searches for the selected feature by UUID to update
 * active state, since the rendered feature dataset changes on move/zoom.
 */
const useOnFeatureClick = (
  callback: (featureUUID: string, featureLCC: IGBPLandCoverClass) => void,
  map: Map | undefined,
  selectedFeatureUUIDs: string[]
): void => {
  useEffect((): (() => void) => {
    /**
     * Searches for the clicked feature by location and updates its active
     * status. Also updates refs for updatedSelectedFeatureActiveStatus.
     */
    const onMapClick = (event: MapMouseEvent): void => {
      if (
        _isUndefined(map) ||
        _isUndefined(event?.point) ||
        _isUndefined((map as any).style) ||
        _isUndefined(map.getLayer(C.FEATURES_LAYER_ID))
      ) {
        return
      }

      const features = map.queryRenderedFeatures(event.point, {
        layers: [C.FEATURES_LAYER_ID]
      })

      if (_isEmpty(features)) {
        return
      }

      const featureUUID = features?.[0]?.properties?.feature_uuid as string
      const featureLCC = features?.[0]?.properties
        ?.land_cover as IGBPLandCoverClass
      const featureID = features?.[0]?.id as number

      if (_isFinite(featureID)) {
        const isActive = selectedFeatureUUIDs.includes(
          features?.[0]?.properties?.feature_uuid as string
        )

        map.setFeatureState(
          {
            id: featureID,
            source: C.FEATURES_SOURCE_ID
          },
          { isActive: !isActive }
        )

        callback(featureUUID, featureLCC)
      }
    }

    /**
     * Queries all map features and searches for the selected feature by UUID
     * to resolve the current feature ID (changes on dataset change). Updates
     * active status accordingly.
     *
     * TODO: Extract
     */
    const updatedSelectedFeatureActiveStatus = (): void => {
      if (
        _isUndefined(map) ||
        _isUndefined((map as any).style) ||
        _isUndefined(map.getLayer(C.FEATURES_LAYER_ID))
      ) {
        return
      }

      const features = map.queryRenderedFeatures(undefined, {
        layers: [C.FEATURES_LAYER_ID]
      })

      features.forEach((feature) => {
        const isActive = selectedFeatureUUIDs.includes(
          feature.properties?.feature_uuid as string
        )

        map.setFeatureState(
          {
            id: feature.id as number,
            source: C.FEATURES_SOURCE_ID
          },
          { isActive }
        )
      })
    }

    map?.on('click', C.FEATURES_LAYER_ID, onMapClick)
    map?.on('data', updatedSelectedFeatureActiveStatus)

    return (): void => {
      map?.off('click', C.FEATURES_LAYER_ID, onMapClick)
      map?.off('data', updatedSelectedFeatureActiveStatus)
    }
  }, [map, (map as any)?.style, callback, selectedFeatureUUIDs])
}

export default useOnFeatureClick
