import { useEffect } from 'react'
import _isBoolean from 'lodash/isBoolean'
import { useNavigate } from 'react-router-dom'

import { PATH as LOGIN_PAGE_PATH } from 'pages/login/const'

const useEnforceAuthByNavigate = (user: any, isUserAdmin?: boolean): void => {
  const navigate = useNavigate()

  useEffect((): void => {
    if (user === null || (_isBoolean(isUserAdmin) && !isUserAdmin)) {
      navigate(LOGIN_PAGE_PATH)
    }
  }, [user])
}

export default useEnforceAuthByNavigate
