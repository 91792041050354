import domToImage from 'dom-to-image-more'

import downloadImage from './download_image'

const exportElementToJPG = async (
  element: HTMLElement,
  height: number,
  fileName: string
): Promise<void> => {
  const dataURL = (await domToImage.toJpeg(element, {
    height,
    bgColor: '#fff',
    quality: 1,
    filter: (node: HTMLElement): boolean =>
      !node?.classList?.contains('cst-chart-header')
  })) as string

  downloadImage(dataURL, fileName)
}

export default exportElementToJPG
