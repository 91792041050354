import { SortMode } from 'types/ui'
import { type Emissions } from 'types/data'

const getSortedEmissions = (
  emissions: Emissions[],
  sortMode: SortMode
): Emissions[] => {
  const results = [...emissions]

  if (sortMode === SortMode.None) {
    return results
  }

  results.sort((a, b) => {
    switch (sortMode) {
      case SortMode.Area: {
        const { feature: aFeature } = a
        const { properties: aProperties } = aFeature
        const { area: aArea } = aProperties

        const { feature: bFeature } = b
        const { properties: bProperties } = bFeature
        const { area: bArea } = bProperties

        return aArea - bArea
      }

      case SortMode.TotalNEE: {
        const { total: aTotal } = a
        const { total: bTotal } = b

        return aTotal - bTotal
      }

      case SortMode.Intensity: {
        const { totalIntensity: atotalIntensity } = a
        const { totalIntensity: btotalIntensity } = b

        return atotalIntensity - btotalIntensity
      }

      default: {
        throw new Error(`Invalid sort mode: ${sortMode}`)
      }
    }
  })

  return results
}

export default getSortedEmissions
