import cn from 'clsx'
import _isUndefined from 'lodash/isUndefined'
import React, { useRef, useCallback } from 'react'

import * as H from '../../hooks'

import { CLASS_NAME } from './const'
import { type ChartBarProps } from './types'
import ChartTooltip from '../ChartTooltip/ChartTooltip'

import './style.scss'

const MIN_BAR_WIDTH_PX = 1

const ChartBar: React.FC<ChartBarProps> = (props: ChartBarProps) => {
  const {
    className: aClassName,
    dataIsIntensity,
    width: aWidth,
    padding = 0,
    chartWidth,
    feature,
    onClick,
    formatX,
    formatY,
    center,
    labelX,
    labelY,
    scaleX,
    scaleY,
    data
  } = props

  const { x: dx, y: dy } = data
  const ref = useRef<SVGRectElement>(null)
  const className = H.useClassName(CLASS_NAME, aClassName)
  const hPadding =
    padding === 0 ? 0 : padding < 1 ? (padding * aWidth) / 2 : padding / 2

  const height = Math.abs(scaleY(0) - scaleY(dy))
  const x = +scaleX(dx) + hPadding
  const y = dy > 0 ? scaleY(0) - height : scaleY(0)
  const width = Math.max(MIN_BAR_WIDTH_PX, aWidth - hPadding * 2)
  const fill = dy < 0 ? 'green' : 'red'

  const onClickWithData = useCallback((): void => {
    if (!_isUndefined(onClick)) {
      onClick(data)
    }
  }, [onClick, data])

  return (
    <>
      <rect
        ref={ref}
        className={cn(className, { [fill]: true })}
        onClick={onClickWithData}
        x={center === true ? x + width / 2 : x}
        y={y}
        width={width}
        height={height}
      />

      <ChartTooltip
        x={0}
        y={0}
        dx={dx}
        dy={dy}
        width={chartWidth}
        triggerRef={ref}
        feature={feature}
        formatX={formatX}
        formatY={formatY}
        labelX={labelX}
        labelY={labelY}
        dataIsIntensity={dataIsIntensity}
      />
    </>
  )
}

export default ChartBar
