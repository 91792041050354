import _sum from 'lodash/sum'
import { useMemo } from 'react'

import { type Emissions } from 'types/data'

const useTotalArea = (emissions: Emissions[]): number =>
  useMemo(
    (): number => _sum(emissions.map((e: Emissions) => e.area)),
    [emissions]
  )

export default useTotalArea
