import _isUndefined from 'lodash/isUndefined'
import { type Map, type AnyLayer } from 'mapbox-gl'

const addMapLayer = (map: Map, data: AnyLayer, before?: string): void => {
  const existingLayer = map?.getLayer(data.id)

  if (_isUndefined(existingLayer)) {
    map.addLayer(data, before)
  }
}

export default addMapLayer
