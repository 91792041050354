import ReactGA from 'react-ga4'
import _isUndefined from 'lodash/isUndefined'

const { REACT_APP_GA_TRACKING_ID } = process.env

/**
 * Initializes google analytics if the tracking ID is configured.
 */
const initializeGA = (): void => {
  if (!_isUndefined(REACT_APP_GA_TRACKING_ID)) {
    ReactGA.initialize(REACT_APP_GA_TRACKING_ID)
  }
}

const trackUserEvent = (sub: string): void => {
  if (!_isUndefined(REACT_APP_GA_TRACKING_ID)) {
    ReactGA.event({
      category: 'User',
      action: 'Loaded User',
      label: sub
    })
  }
}

export { initializeGA, trackUserEvent }
