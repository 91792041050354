import cn from 'clsx'
import _noop from 'lodash/noop'
import _chunk from 'lodash/chunk'
import _isNil from 'lodash/isNil'
import _isUndefined from 'lodash/isUndefined'
import { useTranslation } from 'react-i18next'
import { useClickAway } from '@uidotdev/usehooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import * as H from 'hooks'
import IconCalendar from '../IconCalendar/IconCalendar'

import { CLASS_NAME } from './const'
import { type YearPickerProps } from './types'
import { propTypes, defaultProps } from './props'

import './style.scss'

const N_YEARS_PER_ROW = 4
const N_YEARS_PER_PAGE = N_YEARS_PER_ROW * 4

const YearPicker: React.FC<YearPickerProps> = (props: YearPickerProps) => {
  const { label, minDate, maxDate, value, onChange, className } = props

  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const finalClassName = H.useClassName(CLASS_NAME, className, { open: isOpen })
  const ref = useClickAway((): void => {
    setIsOpen(false)
  })

  const years = useMemo((): number[] => {
    if (
      _isNil(maxDate) ||
      _isNil(minDate) ||
      _isUndefined(minDate) ||
      _isUndefined(maxDate)
    ) {
      return []
    }

    const minYear = minDate.getUTCFullYear()
    const maxYear = maxDate.getUTCFullYear()
    const years = []

    for (let i = minYear; i <= maxYear; i += 1) {
      years.push(i)
    }

    return years
  }, [minDate, maxDate])

  const yearPages = useMemo(
    (): number[][] => _chunk(years, N_YEARS_PER_PAGE),
    [years]
  )
  const yearPage = useMemo((): number[] => yearPages[page], [yearPages, page])
  const yearPageChunks = useMemo(
    (): number[][] => _chunk(yearPage, N_YEARS_PER_ROW),
    [yearPage]
  )

  useEffect((): void => {
    const initialPageIndex = yearPages.findIndex((yearPage) =>
      yearPage.includes(value)
    )

    const initialPage = initialPageIndex === -1 ? 0 : initialPageIndex

    setPage(initialPage)
  }, [value])

  const isPageLast = page === yearPages.length - 1
  const isPageFirst = page === 0

  const onNextPage = useCallback((): void => {
    if (!isPageLast) {
      setPage(page + 1)
    }
  }, [setPage, page, isPageLast])

  const onPrevPage = useCallback((): void => {
    if (!isPageFirst) {
      setPage(page - 1)
    }
  }, [setPage, page, isPageFirst])

  useEffect((): void => {
    setIsOpen(false)
  }, [value])

  const onToggleIsOpen = useCallback((): void => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const onSelectYear = useCallback(
    (year: number) => {
      onChange(year)
      setIsOpen(false)
    },
    [onChange, setIsOpen]
  )

  return (
    <div ref={ref} className={finalClassName}>
      <div className={`${CLASS_NAME}-header`}>
        <p>{label ?? t('year_picker.label')}</p>
      </div>

      <button onClick={onToggleIsOpen}>
        <IconCalendar width={17} />
        <p>{value}</p>
      </button>

      {isOpen && (
        <div className={`${CLASS_NAME}-content-wrapper`}>
          <div
            onClick={isPageFirst || yearPages.length === 1 ? _noop : onPrevPage}
            className={cn(`${CLASS_NAME}-nav`, {
              disabled: isPageFirst || yearPages.length === 1
            })}
          >
            <p>&lt;</p>
          </div>

          <div className={`${CLASS_NAME}-list`}>
            {yearPageChunks.map((yearPageChunk: number[], i: number) => (
              <ul key={i}>
                {yearPageChunk.map((year: number) => (
                  <li
                    key={year}
                    onClick={onSelectYear.bind(null, year)}
                    className={cn({ selected: value === year })}
                  >
                    <p>{year}</p>
                  </li>
                ))}
              </ul>
            ))}
          </div>

          <div
            onClick={isPageLast || yearPages.length === 1 ? _noop : onNextPage}
            className={cn(`${CLASS_NAME}-nav`, {
              disabled: isPageLast || yearPages.length === 1
            })}
          >
            <p>&gt;</p>
          </div>
        </div>
      )}
    </div>
  )
}

YearPicker.propTypes = propTypes
YearPicker.defaultProps = defaultProps

export default YearPicker
export { CLASS_NAME }
export type { YearPickerProps as PROPS }
