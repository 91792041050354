import { useCallback, useMemo } from 'react'
import _isUndefined from 'lodash/isUndefined'

import { type Emissions } from '../types/data'

interface EmissionsMassSelectRes {
  onSelectAll: () => void
  onDeselectAll: () => void
  onSelectEmissions: (emissions: Emissions) => void
  onDeselectEmissions: (emissions: Emissions) => void
  isSelectAllDisabled: boolean
  isDeselectAllDisabled: boolean
}

/**
 * Creates callbacks for selecting/deselecting emissions individually or
 * en-masse.
 */
const useEmissionsSelect = (
  emissions: Emissions[],
  selectedEmissions?: Emissions[],
  setSelectedEmissions?: (emissions: Emissions[]) => void
): EmissionsMassSelectRes => {
  const onSelectEmissions = useCallback(
    (emissions: Emissions): void => {
      if (
        !_isUndefined(selectedEmissions) &&
        !_isUndefined(setSelectedEmissions) &&
        _isUndefined(selectedEmissions.find(({ id }) => id === emissions.id))
      ) {
        setSelectedEmissions([...selectedEmissions, emissions])
      }
    },
    [selectedEmissions, setSelectedEmissions]
  )

  const onDeselectEmissions = useCallback(
    (emissions: Emissions): void => {
      if (
        typeof selectedEmissions !== 'undefined' &&
        typeof setSelectedEmissions !== 'undefined'
      ) {
        setSelectedEmissions(
          selectedEmissions.filter((e) => e.id !== emissions.id)
        )
      }
    },
    [selectedEmissions, selectedEmissions]
  )

  const onSelectAll = useCallback((): void => {
    if (!_isUndefined(setSelectedEmissions)) {
      setSelectedEmissions(emissions)
    }
  }, [emissions, setSelectedEmissions])

  const onDeselectAll = useCallback((): void => {
    if (!_isUndefined(setSelectedEmissions)) {
      setSelectedEmissions([])
    }
  }, [setSelectedEmissions])

  const isSelectAllDisabled = useMemo(
    (): boolean => selectedEmissions?.length === emissions.length,
    [selectedEmissions, emissions]
  )

  const isDeselectAllDisabled = useMemo(
    (): boolean => selectedEmissions?.length === 0,
    [selectedEmissions]
  )

  return {
    onSelectAll,
    onDeselectAll,
    onSelectEmissions,
    onDeselectEmissions,
    isSelectAllDisabled,
    isDeselectAllDisabled
  }
}

export default useEmissionsSelect
