import { useMemo, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import _keys from 'lodash/keys'
import _uniq from 'lodash/uniq'
import _flattenDeep from 'lodash/flattenDeep'

import Dropdown from '../../../../components/Dropdown/Dropdown'
import { CLASS_NAME } from '../../const'
import DownloadButton from '../../../../components/DownloadButton/DownloadButton'
import convertDateFromApiToStandardDateObject from '../../../../utils/data/convertDateFromApiToStandardDateObject'

import { type Emissions } from '../../../../types/data'
import { type DropdownOption } from 'components/Dropdown/types'

import './style.scss'

type Props = {
  year: number | null
  emissions: Emissions[]
  setYear: (year: number) => void
  onDownloadAllClick: () => void
}

export default function Topbar({
  year,
  emissions,
  setYear,
  onDownloadAllClick
}: Props) {
  const { t } = useTranslation()
  const yearOptions = useMemo((): DropdownOption[] => {
    const options = _uniq(
      _flattenDeep(
        (emissions ?? []).map(({ data }) =>
          _keys(data).map((key: string): number =>
            convertDateFromApiToStandardDateObject(key).getUTCFullYear()
          )
        )
      )
    ).map((year: number) => ({
      value: year,
      label: `${year}`
    }))

    options.sort(({ value: a }, { value: b }) => b - a)

    return options
  }, [emissions])

  const onChangeYear = useCallback(
    (yearOption: DropdownOption) => {
      setYear(yearOption.value as number)
    },
    [setYear]
  )

  const yearOption = useMemo(
    (): DropdownOption => ({
      label: `${year}`,
      value: year
    }),
    [year]
  )

  return (
    <div className={`${CLASS_NAME}-top-bar`}>
      <h1>
        <Trans i18nKey="dashboard.title" />
      </h1>
      <div className={`${CLASS_NAME}-active-year-bar__year-wrapper`}>
        <p>
          <Trans i18nKey="dashboard.year_dropdown_label" />
        </p>
        <Dropdown
          className={`${CLASS_NAME}-active-year-bar__year`}
          value={yearOption}
          options={yearOptions}
          onChange={onChangeYear}
        />
      </div>
      <DownloadButton
        buttonText={t('download_button.text_all_data')}
        onClick={onDownloadAllClick}
      />
    </div>
  )
}
