import _isNil from 'lodash/isNil'
import ReactDOM from 'react-dom/client'
import React, { useEffect } from 'react'
import _isUndefined from 'lodash/isUndefined'
import {
  Marker,
  type Map,
  type LngLatLike,
  type LngLatBoundsLike
} from 'mapbox-gl'

import { getLogger } from 'logger'
import { type Emissions } from 'types/data'
import MapEmissionsMarker from 'components/MapEmissionsMarker/MapEmissionsMarker'

const { NODE_ENV } = process.env
const l = getLogger('pages:map:hooks:use-selected-emissions-map-markers')

const useSelectedEmissionsMapMarkers = (
  map: Map | undefined,
  selectedEmissions: Emissions[],
  onMarkerClick: (emissions: Emissions) => void
): void => {
  useEffect((): (() => void) | undefined => {
    if (
      _isNil(map) ||
      _isUndefined(map) ||
      _isUndefined((map as any).style) ||
      selectedEmissions.length === 0
    ) {
      return
    }

    const emissionsPointCoordinates = selectedEmissions.map(
      ({ feature }) => feature.geometry.coordinates as unknown as number[]
    )

    const minLng = Math.min(...emissionsPointCoordinates.map(([lng]) => lng))
    const maxLng = Math.max(...emissionsPointCoordinates.map(([lng]) => lng))
    const minLat = Math.min(...emissionsPointCoordinates.map(([, lat]) => lat))
    const maxLat = Math.max(...emissionsPointCoordinates.map(([, lat]) => lat))
    const bounds = [minLng, minLat, maxLng, maxLat] as LngLatBoundsLike

    let mapMarkers: Marker[] = []

    // NOTE: This fails sporadically with an invalid lat/lng error, find a
    // better solution in the future.
    try {
      map.fitBounds(bounds, {
        padding: 100,
        maxZoom: 13,
        duration: 2000
      })
    } catch (err: any) {
      if (NODE_ENV === 'development') {
        l.error((err as Error).stack)
      } else {
        l.error((err as Error).message)
      }
    }

    mapMarkers = selectedEmissions.map((e: Emissions, i: number) => {
      const { feature } = e
      const markerContainer = document.createElement('div')
      const root = ReactDOM.createRoot(markerContainer)

      root.render(
        React.createElement(MapEmissionsMarker, {
          emissions: e,
          selectionIndex: i,
          onClick: onMarkerClick.bind(null, e)
        })
      )

      const { geometry } = feature
      const { coordinates } = geometry
      const marker = new Marker({
        element: markerContainer
      })

      marker.setLngLat(coordinates as unknown as LngLatLike)
      marker.addTo(map)

      return marker
    })

    return (): void => {
      mapMarkers.forEach((marker: Marker) => marker.remove())
    }
  }, [onMarkerClick, selectedEmissions, map])
}

export default useSelectedEmissionsMapMarkers
