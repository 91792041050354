import cn from 'clsx'
import * as d3 from 'd3'
import * as d3fc from 'd3fc'
import React, { useMemo, useRef, useEffect } from 'react'

import { CLASS_NAME } from './const'
import { type ChartAxisGridLinesProps } from './types'

import './style.scss'

const ChartAxisGridLines: React.FC<ChartAxisGridLinesProps> = (
  props: ChartAxisGridLinesProps
) => {
  const { className, scaleX, scaleY } = props
  const ref = useRef<any>(null)

  const gridline = useMemo(
    (): any => d3fc.annotationSvgGridline().xScale(scaleX).yScale(scaleY),
    [scaleX, scaleY]
  )

  useEffect((): void => {
    const { current } = ref

    if (current === null) {
      return
    }

    d3.select(current).call(gridline)
  }, [ref.current, gridline])

  return <g className={cn(CLASS_NAME, className)} ref={ref} />
}

export default ChartAxisGridLines
