import _sum from 'lodash/sum'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'

import getEmissionsTotalArea from './get_emissions_total_area'

import { type Emissions } from 'types/data'

const getEmissionsTotalIntensityForYear = (
  emissions: Emissions | Emissions[],
  year: number
): number => {
  const src = _isArray(emissions) ? emissions : [emissions]
  const totalArea = getEmissionsTotalArea(src)

  return _sum(
    src.map((e: Emissions): number => {
      const { data } = e
      const keysForYear = Object.keys(data).filter(
        (k: string): boolean => k.substring(0, 4) === `${year}`
      )

      const totalForYear = _isEmpty(keysForYear)
        ? 0
        : _sum(keysForYear.map((k: string): number => data[k] ?? 0))

      return totalArea === 0 ? 0 : totalForYear / totalArea
    })
  )
}

export default getEmissionsTotalIntensityForYear
