import { useTranslation } from 'react-i18next'

import * as H from 'hooks'
import * as U from 'utils'
import { type Emissions } from 'types/data'
import DashboardCard from '../DashboardCard/DashboardCard'

import { CLASS_NAME } from './const'
import '../style.scss'

type Props = {
  emissions: Emissions[]
  year: number
  className?: string
}

export default function PolygonCount({
  year,
  emissions,
  className
}: Props): JSX.Element {
  const { t } = useTranslation()
  const finalClassName = H.useClassName(CLASS_NAME, className)
  const yearStartDate = new Date(`${year}-01-01T00:00:00.000Z`)
  const yearEndDate = new Date(`${year}-12-31T23:59:59.999Z`)
  const emissionsForYear = H.useFunctionMemo<Emissions[]>(
    U.getFilteredEmissions,
    emissions,
    '',
    yearStartDate,
    yearEndDate
  )
  const numberOfPolygons = emissionsForYear.length

  return (
    <DashboardCard
      title={t('metric_card_polygon_count.title')}
      value={t('metric_card_polygon_count.value', {
        count: numberOfPolygons
      })}
      className={finalClassName}
    />
  )
}

export { CLASS_NAME }
