import _sum from 'lodash/sum'
import _get from 'lodash/get'
import _compact from 'lodash/compact'

import { type EmissionsGroup } from 'types/data'

/**
 * Updates a groups' NEE total, intensity total, and area total based on the
 * item data it contains.
 */
const updateGroupEmissionsValues = (group: EmissionsGroup): EmissionsGroup => {
  const { items } = group
  const totalArea = _sum(
    _compact(
      items.map(({ feature }: { feature: any }) =>
        _get(feature, 'properties.area')
      )
    )
  )
  const total = _sum(items.map(({ total }: { total: number }) => total))
  const totalIntensity = totalArea > 0 ? total / totalArea : 0

  group.total = total
  group.area = totalArea
  group.totalIntensity = totalIntensity

  return group
}

export default updateGroupEmissionsValues
