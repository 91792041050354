import * as U from 'utils'
import { type DB } from 'data'
import { getLogger } from 'logger'

const l = getLogger('pages:admin:utils:on_sub_change')

const onSubChange = (
  db: DB | null,
  sub: string,
  currentSub: string,
  setSub: (sub: string) => void
): void => {
  const tSub = sub.trim()

  if (tSub === currentSub) {
    return
  }

  U.clearLocalData(db)
    // eslint-disable-next-line promise/always-return,github/no-then
    .then((): void => {
      setSub(tSub)
    })
    // eslint-disable-next-line github/no-then
    .catch((err: Error) => {
      // eslint-disable-next-line i18n-text/no-en
      l.error(`Failed to clear local data: ${err.message}`)
      l.error(err.stack)
    })
}

export default onSubChange
