import _noop from 'lodash/noop'
import { createContext } from 'react'

import type DB from './data/db'
import { type Feature, type Emissions } from './types/data'

interface UIContextValue {
  db: DB | null

  sub: string
  userSub: string
  setSub: (v: string) => void

  features: Feature[] | null
  emissions: Emissions[] | null
  isLoading: boolean
  isUserAdmin: boolean
}

const UIContext = createContext<UIContextValue>({
  db: null,
  sub: '',
  userSub: '',
  setSub: _noop,
  features: null,
  emissions: null,
  isLoading: false,
  isUserAdmin: false
})

UIContext.displayName = 'UIContext'

export default UIContext
