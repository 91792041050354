import _noop from 'lodash/noop'
import _isFunction from 'lodash/isFunction'
import _isUndefined from 'lodash/isUndefined'

import { getLogger } from '../logger'

interface StoreConfig {
  name: string
}

type DBConfig = StoreConfig[] & Record<string, any>

const DB_VERSION = 1
const DB_DATA_EXPIRY_INTERVAL_MS = 24 * 60 * 60 * 1000
const l = getLogger('db')

/**
 * Wraps IndexedDB providing utility methods for reading and writing data. Used
 * for implementing the local API data cache, offering quick search and lookup
 * of emissions data.
 */

class DB {
  name: string
  version: number

  db: any
  config: DBConfig

  static supportsIndexedDB(): boolean {
    return !_isUndefined(indexedDB)
  }

  static getEmissionsKey(
    sub: string,
    featureUUID: string,
    featureLCC: string
  ): string {
    return `${sub}|${featureUUID}|co2|${featureLCC}`
  }

  static getFeatureKey(
    sub: string,
    featureUUID: string,
    featureLCC: string
  ): string {
    return `${sub}|${featureUUID}|${featureLCC}`
  }

  static getGeometryKey(
    sub: string,
    featureUUID: string,
    featureLCC: string
  ): string {
    return `${sub}|${featureUUID}|${featureLCC}`
  }

  static isDBItemExpired(item: any): boolean {
    const { expiry } = item

    return _isUndefined(expiry) || Date.now() > expiry
  }

  static getExpiryDate(): Date {
    return new Date(Date.now() + DB_DATA_EXPIRY_INTERVAL_MS)
  }

  constructor(name: string, version: number = DB_VERSION, config: DBConfig) {
    this.name = name
    this.version = version
    this.db = null
    this.config = config

    if (!DB.supportsIndexedDB()) {
      throw new Error('Browser does not support IndexedDB')
    }
  }

  getTransaction(store: string): any {
    return this.db.transaction([store], 'readwrite')
  }

  async execRequest(
    cb: () => any,
    onUpgradeNeededCB: (event: any) => void = _noop
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      const req = cb()

      req.onerror = (event: any) => {
        const { target } = event
        const { error } = target

        reject(error)
      }

      req.onsuccess = (event: any) => {
        const { target } = event
        const { result } = target

        resolve(result)
      }

      if (_isFunction(onUpgradeNeededCB)) {
        req.onupgradeneeded = onUpgradeNeededCB
      }
    })
  }

  async open(): Promise<void> {
    this.db = await this.execRequest(
      (): IDBOpenDBRequest => {
        const { indexedDB } = window

        return indexedDB.open(this.name, this.version)
      },
      (event) => {
        const { target } = event
        const { result } = target
        const db = result

        this.config.forEach(({ name, ...storeConfig }) =>
          db.createObjectStore(name, storeConfig)
        )
      }
    )
  }

  async saveItem(item: Record<any, any>, store: string): Promise<void> {
    const t = this.getTransaction(store)
    const objectStore = t.objectStore(store)

    try {
      await this.execRequest((): void => objectStore.add(item))
    } catch (e: any) {
      l.error(`failed to save item: ${e.message}`)
    }
  }

  async getAllItems(store: string): Promise<any> {
    const t = this.getTransaction(store)
    const objectStore = t.objectStore(store)

    try {
      return await this.execRequest((): void => objectStore.getAll())
    } catch (e: any) {
      l.error(`failed to get all items from store ${store}: ${e.message}`)

      throw e
    }
  }

  async getItem(id: string | number, store: string): Promise<any> {
    const t = this.getTransaction(store)
    const objectStore = t.objectStore(store)

    try {
      return await this.execRequest((): void => objectStore.get(id))
    } catch (e: any) {
      l.error(`failed to get item ${id}: ${e.message}`)

      throw e
    }
  }

  async removeItem(id: string | number, store: string): Promise<any> {
    const t = this.getTransaction(store)
    const objectStore = t.objectStore(store)

    try {
      return await this.execRequest((): void => objectStore.delete(id))
    } catch (e: any) {
      l.error(`failed to remove item ${id}: ${e.message}`)

      throw e
    }
  }

  async clear(store: string): Promise<any> {
    const t = this.getTransaction(store)
    const objectStore = t.objectStore(store)

    try {
      return await this.execRequest((): void => objectStore.clear())
    } catch (e: any) {
      l.error(`failed to clear store ${store}`)

      throw e
    }
  }
}

export default DB
