import _isUndefined from 'lodash/isUndefined'

import { type Emissions, type IGBPLandCoverClass } from 'types'

const onFeatureClick = (
  emissions: Emissions[],
  selectedEmissions: Emissions[],
  setSelectedEmissions: (emissions: Emissions[]) => void,
  featureUUID: string,
  featureLCC: IGBPLandCoverClass
): void => {
  const featureEmissions = emissions.find(({ feature }) => {
    const { properties } = feature
    const { feature_uuid: eFeatureUUID, land_cover: eFeatureLCC } = properties

    return eFeatureUUID === featureUUID && eFeatureLCC === featureLCC
  })

  if (_isUndefined(featureEmissions)) {
    return
  }

  const isEmissionsSelected = !_isUndefined(
    selectedEmissions.find(({ id }) => id === featureEmissions.id)
  )

  if (isEmissionsSelected) {
    setSelectedEmissions(
      selectedEmissions.filter(({ id }) => id !== featureEmissions.id)
    )
  } else {
    setSelectedEmissions([...selectedEmissions, featureEmissions])
  }
}

export default onFeatureClick
