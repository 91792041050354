import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  marginTop: PropTypes.number,
  marginRight: PropTypes.number,
  marginLeft: PropTypes.number,
  marginBottom: PropTypes.number,
  temporal: PropTypes.bool,
  formatX: PropTypes.func,
  formatY: PropTypes.func,
  labelX: PropTypes.string,
  labelY: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.string.isRequired
}

const defaultProps = {
  marginTop: 40,
  marginRight: 40,
  marginLeft: 40,
  marginBottom: 40,
  labelX: 'x',
  labelY: 'y'
}

export default { propTypes, defaultProps }
export { propTypes, defaultProps }
