import _get from 'lodash/get'

import { type Emissions } from 'types/data'
import { IGBPLandCoverClassNames } from 'types/common'
import {
  GROUP_MODE_PROPERTY_PATH,
  COUNTRY_CODE_LABELS,
  GroupMode
} from 'types/ui'

const getGroupKey = (mode: GroupMode, emissions: Emissions): string => {
  const propertyPath = GROUP_MODE_PROPERTY_PATH[mode]
  const propertyValue = (_get(emissions, propertyPath) ?? '') as string

  switch (mode) {
    case GroupMode.LandCover: {
      return IGBPLandCoverClassNames[
        propertyValue as keyof typeof IGBPLandCoverClassNames
      ]
    }

    case GroupMode.Country: {
      return COUNTRY_CODE_LABELS[
        propertyValue as keyof typeof COUNTRY_CODE_LABELS
      ]
    }

    case GroupMode.SiteName: {
      return propertyValue
    }

    case GroupMode.Name: {
      return propertyValue ?? 'None'
    }

    case GroupMode.None: {
      return propertyValue
    }

    default: {
      throw new Error(`Invalid group mode: ${mode}`)
    }
  }
}

export default getGroupKey
