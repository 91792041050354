import _isFinite from 'lodash/isFinite'
import _isUndefined from 'lodash/isUndefined'

import { type Emissions, type EmissionsData } from '../../types/data'

const getEmissionsDataMerged = (emissions: Emissions[]): EmissionsData => {
  const mergedData: EmissionsData = {}

  emissions.forEach(({ data }) => {
    Object.keys(data).forEach((key) => {
      if (!_isFinite(+key)) {
        return
      }

      if (_isUndefined((mergedData as any)[key])) {
        mergedData[key] = data[key]
      } else {
        mergedData[key] += data[key]
      }
    })
  })

  return mergedData
}

export default getEmissionsDataMerged
