import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import * as H from 'hooks'
import IconSearch from 'components/IconSearch/IconSearch'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type SearchInputProps } from './types'

import './style.scss'

const SearchInput: React.FC<SearchInputProps> = (props: SearchInputProps) => {
  const { placeholder, value, onChange, className } = props

  const { t } = useTranslation()
  const finalClassName = H.useClassName(CLASS_NAME, className)
  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { target } = e
      const { value } = target

      onChange(value)
    },
    []
  )

  const onClear = useCallback((): void => {
    onChange('')
  }, [onChange])

  return (
    <div className={finalClassName}>
      <IconSearch />

      <input
        type="text"
        placeholder={placeholder ?? t('search_input.placeholder')}
        value={value}
        onChange={onSearchChange}
      />

      {value.length > 0 && (
        <p onClick={onClear}>
          <Trans i18nKey="search_input.clear">Clear</Trans>
        </p>
      )}
    </div>
  )
}

SearchInput.propTypes = propTypes
SearchInput.defaultProps = defaultProps

export default SearchInput
export { CLASS_NAME }
export type { SearchInputProps as PROPS }
