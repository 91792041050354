import _sum from 'lodash/sum'
import _isArray from 'lodash/isArray'

import { type Emissions } from 'types/data'
import getEmissionsDataTotal from './get_emissions_data_total'

const getEmissionsTotal = (
  emissions: Emissions | Emissions[],
  year?: number
): number => {
  if (_isArray(emissions)) {
    return emissions.length === 0
      ? 0
      : _sum(emissions.map((e: Emissions) => getEmissionsTotal(e, year)))
  }

  const { data } = emissions

  return getEmissionsDataTotal(data)
}

export default getEmissionsTotal
