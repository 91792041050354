import useClassName from '../../hooks/use_class_name'
import { DEFAULT_ICON_WIDTH_PX } from '../../config'

import { CLASS_NAME } from './const'

import './style.scss'

type Props = {
  className?: string
  width?: number
  active?: boolean
}

export default function IconDownload({
  active,
  className,
  width = DEFAULT_ICON_WIDTH_PX
}: Props) {
  const finalClassName = useClassName(CLASS_NAME, className, 'cst-icon', {
    active: active === true
  })

  return (
    <div className={finalClassName}>
      <svg
        width={`${width}`}
        height={`${width}`}
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.0458 9.18726V5.52059C20.0458 5.03436 19.8526 4.56804 19.5088 4.22423C19.165 3.88041 18.6987 3.68726 18.2124 3.68726H5.37911C4.89288 3.68726 4.42656 3.88041 4.08275 4.22423C3.73893 4.56804 3.54578 5.03436 3.54578 5.52059V9.18726"
          stroke="#333333"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.2124 13.7705L11.7957 9.18717L16.3791 13.7705"
          stroke="#333333"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.7958 9.18726V20.1873"
          stroke="#333333"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export { CLASS_NAME }
