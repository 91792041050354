import formatUINumber from '../format_ui_number'
import { EmissionsUnit } from '../../types/common'
import getEmissionsValueUnit from './get_emissions_value_unit'

const getFormattedEmissionsValue = (
  value: number,
  isRelative?: boolean,
  omitUnit?: boolean
): string => {
  const unit = getEmissionsValueUnit(value, isRelative)
  const inKt = unit === EmissionsUnit.KTonsPerHa || unit === EmissionsUnit.KTons
  const finalValue = inKt ? value / 1000 : value
  const uiValue = formatUINumber(finalValue)

  return omitUnit === true ? uiValue : `${uiValue} ${unit}`
}

export default getFormattedEmissionsValue
