import _isUndefined from 'lodash/isUndefined'

import { type DB } from 'data'
import { getLogger } from 'logger'
import {
  RECENT_EMISSIONS_STORAGE_KEY,
  DB_FEATURES_STORE_NAME,
  DB_EMISSIONS_STORE_NAME,
  DB_GEOMETRIES_STORE_NAME
} from 'config'

const l = getLogger('utils:clear_local_data')

const clearLocalData = async (db: DB | null): Promise<void> => {
  if (!_isUndefined(localStorage)) {
    localStorage.removeItem(RECENT_EMISSIONS_STORAGE_KEY)
  }

  if (db !== null) {
    await db.clear(DB_FEATURES_STORE_NAME)
    await db.clear(DB_EMISSIONS_STORE_NAME)
    await db.clear(DB_GEOMETRIES_STORE_NAME)
  }

  // eslint-disable-next-line i18n-text/no-en
  l.info('Cleared local data')
}

export default clearLocalData
