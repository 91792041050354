import _isUndefined from 'lodash/isUndefined'
import { useMeasure } from '@uidotdev/usehooks'
import React, { useEffect, useMemo } from 'react'

import * as H from 'hooks'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type FluidContentWrapperProps } from './types'
import FluidContentWrapperContext, {
  type FluidContentWrapperContextValue
} from './context'

import './style.scss'

/**
 * Renders a columnar flexbox component to wrap the provided content, with an
 * optional title.
 *
 * NOTE: The context provides width and height for components that need the
 * values to compute their own widths and heights (i.e. chart SVG elements)
 */
const FluidContentWrapper: React.FC<FluidContentWrapperProps> = (
  props: FluidContentWrapperProps
) => {
  const {
    title,
    centered,
    children,
    className,
    widthOffset,
    onWidthChange,
    onHeightChange,
    ...otherProps
  } = props

  const finalClassName = H.useClassName(CLASS_NAME, className, {
    centered: centered === true
  })

  const [ref, { width, height }] = useMeasure()

  useEffect((): void => {
    if (!_isUndefined(onHeightChange)) {
      onHeightChange(height as number)
    }
  }, [onHeightChange, height])

  useEffect((): void => {
    if (!_isUndefined(onWidthChange)) {
      onWidthChange(width as number)
    }
  }, [onWidthChange, width])

  const contextValue = useMemo(
    (): FluidContentWrapperContextValue => ({
      width,
      height
    }),
    [width, height]
  )

  const hasTitle = !_isUndefined(title) && title.length > 0
  const widthCSSString = _isUndefined(widthOffset)
    ? '100%'
    : `calc(100% - ${widthOffset}px)`

  return (
    <div
      {...otherProps}
      className={finalClassName}
      style={{ width: widthCSSString }}
    >
      {hasTitle && <h3 className={`${CLASS_NAME}-title`}>{title}</h3>}

      <div ref={ref} className={`${CLASS_NAME}-content`}>
        <FluidContentWrapperContext.Provider value={contextValue}>
          {children}
        </FluidContentWrapperContext.Provider>
      </div>
    </div>
  )
}

FluidContentWrapper.propTypes = propTypes
FluidContentWrapper.defaultProps = defaultProps

export default FluidContentWrapper
export { CLASS_NAME }
export type { FluidContentWrapperProps as PROPS }
