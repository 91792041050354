import _isFinite from 'lodash/isFinite'

export default function convertDateFromApiToStandardDateObject(
  date: string
): Date {
  const isYear = date.length === 4
  const isYearAndMonth = date.length === 6

  if (!(isYear || isYearAndMonth)) {
    throw new Error(`Invalid emissions data key: ${date}`)
  }

  const year = Number(date.slice(0, 4))

  if (!_isFinite(year) || year <= 0 || year > new Date().getUTCFullYear()) {
    throw new Error(`Invalid emissions data key year: ${year}`)
  }

  const month = isYearAndMonth ? Number(date.slice(4, 6)) - 1 : 0

  if (!_isFinite(month) || month < 0 || month > 11) {
    throw new Error(`Invalid emissions data key month: ${month}`)
  }

  const dateObject = new Date(Date.UTC(0, 0, 0, 0, 0, 0))
  dateObject.setUTCFullYear(year)
  dateObject.setUTCDate(1)
  dateObject.setUTCMonth(month)
  dateObject.setUTCHours(0, 0, 0, 0)

  return dateObject
}
