import cn from 'clsx'
import * as d3 from 'd3'
import _isUndefined from 'lodash/isUndefined'
import React, { useRef, useEffect } from 'react'

import * as U from '../../utils'
import { CLASS_NAME } from './const'
import { type ChartAxisBottomProps } from './types'

import './style.scss'

const ChartAxisBottom: React.FC<ChartAxisBottomProps> = (
  props: ChartAxisBottomProps
) => {
  const { className, scale, transform, label, chartHeight, chartWidth } = props
  const ref = useRef<any>(null)

  const isLabelVisible =
    !_isUndefined(chartHeight) &&
    !_isUndefined(chartWidth) &&
    !_isUndefined(label) &&
    label.length > 0

  useEffect((): void => {
    const { current } = ref

    if (current === null) {
      return
    }

    d3.select(current)
      .call(d3.axisBottom(scale).tickSize(16))
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-65)')
      .each(U.wrapAxisTick)
  }, [ref.current, scale])

  return (
    <>
      {isLabelVisible && (
        <text
          x="-48"
          y={`${(chartHeight ?? 0) - 50}`}
          className={`${CLASS_NAME}-label`}
        >
          {label}
        </text>
      )}
      <g
        className={cn(CLASS_NAME, className)}
        transform={transform}
        ref={ref}
      />
    </>
  )
}

export default ChartAxisBottom
