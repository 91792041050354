import { useTranslation } from 'react-i18next'
import _noop from 'lodash/noop'
import _isEmpty from 'lodash/isEmpty'
import { type MouseEventHandler } from 'react'

import useClassName from '../../hooks/use_class_name'
import IconDownload from 'components/IconDownload/IconDownload'

import { CLASS_NAME } from './const'

import './style.scss'

type Props = {
  className?: string
  buttonText?: string
  onClick?: MouseEventHandler
}

export default function DownloadButton({
  className,
  buttonText,
  onClick
}: Props) {
  const { t } = useTranslation()
  const finalClassName = useClassName(CLASS_NAME, className)
  const finalButtonText = _isEmpty(buttonText)
    ? t('download_button.text_default')
    : buttonText

  return (
    <div onClick={onClick ?? _noop} className={finalClassName}>
      <IconDownload width={20} />
      <p>{finalButtonText}</p>
    </div>
  )
}

export { CLASS_NAME }
