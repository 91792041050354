import cn from 'clsx'
import React from 'react'

import * as H from 'hooks'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type FixedContentHeaderProps } from './types'

import './style.scss'

const FixedContentHeader: React.FC<FixedContentHeaderProps> = (
  props: FixedContentHeaderProps
) => {
  const { isOverlapping, className, children } = props
  const finalClassName = H.useClassName(CLASS_NAME, className)

  return (
    <div
      className={cn(finalClassName, {
        isOverlapping: isOverlapping === true
      })}
    >
      {children}
    </div>
  )
}

FixedContentHeader.propTypes = propTypes
FixedContentHeader.defaultProps = defaultProps

export default FixedContentHeader
export { CLASS_NAME }
export type { FixedContentHeaderProps as PROPS }
