import { createContext } from 'react'

export interface FluidContentWrapperContextValue {
  width: number
  height: number
}

const FluidContentWrapperContext =
  createContext<FluidContentWrapperContextValue>({
    width: 0,
    height: 0
  })

FluidContentWrapperContext.displayName = 'FluidContentWrapperContext'

export default FluidContentWrapperContext
