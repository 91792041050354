import React from 'react'

import * as H from '../../hooks'
import { DEFAULT_ICON_WIDTH_PX } from '../../config'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type IconChevronDownProps } from './types'

import './style.scss'

const WIDTH_HEIGHT_RATIO = 0.57

const IconChevronDown: React.FC<IconChevronDownProps> = (
  props: IconChevronDownProps
) => {
  const { active, className, width = DEFAULT_ICON_WIDTH_PX } = props
  const finalClassName = H.useClassName(CLASS_NAME, className, 'cst-icon', {
    active: active === true
  })

  return (
    <div className={finalClassName}>
      <svg
        width={`${width}`}
        height={`${width * WIDTH_HEIGHT_RATIO}`}
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.675903 1.39844L6.74686 6.67308L12.8178 1.39844"
          stroke="black"
          strokeWidth="1.3"
        />
      </svg>
    </div>
  )
}

IconChevronDown.propTypes = propTypes
IconChevronDown.defaultProps = defaultProps

export default IconChevronDown
export { CLASS_NAME }
export type { IconChevronDownProps as PROPS }
