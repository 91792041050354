import PropTypes from 'prop-types'

export const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  emissions: PropTypes.array.isRequired,
  setSelectedEmissions: PropTypes.func,
  selectedEmissions: PropTypes.array,
  multiLineItems: PropTypes.bool,
  onShowFeatureInfo: PropTypes.func,
  disableSelection: PropTypes.bool,
  showSelectionIndex: PropTypes.bool,
  renderIDs: PropTypes.bool,
  hideNavMapIcon: PropTypes.bool,
  scrollWindowWidth: PropTypes.number,
  scrollWindowHeight: PropTypes.number
}

export const defaultProps = {
  multiLineItems: false
}
