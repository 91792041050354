import { type Emissions } from '../../types/data'

import getEmissionsFeatureName from './get_emissions_feature_name'
import getEmissionsFeatureProperty from './get_emissions_feature_property'

const isEmissionsSearchMatch = (
  query: string,
  emissions: Emissions
): boolean => {
  const q = query.toLowerCase().trim()
  const name = getEmissionsFeatureName(emissions)
  const lcc = getEmissionsFeatureProperty(emissions, 'land_cover')
  const siteName = getEmissionsFeatureProperty(emissions, 'site_name')

  return (
    (name?.toLowerCase() ?? '').includes(q) ||
    (siteName?.toLowerCase() ?? '').includes(q) ||
    (lcc?.toLowerCase() ?? '').includes(q)
  )
}

export default isEmissionsSearchMatch
