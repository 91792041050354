import { useMemo } from 'react'

import { type GroupMode } from 'types/ui'
import { type DropdownOption } from 'components/Dropdown/types'

const useGroupModeOption = (
  sortMode: GroupMode,
  options: DropdownOption[]
): DropdownOption | undefined =>
  useMemo(
    (): DropdownOption | undefined =>
      options.find(({ value }) => value === sortMode),
    [sortMode, options]
  )

export default useGroupModeOption
