import PropTypes from 'prop-types'

export const propTypes = {
  options: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  inline: PropTypes.bool,
  value: PropTypes.any,
  label: PropTypes.string
}

export const defaultProps = {}
