import { useMemo } from 'react'

import { type SortMode } from 'types/ui'
import { type DropdownOption } from 'components/Dropdown/types'

const useSortModeOption = (
  sortMode: SortMode,
  options: DropdownOption[]
): DropdownOption | undefined =>
  useMemo(
    (): DropdownOption | undefined =>
      options.find(({ value }) => value === sortMode),
    [sortMode, options]
  )

export default useSortModeOption
