import _isNil from 'lodash/isNil'
import _isEmpty from 'lodash/isEmpty'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from '@uidotdev/usehooks'

import * as U from 'utils'
import * as H from 'hooks'
import * as C from 'components'
import { GroupMode } from 'types/ui'
import { type EmissionsGroup } from 'types/data'

import { CLASS_NAME } from './const'
import { type MapSidebarProps } from './types'
import { propTypes, defaultProps } from './props'

import './style.scss'

const MapSidebar: React.FC<MapSidebarProps> = (props: MapSidebarProps) => {
  const {
    setSelectedEmissions,
    selectedEmissions,
    onShowFeatureInfo,
    selectedGroups,
    emissions,
    className,
    filters
  } = props

  const { t } = useTranslation()
  const { groupMode } = filters
  const { height: windowHeight } = useWindowSize()
  const finalClassName = H.useClassName(CLASS_NAME, className)

  const groups = useMemo(
    (): EmissionsGroup[] =>
      _isNil(emissions) ? [] : U.groupEmissions(emissions, groupMode),
    [groupMode, emissions]
  )

  return (
    <div className={finalClassName}>
      {emissions.length === 0 && <C.NoDataSkeleton />}
      {emissions.length > 0 && groupMode === GroupMode.None && (
        <C.FeatureList
          title={t('map_sidebar.feature_list_title')}
          emissions={emissions}
          selectedEmissions={selectedEmissions}
          setSelectedEmissions={setSelectedEmissions}
          onShowFeatureInfo={onShowFeatureInfo}
          scrollWindowWidth={512}
          scrollWindowHeight={windowHeight - 266}
          subTitle={
            _isEmpty(selectedEmissions)
              ? t('map_sidebar.feature_list_subtitle_empty')
              : `${
                  selectedEmissions?.length ??
                  t('map_sidebar.feature_list_subtitle_selected_none')
                } ${t('map_sidebar.feature_list_subtitle_selected_suffix')}`
          }
          showSelectionIndex
          hideNavMapIcon
        />
      )}

      {emissions.length > 0 && groupMode !== GroupMode.None && (
        <C.GroupList
          setSelectedEmissions={setSelectedEmissions}
          scrollWindowHeight={windowHeight - 266}
          selectedEmissions={selectedEmissions}
          scrollWindowWidth={501}
          itemWidthPX={424}
          groups={groups}
          subTitle={
            _isEmpty(selectedGroups)
              ? t('map_sidebar.group_list_subtitle_empty')
              : `${
                  selectedGroups?.length ??
                  t('map_sidebar.group_list_subtitle_selected_none')
                } ${t('map_sidebar.group_list_subtitle_selected_suffix')}`
          }
          showSelectionIndex
          hideNavMapIcon
        />
      )}
    </div>
  )
}

MapSidebar.propTypes = propTypes
MapSidebar.defaultProps = defaultProps

export default MapSidebar
export { CLASS_NAME }
export type { MapSidebarProps as PROPS }
