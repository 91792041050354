import React from 'react'

import * as H from '../../hooks'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type IconSearchProps } from './types'

import './style.scss'

const IconSearch: React.FC<IconSearchProps> = (props: IconSearchProps) => {
  const { active, className } = props
  const finalClassName = H.useClassName(CLASS_NAME, className, 'cst-icon', {
    active: active === true
  })

  return (
    <div className={finalClassName}>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_870_17)">
          <path
            d="M16.4352 14.6393L12.4552 10.9081C13.5392 9.6651 14.0721 8.0791 13.9438 6.47809C13.8155 4.87707 13.0356 3.38355 11.7657 2.30644C10.4957 1.22932 8.8327 0.651029 7.12068 0.691167C5.40865 0.731304 3.77858 1.3868 2.56761 2.52208C1.35665 3.65736 0.657451 5.18556 0.614637 6.79058C0.571824 8.3956 1.18867 9.95466 2.33759 11.1453C3.48651 12.3359 5.07961 13.0669 6.78735 13.1872C8.4951 13.3076 10.1868 12.8079 11.5127 11.7917L15.4927 15.5229C15.6198 15.6294 15.7857 15.6863 15.9562 15.6818C16.1266 15.6773 16.2888 15.6118 16.4094 15.4988C16.53 15.3857 16.5999 15.2337 16.6047 15.0738C16.6094 14.914 16.5488 14.7585 16.4352 14.6393ZM7.29766 11.9557C6.24228 11.9562 5.21047 11.6632 4.33275 11.1138C3.45503 10.5644 2.77084 9.78326 2.36674 8.86925C1.96263 7.95524 1.85677 6.9494 2.06254 5.97897C2.26832 5.00854 2.77648 4.11714 3.52274 3.41751C4.269 2.71789 5.21984 2.24149 6.25496 2.04858C7.29008 1.85567 8.36298 1.95491 9.33793 2.33376C10.3129 2.71261 11.1461 3.35403 11.7321 4.17689C12.3181 4.99976 12.6307 5.96708 12.6302 6.9565C12.6282 8.2818 12.0657 9.55229 11.0661 10.4894C10.0665 11.4266 8.71131 11.9539 7.29766 11.9557Z"
            fill="#BFBFBF"
          />
        </g>
        <defs>
          <clipPath id="clip0_870_17">
            <rect
              width="16"
              height="15"
              fill="white"
              transform="translate(0.630127 0.705811)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

IconSearch.propTypes = propTypes
IconSearch.defaultProps = defaultProps

export default IconSearch
export { CLASS_NAME }
export type { IconSearchProps as PROPS }
