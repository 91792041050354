import React from 'react'

import * as H from '../../hooks'
import { DEFAULT_ICON_WIDTH_PX } from '../../config'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type IconCalendarProps } from './types'

import './style.scss'

const IconCalendar: React.FC<IconCalendarProps> = (
  props: IconCalendarProps
) => {
  const { active, className, width = DEFAULT_ICON_WIDTH_PX } = props
  const finalClassName = H.useClassName(CLASS_NAME, className, 'cst-icon', {
    active: active === true
  })

  return (
    <div className={finalClassName}>
      <svg
        width={`${width}`}
        height={`${width}`}
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_956_4304)">
          <path
            d="M14.3796 2.53369H3.52036C2.39585 2.53369 1.48425 3.44969 1.48425 4.57964V14.1274C1.48425 15.2573 2.39585 16.1733 3.52036 16.1733H14.3796C15.5041 16.1733 16.4157 15.2573 16.4157 14.1274V4.57964C16.4157 3.44969 15.5041 2.53369 14.3796 2.53369Z"
            stroke="#B8B8B8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.48425 6.62598H16.4157"
            stroke="#B8B8B8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.95001 3.89785V1.16992"
            stroke="#B8B8B8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.87775 3.89785V1.16992"
            stroke="#B8B8B8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.0222 3.89785V1.16992"
            stroke="#B8B8B8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.19904 10.0356H5.55644"
            stroke="#B8B8B8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.27124 10.0356H9.62865"
            stroke="#B8B8B8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.3435 10.0356H13.7009"
            stroke="#B8B8B8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.19904 12.7637H5.55644"
            stroke="#B8B8B8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.27124 12.7637H9.62865"
            stroke="#B8B8B8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.3435 12.7637H13.7009"
            stroke="#B8B8B8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_956_4304">
            <rect
              width="16.2889"
              height="16.3676"
              fill="white"
              transform="translate(0.805481 0.487793)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

IconCalendar.propTypes = propTypes
IconCalendar.defaultProps = defaultProps

export default IconCalendar
export { CLASS_NAME }
export type { IconCalendarProps as PROPS }
